import {
  Button,
  Center,
  Flex,
  useToast,
  VStack,
  Heading,
  Switch
} from '@chakra-ui/react'
import { QUEST_TYPES, Quest } from '@olagg/db-types'
import {
  fetcher,
  AdminQuestEndpoints,
  useAdminQuests,
  useOwnersQuest,
  useAdminOwnerQuests
} from '@olagg/api-hooks'
import {
  CreateQuestInput,
  createQuestSchema,
  UpdateQuestInput,
  updateQuestSchema
} from '@olagg/validation-schemas'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import OlaggForm from './OlaggForm'
import OlaGGFormInput from './OlaggFormInput'
import OlaggFormSelect from './OlaggFormSelect'
import OlaggFormInput from './OlaggFormInput'
import { ROUTES } from '../../consts/routes'

interface IProps {
  quest?: Quest
  mode: 'edit' | 'create'
}

const QuestForm: FC<IProps> = ({ mode, quest }) => {
  const toast = useToast()
  const navigate = useNavigate()

  const isCreate = mode === 'create'
  const isEdit = mode === 'edit'
  const heading = isCreate ? 'Creando nuevo Quest' : 'Editar Quest'

  const [afterVerifyToggle, setAfterVerifyToggle] = useState(false)

  const useFormMethods = useForm<CreateQuestInput>({
    resolver: yupResolver(isCreate ? createQuestSchema : updateQuestSchema),
    mode: 'onChange'
  })

  const [isEditable, setIsEditable] = useState(
    isEdit ? false : true
  )
  const [questsTitle, setQuestsTitle] = useState()

  const {
    quests
  } = useAdminQuests({
    title: questsTitle,
    page: 1,
    limit: 100
  })

  const {
    owners,
    isLoading: isLoadingOwners
  } = useAdminOwnerQuests({
    page: 1,
    limit: 100
  })

  const ownersOptions = owners.map(owner => ({ value: owner.id, label: owner.name }))
  const { isSubmitting } = useFormMethods.formState
  const questTypesOptions = [
    QUEST_TYPES.AUTOMATIC,
    QUEST_TYPES.MANUAL,
    QUEST_TYPES.ONE_CLICK,
    QUEST_TYPES.ADMIN_COMPLETION
  ].map(name => ({ value: name, label: name }))

  const topicsOptions = [
    ['quest.discord.linked', 'Cuando vincula su cuenta de Discord'],
    ['quest.twitter.linked', 'Cuando vincula su cuenta de Twitter'],
    ['quest.wallet.linked', 'Cuando vincula su wallet'],
    ['user.account.updated', 'Cuando actualiza su cuenta'],
    ['quest.discord.followed', 'Cuando sigue el servidor de Discord'],
    ['quest.oneclick.clicked', 'Cuando hace click en el botón de OneClick'],
    ['user.account.login', 'Cuando inicia sesión'],
    ['user.referrer.linked', 'Cuando crea su cuenta con un referido']
  ].map(name => ({ value: name[0], label: name[1] }))

  const onSubmit = (data: CreateQuestInput | UpdateQuestInput) => {
    const body: Partial<Quest> & { ownerId?: string } = {
      ...data,
      visible: data.visible || false,
      recursive: data.recursive || false,
      ownerId: data.ownerId.value,
      dependsOn: [data.dependsOn?.value || ''],
      trigger: data.trigger?.value,
      type: data.type?.value as QUEST_TYPES
    }
    if (isCreate) {
      fetcher(
        AdminQuestEndpoints.create(body)
      )
        .then(() => onSuccess('Quest creado correctamente'))
        .catch(e => onError(e))
    }

    if (isEdit) {
      fetcher(AdminQuestEndpoints.update(quest!.id!, body))
        .then(() =>
          onSuccess(
            'Quest editado correctamente',
            'El Quest fue editado con éxito'
          )
        )
        .catch(e => onError(e))
    }
  }

  const onSuccess = async (title: string, body?: string) => {
    toast({
      title,
      colorScheme: 'olaggPink',
      status: 'success',
      description: body || 'El Quest fue creado con éxito',
      position: 'bottom-right'
    })
    if (mode === 'create') {
      resetForm()
      navigate(ROUTES.QUESTS.all)
    }

    if (mode === 'edit' && quest?.id) {
      setTimeout(() => {
        location.reload()
      }, 750)
    }
  }

  const onError = (e: { message: string } | string) => {
    const message = typeof e === 'string' ? e : e.message
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: message
          }}
        ></div>
      ),
      position: 'bottom-right'
    })
  }

  const checkType = (value: string) => {
    (value == QUEST_TYPES.ONE_CLICK) ? setAfterVerifyToggle(true) : setAfterVerifyToggle(false)
  }

  const selectedDependsOn = quests.find(q => q.id === quest?.dependsOn?.[0])

  useEffect(() => {
    if (quest) checkType(quest.type)
  }, [quest])

  useEffect(
    () => {
      if (!isLoadingOwners) {
        useFormMethods.setValue('title', quest?.title || '')
        useFormMethods.setValue('buttonText', quest?.buttonText || '')
        useFormMethods.setValue('action', quest?.action || '')
        useFormMethods.setValue('banner', quest?.banner || '')
        useFormMethods.setValue('icon', quest?.icon || '')
        useFormMethods.setValue('type', { value: quest?.type, label: quest?.type } || '')
        useFormMethods.setValue('description', quest?.description || '')
        useFormMethods.setValue('dependsOn', { value: selectedDependsOn?.id, label: selectedDependsOn?.title || '' })
        useFormMethods.setValue('score', quest?.score || '')
        useFormMethods.setValue('multiplier', quest?.multiplier || '')
        useFormMethods.setValue('trigger', { value: quest?.trigger, label: quest?.trigger } || '')
        useFormMethods.setValue('ownerId', {
          value: quest?.owner?.id,
          label: owners.find(o => o.id === quest?.owner?.id)?.name || 'Seleccione...'
        })
        useFormMethods.setValue('recursive', quest?.recursive || false)
        useFormMethods.setValue('callback', quest?.callback || '')
        useFormMethods.setValue('visible', quest?.visible || false)
      }
    }, [isLoadingOwners]
  )

  const resetForm = () => useFormMethods.reset()

  const questsOptions = quests.map(quest => ({ value: quest.id, label: quest.title }))

  return (
    <Center w={'full'}>
      <VStack color={'white'}>
        <Heading my={2}>{heading}</Heading>
        {isEdit && (
          <Flex
            w="full"
            justify="end"
            align="center"
            style={{ marginTop: '30px' }}
          >
            <label
              htmlFor="enableEdition"
              style={{ cursor: 'pointer' }}
            >
              Habilitar edición
            </label>
            <Switch
              id="enableEdition"
              colorScheme="purple"
              size="md"
              ml={2}
              onChange={() => setIsEditable(prev => !prev)}
            />
          </Flex>
        )}

        <OlaggForm
          useFormMethods={useFormMethods}
          onSubmit={onSubmit}
          style={{
            width: '100%'
          }}
        >
          <fieldset disabled={!(isEditable || isCreate)}>
            <VStack
              w={{ base: '70vw', lg: '50vw' }}
              mt={2}
              mb={10}
            >
              <OlaGGFormInput
                label='Título'
                controlName='title'
                placeholder='Ej: Vincula tu cuenta de Discord'
                required={true}
              />
              <OlaGGFormInput
                label='Texto del botón'
                controlName='buttonText'
                placeholder='Ej: Vincula Discord'
                required={true}
              />
              <OlaGGFormInput
                label='Link del botón'
                controlName='action'
                placeholder='Ej: /mi-perfil/datos'
                required={true}
              />
              <OlaGGFormInput
                label='Link de la imagen de portada'
                description='712px x 400px sugerido'
                controlName='banner'
                placeholder='https://cdn.olagg.io/game-assets/axie-infinity.png'
                required={false}
              />
              <OlaGGFormInput
                label='Link del icono'
                description='48px x 48px sugerido'
                controlName='icon'
                placeholder='https://cdn.olagg.io/game-assets/axie-infinity.png'
                required={true}
              />
              <OlaggFormSelect
                label='Tipo de Quest'
                controlName='type'
                required={true}
                isSearchable={false}
                staticOptions={questTypesOptions}
                onChange={value => checkType(value.value)}
              />
              {afterVerifyToggle &&
                <OlaGGFormInput
                  label='Link a donde redirigir luego de verificar la Misión (opcional)'
                  controlName='callback'
                  placeholder='Ej: https://olagg.io/landing/faqs'
                />
              }
              <OlaGGFormInput
                label='Descripción'
                controlName='description'
                placeholder='Ej: Vincula tu cuenta de Discord para poder participar en los torneos'
              />
              <OlaggFormSelect
                label='Depende de'
                controlName='dependsOn'
                isSearchable={false}
                required={false}
                onInputChange={setQuestsTitle}
                staticOptions={questsOptions}
              />
              <OlaGGFormInput
                label='Score'
                controlName='score'
                placeholder='Ej: 100'
                required={true}
              />
              <OlaGGFormInput
                label='Multiplicador'
                controlName='multiplier'
                placeholder='Ej: 1'
                inputType='number'
                required={true}
              />
              <OlaggFormSelect
                label='Evento que completa la Quest'
                controlName='trigger'
                required={quest?.type && [QUEST_TYPES.AUTOMATIC, QUEST_TYPES.MANUAL].includes(quest.type)}
                isSearchable={false}
                staticOptions={topicsOptions}
              />
              <OlaggFormSelect
                label='Vincular con Owner'
                controlName='ownerId'
                required={true}
                isSearchable={true}
                staticOptions={ownersOptions}
              />
              <OlaggFormInput
                label='Es recursiva?'
                controlName='recursive'
                inputType='switch'
                required={false}
                chakraStyles={{ colorScheme: 'red' }}
              />

              <OlaggFormInput
                label='Mostrar?'
                controlName='visible'
                inputType='switch'
                chakraStyles={{ colorScheme: 'red' }}
              />

              {isEditable && (
                <Flex w="full" style={{ marginTop: '20px' }}>
                  <Button
                    variant="filledGradient"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    type="submit"
                    flex="1"
                  >
                    {isCreate ? 'Crear Quest' : 'Guardar cambios'}
                  </Button>
                  {isEdit && (
                    <Button
                      ml={2}
                      variant="outlinedGradient"
                      onClick={() => navigate(-1)}
                      flex="1"
                    >
                      Descartar Cambios
                    </Button>
                  )}
                </Flex>
              )}
            </VStack>
          </fieldset>
        </OlaggForm>
      </VStack>
    </Center>
  )
}

export default QuestForm
