import {
  TableContainer,
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  useToast
} from "@chakra-ui/react"
import { AdminAssignmentEndpoints, fetcher } from "@olagg/api-hooks";
import { AssignmentData } from "@olagg/web3/dist/domain/entities/assignment.entity"

type TableProps = {
  assignments: AssignmentData[]
  tokenId: string
}

const Table = ({ assignments, tokenId }: TableProps) => {
  const toast = useToast();

  const removeAssignment = (assignment: AssignmentData) => () => {
    fetcher(AdminAssignmentEndpoints.delete(tokenId, assignment.id))
      .then(() => {
        toast({
          title: 'Whitelist',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Usuario quitado de la whitelist',
          position: 'bottom-right'
        });
        window.location.reload()
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
  };

  return (
    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="80vw" h="70vh">
      <ChakraTable
        variant="striped"
        colorScheme="olaggPurple"
        fontSize="18px"
      >
        <Thead>
          <Tr>
            <Th color="white">Id</Th>
            <Th color="white">Email</Th>
            <Th color="white">Discord</Th>
            <Th color="white" textAlign='center'>Status</Th>
            <Th textAlign='center' color="white">Acción</Th>
          </Tr>
        </Thead>
        <Tbody>
          {assignments.map((assignment, index) => (
            <Tr key={index}>
              <Td maxWidth='100px'>
                {assignment.user.id}
              </Td>
              <Td>
                {assignment.user.email}
              </Td>
              <Td>
                {assignment.user.discordId}
              </Td>
              <Td textAlign='center'>
                {assignment.status}
              </Td>
              <Td textAlign='center'>
                <Flex gap='3' justifyContent={'center'}>
                  <Popover>
                    <PopoverTrigger>
                      <Button size={'sm'} padding='0px 8px' ml={0} colorScheme="red">Quitar</Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Button width={'100%'} colorScheme='red' onClick={removeAssignment(assignment)}
                          >
                            Confirmar quitar de la Whitelist
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}

export default Table
