import { useState } from 'react';
import { Center, Heading, VStack, Button, Flex } from '@chakra-ui/react';
import { useAdminInscriptions } from '@olagg/api-hooks';
import { useNavigate, useParams, generatePath, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../../consts/routes';
import { FaPlus } from 'react-icons/fa';
import Pagination from '@olagg/ui-kit/Pagination';
import Table from './Table';
import Filter from './Filter';
import { IUseInscriptions } from '@olagg/api-hooks/interfaces/IUseInscriptions';

const TournamentInscriptions = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<IUseInscriptions>({
    tournamentId: Number(params.id!),
    userId: searchParams.get('userId') ? Number(searchParams.get('userId')) : undefined,
    onlyWinners: searchParams.get('onlyWinners') ? Boolean(searchParams.get('onlyWinners')) : undefined,
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 10)
  })

  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  const onUpdate = () => {
    setFilter({ ...filter, update: Math.random() })
  }

  const { page, limit } = filter

  const { count, inscriptions } = useAdminInscriptions(filter)

  return (
    <Center w='full' pb={4}>
      <VStack color={'white'} w='full'>
        <Heading my={2}>
          Participantes
        </Heading>

        <Flex w='full' justifyContent='space-between' paddingRight="22px">
          <Filter onFilter={onFilter} />
          <Button
            display='flex'
            alignSelf='self-start'
            variant="outlinedGradient"
            leftIcon={<FaPlus />}
            onClick={() => navigate(generatePath(ROUTES.TOURNAMENTS.addInscription, { id: params.id }))}
          >
            Añadir Participantes
          </Button>
        </Flex>

        <Table inscriptions={inscriptions} onUpdate={onUpdate} />

        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: page + 1
            })
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            })
          }
          hideLimitInput
        />
      </VStack >
    </Center >
  )
};

export default TournamentInscriptions;
