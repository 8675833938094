import { MarketPlaceItem } from "@olagg/db-types"
import { AdminMarketPlaceEndpoints } from "../endpoints/adminEndpoints"
import { IUseItems } from "../interfaces/IUseItems"
import { useQ } from "./useQ"

export const useAdminItems = ({ page, limit, title }: IUseItems) => {
  const response = useQ<{ items: MarketPlaceItem[], meta: { count: number } }>(
    AdminMarketPlaceEndpoints.all({ page, limit, title }), {
    revalidateOnFocus: true
  }
  )

  return {
    ...response,
    items: response.data?.items ?? [],
    count: response.data?.meta?.count ?? 0
  }
}
