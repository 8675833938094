import type {
  Quest
} from '@olagg/db-types'
import { AdminQuestEndpoints } from '../endpoints/adminEndpoints'
import { useQ } from './useQ'

export const useAdminQuest = (id: string) => {
  const response = useQ<{quest: Quest}>(AdminQuestEndpoints.find(id), {
    revalidateOnFocus: true
  })

  return {
    ...response,
    quest: response.data?.quest
  }
}