import { Center, useDisclosure } from '@chakra-ui/react'
import { useScholarships } from '@olagg/api-hooks'
import { IUseScholarships } from '@olagg/api-hooks/interfaces/IUseScholarships'
import useAdminScholarships from '@olagg/api-hooks/queries/useAdminScholarships'
import type {
  ApplicationStatus,
  ScholarshipApplication
} from '@olagg/db-types'
import Pagination from '@olagg/ui-kit/Pagination'
import { FC, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import Filter from '../../components/Filters/ScholarshipsFilter'
import ManageScholarshipModal from '../../components/Modals/ManageScholarshipModal'
import ScholarshipTable from '../../components/Tables/ScholarshipTable'

interface IProps {
  showFilter?: boolean
}

const ScholarshipApplications: FC<IProps> = ({
  showFilter = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [currentAction, setCurrentAction] =
    useState<ApplicationStatus>('APPROVED')

  const [currentApplication, setCurrentApplication] =
    useState<ScholarshipApplication>()

  const [searchParams, setSearchParams] = useSearchParams()

  const [filter, setFilter] = useState<IUseScholarships>({
    slug: searchParams.get('slug') || '',
    status: searchParams.get('status') || '',
    query: searchParams.get('query') || '',
    country: searchParams.get('country') || '',
    dateSort:
      (searchParams.get('dateSort') as 'asc' | 'desc') || '',
    page: Number(searchParams.get('page') || 0),
    limit: Number(searchParams.get('limit') || 100)
  })

  const {
    slug,
    country,
    dateSort,
    query,
    status,
    page,
    limit
  } = filter

  const { scholarships, getAll, count } = useAdminScholarships()


  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  const getScholarships = async () => {
    getAll({
      slug,
      country,
      dateSort,
      query,
      status,
      page,
      limit
    })
  }

  useEffect(() => {
    getScholarships()
  }, [filter])

  return (
    <>
      <ManageScholarshipModal
        isOpen={isOpen}
        onClose={onClose}
        currentAction={currentAction}
        setCurrentAction={setCurrentAction}
        currentApplication={currentApplication}
        setCurrentApplication={setCurrentApplication}
        onAction={getScholarships}
      />
      <Center color="white" flexDirection="column">
        <Center h="full" display="block" px={8}>
          {showFilter && (
            <Filter
              filter={filter}
              onFilter={({ target }) => onFilter(target)}
            />
          )}
          <ScholarshipTable
            scholarships={scholarships}
            setCurrentAction={setCurrentAction}
            setCurrentApplication={setCurrentApplication}
            onOpen={onOpen}
            filter={filter}
            onFilter={onFilter}
          />
        </Center>
        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: String(page + 1)
            })
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            })
          }
          hideLimitInput
        />
      </Center>
    </>
  )
}

export default ScholarshipApplications
