import { FC, useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { ROUTES } from '../../consts/routes'
import { useForm } from 'react-hook-form'
import { Center, Heading, VStack, Button, Box, Spinner, useToast } from '@chakra-ui/react'
import OlaggForm from '../../components/Forms/OlaggForm'
import OlaggFormSelect from '../../components/Forms/OlaggFormSelect'
import OlaggFormInput from '../../components/Forms/OlaggFormInput'
import { fetcher, AdminTokensEndpoints, CreateTokenData, UpdateTokenData, Endpoint } from '@olagg/api-hooks'
import { useAdminTokens } from '@olagg/api-hooks'
import TokenType from '@olagg/web3/dist/domain/entities/vo/tokenType.vo'
import MintOwner from '@olagg/web3/dist/domain/entities/vo/mintOwner.vo'
import Mint from '@olagg/web3/dist/domain/entities/mint.entity'
import Network from '@olagg/web3/dist/domain/entities/vo/network.vo'
import Section from '@olagg/web3/dist/domain/entities/vo/section.vo'
import { yupResolver } from '@hookform/resolvers/yup'
import { formTokenSchema, FormTokenData } from '@olagg/validation-schemas//token.schemas'
import { convertUTCToLocalISOString } from '../../utils'
import { TokenClassificationType } from '../../../../../packages/db-types'

interface ITokenFormProps {
  mode: 'edit' | 'create'
};

const TokenForm: FC<ITokenFormProps> = ({
  mode='create'
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { state } = useLocation();
  const tokenId = useParams()?.id;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tokenType, setTokenType] = useState<string>('');
  const [mintType, setMintType] = useState<string>('');
  const { mutate } = useAdminTokens({ page: 1, limit: 10 })

  const useFormMethods = useForm<FormTokenData>({
    resolver: yupResolver(formTokenSchema),
    mode: 'onChange',
  });

  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // recursive function for loading current form values in edit mode 
  const setValues = (value: any, fieldName?: any, parentFieldName?: any) => {
    if (typeof value === 'object' && value !== null && !(value instanceof Array)) {
      Object.keys(value).forEach(childFieldName => {
        setValues(value[childFieldName], childFieldName, fieldName);
      });
    } else {
      const fullFieldName = parentFieldName ? 
        `${parentFieldName}${fieldName.charAt(0).toUpperCase()}${fieldName.slice(1)}` : 
        fieldName;
      useFormMethods.setValue(fullFieldName, value);
    }
  };

  useEffect(() => {
    if (mode === 'create') return; // no need of state on create mode
    
    const data = state?.data;
    if (!data) {
      navigate(ROUTES.TOKENS.all); // navigate to tokens list if no data received 
      return;
    }
  
    // Populate form with current values
    setValues(data);
          
    // Handle special cases
    // Date fields are strings in ISO format, in UTC timezone: convert to user timezone and remove the last 'Z'
    useFormMethods.setValue('enabledAt', data?.enabledAt && convertUTCToLocalISOString(data.enabledAt).slice(0, -1));

    // Selectable fields
    useFormMethods.setValue('network', {value: data?.network, label: data?.network});
    useFormMethods.setValue('type', {value: data?.type, label: data?.type});
    useFormMethods.setValue('section', {value: data?.section, label: data?.section});
    useFormMethods.setValue('mintOwner', {value: data?.mint?.owner, label: data?.mint?.owner});
    useFormMethods.setValue('classificationClassificationType', {value: data?.classification?.classificationType, label: data?.classification?.classificationType});

    // Set states
    setTokenType(data.type.toUpperCase());
    setMintType(data.mint.owner.toUpperCase());
  }, [state]);

  const onSubmit = (dataForm: FormTokenData) => {
    setIsLoading(true);

    // remove auxiliary form fields
    const {
      classificationClassificationType,
      mintOwner, 
      mintSite,
      mintClaimPhases,
      mintSnapshotRequired,
      mintRequirements,
      valueCurrency,
      valuePrice,
      ...partialData 
    } = dataForm;

    // build token object
    const tokenData = {
      ...partialData,
      classification: dataForm.classificationClassificationType.value,
      mint: new Mint({
        owner: dataForm.mintOwner.value as MintOwner,
        site: dataForm.mintSite,
        claimPhases: dataForm.mintClaimPhases,
        snapshotRequired: dataForm.mintSnapshotRequired,
        requirements: dataForm.mintRequirements ?? ''
      }),
      value: {
        price: dataForm.valuePrice,
        currency: dataForm.valueCurrency
      },
      type: dataForm.type.value,
      network: dataForm.network.value,
      section: dataForm.section.value,
    };

    const endpoint: Endpoint = mode === 'create' ? 
      AdminTokensEndpoints.create(tokenData as CreateTokenData) : 
      AdminTokensEndpoints.update(tokenId || '', tokenData as UpdateTokenData);

    fetcher(endpoint)
      .then(() => {
        toast({
          title: 'Token',
          colorScheme: 'olaggPink',
          status: 'success',
          description: `Token ${mode === 'create' ? 'creado' : 'actualizado'} con éxito`,
          position: 'bottom-right'
        });
        mutate();
        navigate(ROUTES.TOKENS.all);
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          {mode === 'create' ? 'Crear nuevo' : 'Editar'} Token
        </Heading>

        {isLoading ? (
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
        ) : (
          <>
            <OlaggForm 
              useFormMethods={useFormMethods} 
              onSubmit={onSubmit} 
              style={{ width: '100%' }}
            >
              <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
                <OlaggFormSelect 
                  label='Blockchain'
                  controlName='network'
                  staticOptions={Object.values(Network)}
                />

                <OlaggFormSelect
                  label='Tipo'
                  controlName='type'
                  staticOptions={Object.values(TokenType)}
                  onChange={(newVal) => setTokenType(newVal.value)}
                />
                
                <OlaggFormInput
                  label='Token Id'
                  controlName='tokenId'
                  description='Instancia del Token ERC1155 (no aplica para ERC721 ni para ERC20)'
                  visible={tokenType === TokenType.ERC1155}
                  required={tokenType === TokenType.ERC1155}
                />
                    
                <OlaggFormInput
                  label='Address'
                  controlName='address'
                />

                <OlaggFormSelect
                  label='Clasificación'
                  controlName='classificationClassificationType'
                  description='Clasificación del Token por área de experiencia. Esto controla el mini-ícono que se muestra en cada token según su área de experiencia'
                  staticOptions={Object.values(TokenClassificationType)}
                />

                <OlaggFormSelect
                  label='Sección'
                  controlName='section'
                  description='En qué sección mostrar, según la utilidad del Token'
                  staticOptions={Object.values(Section)}
                />
                
                <OlaggFormSelect
                  label='Forma de hacer el Mint'
                  controlName='mintOwner'
                  description='
                    AIRDROP: OlaGG hace el Mint y el usuario lo recibe como un airdrop.
                    MINT: El usuario hace el “Claim” desde la página de OlaGG.
                    THIRD-PARTY: La emisión la hace un tercero o Partner de Ola, por lo tanto se deshabilita la obtención del Token desde la página de OlaGG)'
                  staticOptions={Object.values(MintOwner)}
                  onChange={(newVal) => setMintType(newVal.value)}
                />

                <OlaggFormInput
                  label='URL para el minteo'
                  controlName='mintSite'
                  description='Sitio externo desde donde el Third Party hará el minteo'
                  required={mintType === MintOwner.THIRD_PARTY}
                  visible={mintType === MintOwner.THIRD_PARTY}
                />
                
                <OlaggFormInput
                  label='Claim Phases'
                  controlName='mintClaimPhases'
                  description='Habilitar sólo si el smart contract implementa condiciones para poder hacer el mint'
                  inputType='switch'
                  required={false}
                />
                
                <OlaggFormInput
                  label='Whitelist requerida'
                  controlName='mintSnapshotRequired'
                  description='Habiliar si es necesario que el usuario esté dentro de la Whitelist para poder hacer el Mint'
                  inputType='switch'
                  required={false}
                />
                
                <OlaggFormInput
                  label='Requisitos para el Mint'
                  controlName='mintRequirements'
                  description='Descripción de los requisitos que debe cumplir un usuario (en caso que los hubiera) para poder hacer el Mint'
                  required={false}
                />

                <OlaggFormInput
                  label='Fecha de lazamiento'
                  controlName='enabledAt'
                  description={`Fecha a partir de la cual se podrá obtener el Token/NFT (si es que aplica) | Zona horaria: ${currentTimeZone}`}
                  inputType='datetime'
                  datePickerProps={{backgroundColor: 'white'}}
                  required={false}
                />

                <OlaggFormInput
                  label='Gasless Relayer'
                  controlName='gaslessRelayer'
                  description='URL del Gasless Relayer que se usaría para permitir que el usuario haga Claim/Mint sin pagar por el Gas Fee'
                  required={false}
                />

                <OlaggFormInput
                  label='Precio'
                  controlName='valuePrice'
                  description='Definir sólo si el TokenID tendrá un precio cuando el usuario haga el Claim/Mint'
                  required={false}
                />

                <OlaggFormInput
                  label='Moneda'
                  controlName='valueCurrency'
                  description='Definir sólo si el TokenID tendrá un precio cuando el usuario haga el Claim/Mint'
                  required={false}
                />

                <OlaggFormInput
                  label='Level mínimo requerido'
                  controlName='requiredLevel'
                  description='Mínimo Level o XP que debe tener el usuario para obtener el token'
                  required={false}
                />

                <OlaggFormInput
                  label='Cantidad máxima de tokens por wallet'
                  controlName='maxTokensPerUser'
                  description='Aplica sólo para ERC1155. Cuántos tokens iguales le permitimos tener a un mismo usuario'
                  visible={tokenType === TokenType.ERC1155}
                  required={tokenType === TokenType.ERC1155}
                />

                <OlaggFormInput
                  label='Orden'
                  controlName='order'
                  description='Usado para ordenar los tokens en la página de OlaGG'
                  required={true}
                />

                <OlaggFormInput
                  label='Visible'
                  controlName='visible'
                  inputType='switch'
                  required={false}
                />

                <Button
                  variant={'filledGradient'}
                  isLoading={isLoading}
                  disabled={isLoading}
                  type="submit"
                  w={'full'}
                  style={{
                    marginTop: '30px'
                  }}
                >
                  {mode === 'create' ? 'Crear' : 'Actualizar'}
                </Button>
              </VStack>
            </OlaggForm>
          </>

        )}
      </VStack>
    </Center>
  )
}

export default TokenForm;
