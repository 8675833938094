import {
  Button,
  Center,
  Spinner,
  Stack,
  Table as ChakraTable,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
  Text,
  Flex
} from '@chakra-ui/react'
import type {
  DiscordAccount,
  GameCredentials,
  User
} from '@olagg/db-types'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../consts/routes'
import { normalizeDate } from '../../utils'
import DisableCredentialsModal from '../Modals/DisableCredentialsModal'

const Trs = () => (
  <Tr>
    <Th color="white">ID Juego</Th>
    <Th color="white">Username</Th>
    <Th color="white">Password</Th>
    <Th color="white">Email Cuenta</Th>
    <Th color="white">Wallet Address</Th>
    <Th color="white">Blockchain</Th>
    <Th color="white">Identificador NFT</Th>
    <Th color="white">Nombre Jugador</Th>
    <Th color="white">Email Jugador</Th>
    <Th color="white">Fecha de creación</Th>
    <Th color="white">Estado</Th>
    <Th color="white">Lista Para Jugar?</Th>
    <Th color="white">Acciones</Th>
  </Tr>
)

interface IProps {
  credentials?: (GameCredentials & {
    createdAt: string
    currentlyAssignedTo?: {
      user?: User & {
        discordAccount?: DiscordAccount
      }
    }
  })[]
}

const CredentialsTable = ({
  credentials = [],
}: IProps) => {
  const navigate = useNavigate()
  const toast = useToast()

  const {
    isOpen: isOpenDisable,
    onOpen: onOpenDisable,
    onClose: onCloseDisable
  } = useDisclosure()

  const [selectedCrendentialId, setSelectedCredentialId] = useState<string | null>()

  const onSuccess = async (title: string, body?: string) => {
    toast({
      title,
      colorScheme: 'olaggPink',
      status: 'success',
      description: body,
      position: 'bottom-right'
    })
    setSelectedCredentialId(null)
  }

  const onError = (e: { message: string } | string) => {
    const message = typeof e === 'string' ? e : e.message
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: message
          }}
        ></div>
      ),
      position: 'bottom-right'
    })
    setSelectedCredentialId(null)
  }

  if (!credentials.length)
    return <Center h="full">No hay resultados</Center>

  const computeStatus = (
    creds: GameCredentials & {
      createdAt: string
      currentlyAssignedTo?: {
        user?: User & {
          discordAccount?: DiscordAccount
        }
      }
    }
  ) => {
    if (!creds.active) return 'Deshabilitada'
    if (!creds.scholarship) {
      return 'Libre'
    }

    if (!creds.userInputsCompleted) {
      return 'Requiere ingreso de datos del usuario'
    }

    if (!creds.built) {
      return 'Requiere creación de cuenta / Arrendamiento'
    }

    return 'Asignada'
  }

  const showCredential = (id: string) => () => {
    navigate(`${ROUTES.CREDENTIALS.all}/${id}`)
  }

  const disableCredential = (id: string) => () => {
    setSelectedCredentialId(id)
    onOpenDisable()
  }

  return (
    <>
      <ChakraTable
        variant="striped"
        colorScheme="olaggPurple"
        fontSize={'18px'}
      >
        <Thead position='sticky' top='0' bg='gray.800' zIndex='1'>
          <Trs />
        </Thead>
        <Tbody>
          {credentials?.map(c => (
            <Tr key={c.id}>
              <Td>{c.game!.slug}</Td>
              <Td>{c.username}</Td>
              <Td>{c.password}</Td>
              <Td>{c.email}</Td>
              <Td>{c.walletAddress}</Td>
              <Td>{c.blockchain}</Td>
              <Td>{c.nftIdentifier}</Td>
              <Td>
                <Tooltip label={c.user?.id}>
                  <p>
                    {c.user?.name
                      ? c.user?.name
                      : c.user?.discordId
                        ? '#' +
                        c.user?.discordId
                        : '-'}
                  </p>
                </Tooltip>
              </Td>
              <Td>{c.user?.email}</Td>
              <Td>{normalizeDate(c.createdAt)}</Td>
              <Td>{computeStatus(c)}</Td>
              <Td>{c.built ? 'Si' : 'No'}</Td>
              <Td>
                <Flex alignItems="center">
                  <Button
                    colorScheme="green"
                    ml='5px'
                    size={'sm'}
                    py='5px'
                    onClick={showCredential(c.id)}
                    mt='5px'
                  >Ver</Button>
                  {c?.active && (
                    <Button
                      ml='5px'
                      size={'sm'}
                      py='5px'
                      mt='5px'
                      colorScheme={'red'}
                      onClick={disableCredential(c.id)}
                    >
                      Desactivar
                    </Button>
                  )}
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
      {
        selectedCrendentialId &&
        <DisableCredentialsModal
          id={selectedCrendentialId}
          isOpen={isOpenDisable}
          onClose={onCloseDisable}
          onSuccess={onSuccess}
          onError={onError}
        />
      }
    </>
  )
}

export default CredentialsTable
