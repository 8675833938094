export const Joystick = ({
  height = '24px',
  width = '24px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.5 8.65C22.4112 7.38549 21.8455 6.20189 20.9173 5.33863C19.989 4.47536 18.7676 3.99689 17.5 4H7.49996C6.21772 3.99827 4.98382 4.48921 4.05326 5.37138C3.12271 6.25354 2.56663 7.45949 2.49996 8.74C2.49432 8.82657 2.49432 8.91342 2.49996 9V16.5C2.4973 16.9604 2.58602 17.4167 2.76097 17.8425C2.93593 18.2684 3.19363 18.6553 3.51917 18.9808C3.8447 19.3063 4.23159 19.564 4.65743 19.739C5.08327 19.9139 5.53959 20.0027 5.99996 20C7.42996 20 8.31996 18.94 9.18996 17.91C9.50996 17.54 9.83996 17.15 10.19 16.81C10.6543 16.4699 11.1759 16.2158 11.73 16.06C12.2415 16 12.7584 16 13.27 16.06C13.824 16.2158 14.3456 16.4699 14.81 16.81C15.16 17.15 15.49 17.54 15.81 17.91C16.68 18.91 17.57 20 19 20C19.4603 20.0027 19.9166 19.9139 20.3425 19.739C20.7683 19.564 21.1552 19.3063 21.4807 18.9808C21.8063 18.6553 22.064 18.2684 22.2389 17.8425C22.4139 17.4167 22.5026 16.9604 22.5 16.5V9C22.5054 8.91342 22.5054 8.82658 22.5 8.74V8.65ZM20.5 16.5C20.5 16.8978 20.3419 17.2794 20.0606 17.5607C19.7793 17.842 19.3978 18 19 18C18.5 18 18 17.36 17.34 16.62C17 16.23 16.62 15.77 16.19 15.36C15.4659 14.7767 14.628 14.3509 13.73 14.11C12.9165 13.9633 12.0834 13.9633 11.27 14.11C10.3719 14.3509 9.53402 14.7767 8.80996 15.36C8.37996 15.77 7.99996 16.23 7.65996 16.62C7.03996 17.36 6.49996 18 5.99996 18C5.60213 18 5.2206 17.842 4.9393 17.5607C4.65799 17.2794 4.49996 16.8978 4.49996 16.5V9C4.50484 8.95012 4.50484 8.89988 4.49996 8.85C4.53847 8.08063 4.87147 7.35556 5.42996 6.825C5.98845 6.29443 6.72963 5.99904 7.49996 6H17.5C18.2494 5.99673 18.9729 6.27408 19.5281 6.77747C20.0833 7.28086 20.43 7.97383 20.5 8.72V8.84C20.495 8.89322 20.495 8.94678 20.5 9V16.5Z"
        fill="white"
      />
      <path
        d="M16.5 13C17.0523 13 17.5 12.5523 17.5 12C17.5 11.4477 17.0523 11 16.5 11C15.9477 11 15.5 11.4477 15.5 12C15.5 12.5523 15.9477 13 16.5 13Z"
        fill="white"
      />
      <path
        d="M18.5 11C19.0523 11 19.5 10.5523 19.5 10C19.5 9.44772 19.0523 9 18.5 9C17.9477 9 17.5 9.44772 17.5 10C17.5 10.5523 17.9477 11 18.5 11Z"
        fill="white"
      />
      <path
        d="M16.5 9C17.0523 9 17.5 8.55228 17.5 8C17.5 7.44772 17.0523 7 16.5 7C15.9477 7 15.5 7.44772 15.5 8C15.5 8.55228 15.9477 9 16.5 9Z"
        fill="white"
      />
      <path
        d="M14.5 11C15.0523 11 15.5 10.5523 15.5 10C15.5 9.44772 15.0523 9 14.5 9C13.9477 9 13.5 9.44772 13.5 10C13.5 10.5523 13.9477 11 14.5 11Z"
        fill="white"
      />
      <path
        d="M8.5 12C9.60457 12 10.5 11.1046 10.5 10C10.5 8.89543 9.60457 8 8.5 8C7.39543 8 6.5 8.89543 6.5 10C6.5 11.1046 7.39543 12 8.5 12Z"
        fill="white"
      />
    </svg>
  )
}

export default Joystick
