import { FC, useState, useEffect } from 'react';
import {
  Center,
  Heading,
  VStack,
  Button,
  Box,
  Text,
  Flex,
  Select,
  Spinner,
} from '@chakra-ui/react';
import { useAdminTournaments } from '@olagg/api-hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../consts/routes';
import { FaPlus } from 'react-icons/fa';
import Pagination from '@olagg/ui-kit/Pagination';
import TournamentsTable from './Table';
import { IUseTournaments } from '@olagg/api-hooks/interfaces/IUseTournaments';
import Option from '../../components/Option';
import Filter from './Filter';

const Tournaments: FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<IUseTournaments>({
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 10),
    sortByStartDate: searchParams.get('sortByStartDate') as 'asc' | 'desc' || 'desc'
  })

  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  const { page, limit, game } = filter

  const { all, deleteTournament, setGamesList, togleSortByStartDate, isLoading, sortByStartDate, tournaments, games, count } = useAdminTournaments()

  useEffect(() => {
    setGamesList()
  }, [])

  useEffect(() => {
    onFilter({ name: 'sortByStartDate', value: sortByStartDate })
  }, [sortByStartDate])

  useEffect(() => {
    all(filter)
  }, [filter])

  return (
    <Center w='full' pb={4}>
      <VStack color={'white'} w='full'>
        <Heading my={2}>
          Torneos
        </Heading>

        <Flex w='full' alignItems='center' gap='5'>
        {/* <Flex w='full' justifyContent='space-between' p={5}> */}
          <Button
            mt='2' mb='5'
            display='flex'
            variant="outlinedGradient"
            leftIcon={<FaPlus />}
            onClick={() => navigate(ROUTES.TOURNAMENTS.create)}
          >
            Agregar Torneo
          </Button>

          <Select 
            mt='2' mb='5'
            maxW='200px' 
            onChange={(e) => onFilter({
              name: 'game',
              value: e.target.value
            } as EventTarget & HTMLInputElement)}
          >
            <Option value=''>Todos los juegos</Option>
            {games.map(game => (
              <Option key={game.slug} value={game.slug}>{game.title}</Option>
            ))}
          </Select>

          <Filter onFilter={onFilter} />
        </Flex>

        {isLoading ? (
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
        ) : (
          tournaments?.length > 0 ? (
            <TournamentsTable 
              tournaments={tournaments} 
              deleteTournament={deleteTournament}
              sortByStartDate={sortByStartDate}
              togleSortByStartDate={togleSortByStartDate}
            />
          ) : (
            <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
              <Text>No hay torneos cargados</Text>
            </Box>
          )
        )}

        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: String(page + 1)
            } as EventTarget & HTMLInputElement)
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            } as EventTarget & HTMLInputElement)
          }
          hideLimitInput
        />
      </VStack>
    </Center >
  )
};

export default Tournaments;
