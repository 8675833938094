import type { InferType } from 'yup';
import * as Yup from 'yup';

export type CredentialRequired = {
  email: boolean,
  password: boolean,
  username: boolean,
  walletAddress: boolean,
  seedPhrase: boolean,
  nftIdentifier: boolean,
  userNotes: boolean,
}

export const credentialInput = Yup.object({
  gameId: Yup
    .object({
      value: Yup
        .number()
        .nullable()
        .optional(),
      label: Yup
        .string()
        .nullable()
        .optional(),
    }).required("El juego es requerido"),
  username: Yup.string(),
  email: Yup.string(),
  password: Yup.string(),
  walletAddress: Yup.string(),
  seedPhrase: Yup.string(),
  blockchain: Yup
    .object({
      value: Yup
        .string()
        .nullable()
        .optional(),
      label: Yup
        .string()
        .nullable()
        .optional(),
    }).required("Blockchain es requerida"),
  personal: Yup.boolean().default(false),
  nftIdentifier: Yup.string(),
  userNotes: Yup.string().optional(),
  adminNotes: Yup.string().required("Las notas del admin son requeridas"),
  built: Yup.boolean().default(false),
  userRequired: Yup.array().of(Yup.string()).default([]),
  notShow: Yup.array().of(Yup.string()).default([]).optional().nullable(),
})

export type CredentialInput = InferType<typeof credentialInput>
