import { Tr, Th, Center, Spinner, Thead, Tbody, Td, Button, Tfoot, Table as ChakraTable, useToast } from "@chakra-ui/react"
import { UserContent, UserContentStatus } from "@olagg/db-types"
import { Link } from "react-router-dom"
import { ROUTES } from "../../consts/routes"
import moment from 'moment'
import {
  fetcher,
  AdminUserContentEndpoints,
} from '@olagg/api-hooks'

const Trs = () => {
  return (
    <Tr>
      <Th color="white">Juego</Th>
      <Th color="white">Título</Th>
      <Th color="white">Contenido</Th>
      <Th color="white">Usuario</Th>
      <Th color="white">Creado</Th>
      <Th color="white">Estado</Th>
      <Th color="white">Acciones</Th>
    </Tr>
  )
}

interface IProps {
  userContents?: (UserContent)[]
  isLoading: boolean
  error: boolean
}

const UserContentTAble = ({
  userContents = [],
  isLoading,
  error
}: IProps) => {
  if (!userContents.length) {
    if (isLoading)
      return (
        <Center h="full">
          <Spinner />
        </Center>
      )
    if (error)
      return <Center h="full">Se ha producido un error</Center>

    return <Center h="full">No hay resultados</Center>
  }

  const toast = useToast()

  const updateStatus = (data: UserContent, status: UserContentStatus) => {

    const body: Partial<UserContent> = {
      title: data.title,
      description: data.description,
      type: data.type,
      user: { id: data.userId },
      userId: data.user.id,
      gameId: data.gameId,
      content: data.content,
      status: status,
      active: data.active,
    }

    const endpoint = status == UserContentStatus.APPROVED ? AdminUserContentEndpoints.approve : AdminUserContentEndpoints.reject

    fetcher(
      endpoint(data.id!, body)
    )
      .then(() =>
        onSuccess(
          'Contenido editado correctamente',
          'El contenido fue editado con éxito'
        )
      )
      .catch(onError)
  }

  const onSuccess = async (title: string, body?: string) => {
    toast({
      title,
      colorScheme: 'olaggPink',
      status: 'success',
      description: body || 'El contenido fue creado con éxito',
      position: 'bottom-right'
    })
    setTimeout(() => {
      location.reload()
    }, 750)
  }

  const onError = (e: { details: string }) => {
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: e.details
          }}
        ></div>
      ),
      position: 'bottom-right'
    })
  }

  return (
    <ChakraTable variant="striped" colorScheme="olaggPurple">
      <Thead position='sticky' top='0' bg='gray.800' zIndex='1'>
        <Trs />
      </Thead>
      <Tbody>
        {userContents?.map(userContent => (
          <Tr key={userContent.id}>
            <Td>{userContent?.game.slug}</Td>
            <Td>{userContent?.title}</Td>
            <Td>
              <a href={userContent?.content} target='_blank'>
                <Button colorScheme="white">Ver video</Button>
              </a>
            </Td>
            <Td>
              {userContent?.user?.name}<br />
              <strong>(ID: {userContent?.user?.id})</strong>
            </Td>
            <Td>{moment(userContent?.createdAt).format('DD/MM/YYYY')}</Td>
            <Td>{userContent?.status}</Td>
            <Td>
              <Link
                to={`${ROUTES.USER_CONTENT.all}/${userContent.id}`}
              >
                <Button ml='5px' size={'sm'} py='5px' mt='5px' colorScheme="blue">Ver</Button>
              </Link>
              {(userContent?.status != UserContentStatus.APPROVED) && (
                <Button onClick={() => { updateStatus(userContent, UserContentStatus.APPROVED) }} ml='5px' size={'sm'} py='5px' mt='5px' colorScheme="green">Aprobar</Button>
              )}
              {(userContent?.status != UserContentStatus.REJECTED) && (
                <Button onClick={() => { updateStatus(userContent, UserContentStatus.REJECTED) }} ml='5px' size={'sm'} py='5px' mt='5px' colorScheme="red">Rechazar</Button>
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </ChakraTable>
  )
}

export default UserContentTAble
