import { Center, Heading, useToast, VStack } from "@chakra-ui/react"
import { AdminTournamentsEndpoints, fetcher } from "@olagg/api-hooks";
import { CreateTournamentParams } from "@olagg/db-types";
import { FormTournamentData } from "@olagg/validation-schemas/tournament.schemas";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../consts/routes";
import TournamentForm from "./Form"

const CreateTournament = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { state } = useLocation();

  const onSubmit = (dataForm: FormTournamentData) => {

    const tournamentData: CreateTournamentParams = {
      ...dataForm,
      inscriptionBases: {
        min: dataForm.inscriptionBasesMin,
        max: dataForm.inscriptionBasesMax,
        openAt: dataForm.inscriptionBasesOpenAt,
        closeAt: dataForm.inscriptionBasesCloseAt,
        price: dataForm.inscriptionBasesPrice,
        link: dataForm.inscriptionBasesLink,
      },
      gameId: dataForm.gameId.value
    };

    fetcher(AdminTournamentsEndpoints.create(tournamentData))
      .then(() => {
        toast({
          title: 'Torneo',
          colorScheme: 'olaggPink',
          status: 'success',
          description: `Torneo creado con éxito`,
          position: 'bottom-right'
        });
        navigate(ROUTES.TOURNAMENTS.all);
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
  }

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Crear Torneo
        </Heading>
        <TournamentForm onSubmit={onSubmit} tournament={state?.data} />
      </VStack>
    </Center>
  )
}

export default CreateTournament
