import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import MultiSelectModal from '@olagg/ui-kit/MultiSelectModal'
import Edit from '@olagg/ui-kit/svg/Edit'
import { useState } from 'react'
import { FieldValues, UseFormSetValue } from 'react-hook-form'

const PLATFORMS = [
  'WINDOWS',
  'MAC',
  'LINUX',
  'IOS',
  'ANDROID',
  'WEB'
]

export default function ChoosePlatforms({
  platforms,
  setValue
}: {
  platforms?: string[]
  //TODO: properly type below
  setValue: UseFormSetValue<any>
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [selectedPlatforms, setSelectedPlatforms] = useState<
    string[]
  >(platforms || [])

  const handlePlatforms = (platforms: string[]) => {
    setValue('platforms', platforms)
    setSelectedPlatforms(platforms)
    onClose()
  }

  return (
    <FormControl pt={4}>
      <FormLabel htmlFor="categories">Plataformas</FormLabel>
      <Flex
        justify="space-between"
        align="end"
        cursor="pointer"
        onClick={onOpen}
      >
        {!selectedPlatforms?.length ? (
          <Text
            color="gray.500"
            fontWeight="400"
            fontSize="18px"
          >
            Selecciona las plataformas
          </Text>
        ) : (
          <Box
            display="inline-flex"
            flexWrap="wrap"
            w="100%"
            gap="1"
          >
            {selectedPlatforms?.map(platform => (
              <Flex
                key={platform}
                bg="olaggPink.100"
                p={1}
                borderRadius="4px"
              >
                <Text fontSize="15px" fontWeight="600">
                  {platform}
                </Text>
              </Flex>
            ))}
          </Box>
        )}
        <Edit color="#CBD5E0" />
      </Flex>

      <MultiSelectModal
        title="Selecciona las plataformas"
        defaultValues={PLATFORMS}
        loadedValues={selectedPlatforms}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handlePlatforms}
      />
    </FormControl>
  )
}
