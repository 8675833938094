import { Center, Text } from '@chakra-ui/react'
import { useAdminQuest } from '@olagg/api-hooks'
import { useParams } from 'react-router-dom'
import QuestForm from '../components/Forms/QuestForm'

interface IProps {
  mode: 'edit' | 'create'
}

// TODO: Use the param to get the mode. By default will be 'view'
const ManageQuest = ({ mode }: IProps) => {
  const params = useParams()

  const {
    quest,
    error,
    isLoading
  } = useAdminQuest(params?.id || '')

  if (quest || mode == 'create') return <QuestForm mode={mode} quest={quest} />

  return (
    <Center h="80vh" color="white">
      {isLoading && <Text>Loading...</Text>}
      {!isLoading && (error || !quest) && (
        <Text>Quest not found</Text>
      )}
    </Center>
  )
}

export default ManageQuest
