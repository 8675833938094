import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  Switch,
} from '@chakra-ui/react'
import { fetcher, Method } from '@olagg/api-hooks'
import {
  GameCredentials,
  ScholarshipApplication
} from '@olagg/db-types'
import { useState } from 'react'

interface IProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: (s: string, b?: string) => void
  onError: (s: string) => void
  currentApplication?: ScholarshipApplication | undefined
  currentCredentials?: GameCredentials | undefined
}

const RevokeModal = ({
  isOpen,
  onClose,
  currentApplication,
  currentCredentials,
  onSuccess,
  onError
}: IProps) => {
  const [revokeReasons, setRevokeReasons] = useState('')
  const [disableCredentials, setDisableCredentials] =
    useState(false)

  const revoke = async () => {
    if (!revokeReasons)
      return onError('Debes rellenar los motivos')
    if (!currentApplication) return

    try {
      await fetcher({
        method: Method.PUT,
        path: `/admin/scholarships/${currentApplication.id}/suspend`,
        body: {
          message: revokeReasons
        }
      })
      if (disableCredentials) {
        await fetcher({
          method: Method.PUT,
          path: `/admin/credentials/${currentCredentials!.id}`,
          body: {
            active: false
          }
        })
      }
      onSuccess(
        'Beca revocada correctamente',
        'La beca fue revocada con éxito'
      )
    } catch (error: any) {
      onError(error.message)
    }
    onClose()
  }

  const toggleDisable = () =>
    setDisableCredentials(!disableCredentials)

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={5}>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mt={4} textAlign={'center'}>
            <Heading fontSize={'24px'} color={'olaggPurple.100'}>
              ¿Estás seguro que quieres revocar esta beca?
            </Heading>
            <Box>
              <Text fontSize={'16px'} fontFamily={'text'}>
                Por favor escribe los motivos
              </Text>
            </Box>
            <Box mt={5}>
              <Textarea
                value={revokeReasons}
                onChange={e => setRevokeReasons(e.target.value)}
                focusBorderColor="olaggPurple.100"
              />
            </Box>
          </Box>
          {currentCredentials?.active && (
            <Flex w="full" align="center" mt={10}>
              <FormLabel htmlFor="disableCredentials" pt={4}>
                ¿Quieres también desactivar esta beca? (Quedará
                fuera de circulación y será archivada, no podrá
                ser re-asignada)
              </FormLabel>
              <Switch onClick={toggleDisable} />
            </Flex>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            variant={'filledGradient'}
            w={'full'}
            onClick={revoke}
          >
            Aceptar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default RevokeModal
