import {
  Box,
  Button,
  Checkbox,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast
} from '@chakra-ui/react'
import { fetcher, Method } from '@olagg/api-hooks'
import type {
  ApplicationStatus,
  ScholarshipApplication
} from '@olagg/db-types'
import { useState } from 'react'

interface IProps {
  isOpen: boolean
  onClose: () => void
  currentAction: ApplicationStatus
  setCurrentAction: (value: ApplicationStatus) => void
  currentApplication: ScholarshipApplication | undefined
  setCurrentApplication: (
    value: ScholarshipApplication | undefined
  ) => void
  onAction: () => Promise<void>
}

const ManageScholarshipModal = ({
  isOpen,
  onClose,
  currentAction,
  setCurrentAction,
  currentApplication,
  setCurrentApplication,
  onAction
}: IProps) => {
  const toast = useToast()

  const [confirmAction, setConfirmAction] = useState(false)

  const computedAction =
    currentAction === 'APPROVED' ? 'approve' : 'reject'

  const onClick = async () => {
    if (!currentApplication || !confirmAction) return

    const path = `/admin/scholarships/${currentApplication.id}`

    await fetcher({ method: Method.PUT, path, body: { status: currentAction.toLowerCase() } })
      .then(async () => {
        await onAction()

        setCurrentAction('APPROVED')
        setConfirmAction(false)
        setCurrentApplication(undefined)
        toast({
          title: 'Éxito',
          colorScheme: 'olaggPink',
          status: 'success',
          description: `Solicitud  ${computedAction === 'approve'
            ? 'aprobada '
            : 'rechazada '
            } correctamente`,
          position: 'bottom-right',
          duration: 1000
        })
      })
      .catch((e: any) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: e.error,
          position: 'bottom-right',
          isClosable: true,
          duration: 5000
        })

      })
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={5}>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box pt={4} px={1}>
            <Heading
              fontSize={'24px'}
              color={'olaggPurple.100'}
              pb={4}
              textAlign={'center'}
            >
              {computedAction === 'approve'
                ? 'Aprobar '
                : 'Rechazar '}
              solicitud
            </Heading>
            <Box>
              <Text
                fontSize={'16px'}
                fontFamily={'text'}
                textAlign={'center'}
              >
                ¿Seguro que deseas{' '}
                <span
                  style={{
                    backgroundColor:
                      computedAction === 'approve'
                        ? 'green'
                        : 'red',
                    color: 'white'
                  }}
                >
                  {computedAction === 'approve'
                    ? 'aprobar '
                    : 'rechazar '}
                </span>{' '}
                esta solicitud para {currentApplication?.game?.slug}?
              </Text>
            </Box>
            <Box pt={5} px={6}>
              <Checkbox
                fontFamily={'text'}
                checked={confirmAction}
                onChange={() => {
                  setConfirmAction(prev => !prev)
                }}
              >
                Si, estoy seguro
              </Checkbox>
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            variant={'filledGradient'}
            w={'full'}
            onClick={onClick}
          >
            Aceptar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ManageScholarshipModal
