import {
  Avatar,
  Box,
  Button,
  Flex,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import { useAuthStore } from '@olagg/store'
import { ProfileHexagon } from '@olagg/ui-kit'
import { ReactNode, useState } from 'react'
import avatar from '@olagg/ui-kit/svg/avatar.svg'

interface IProps {
  breadcrums: ReactNode
  fixed?: boolean
}

export default function Navbar({
  breadcrums,
  fixed = false
}: IProps) {
  const [scrolled, setScrolled] = useState(false)

  const { me, logout } = useAuthStore()
  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let navbarPosition = 'absolute'
  let navbarFilter = 'none'
  let navbarBackdrop = 'blur(21px)'
  let navbarShadow = 'none'
  let navbarBg = 'none'
  let navbarBorder = 'transparent'
  let paddingX = '15px'

  if (fixed === true)
    if (scrolled === true) {
      navbarPosition = 'fixed'
      navbarShadow = useColorModeValue(
        '0px 7px 23px rgba(0, 0, 0, 0.05)',
        'none'
      )
      navbarBg = useColorModeValue(
        'linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)',
        'linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)'
      )
      navbarBorder = useColorModeValue(
        '#FFFFFF',
        'rgba(255, 255, 255, 0.31)'
      )
      navbarFilter = useColorModeValue(
        'none',
        'drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))'
      )
    }

  const changeNavbar = () => {
    if (window.scrollY > 1) setScrolled(true)
    else setScrolled(false)
  }
  window.addEventListener('scroll', changeNavbar)

  return (
    <Box
      position="absolute"
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems="center"
      borderRadius="16px"
      left={{ base: '20px', lg: '240px' }}
      right="0px"
      px={{ base: paddingX, md: '30px' }}
      ps={{ xl: '12px' }}
      pt="8px"
      top="18px"
      w={{ xl: 'calc(100vw - 240px)' }}
      zIndex={9}
    >
      <Flex w="100%" alignItems="center">
        <Box>{breadcrums}</Box>

        {/* Elements on the right side */}
        <Flex
          align="center"
          ms="auto"
          cursor="pointer"
          onClick={() => logout()}
        >
          <ProfileHexagon img={me?.image || avatar} w="40px" />
          <Text color="white">Sign Out</Text>
        </Flex>
      </Flex>
    </Box>
  )
}
