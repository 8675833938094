import { Logo } from '@olagg/ui-kit'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import Navbar from '../Navbar/Navbar'
import Sidebar from '../Sidebar'
import { routes } from './routes'

const Navigation = () => {
  const style = {
    // bg: 'linear-gradient(90deg, #4e10ff00 0%, #AB17DF 100%)',
    bg: 'linear-gradient(90deg, #000000 0%, #AB17DF 100%)',
    w: '200px',
    borderRadius: '16px',
  }
  return (
    <>
      <Navbar breadcrums={<Breadcrumbs />} />
      <Sidebar
        routes={routes}
        logo={<Logo width={40} />}
        style={style}
      />
    </>
  )
}

export default Navigation
