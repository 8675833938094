import { Flex, InputGroup, Input, InputRightElement, Box } from "@chakra-ui/react"
import { useAdminUsers } from "@olagg/api-hooks"
import { IUseTournaments } from "@olagg/api-hooks/interfaces/IUseTournaments"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { BsSearch } from "react-icons/bs"
import OlaggForm from "../../../components/Forms/OlaggForm"
import OlaggFormSelect from "../../../components/Forms/OlaggFormSelect"

const Filter = ({
  onFilter,
}: {
  onFilter: (filter: any) => void,
}) => {

  const [query, setQuery] = useState<string>('')

  const { users } = useAdminUsers({
    page: 1, limit: 100, query, withWallets: false
  })

  const userOptions = users.map(user => ({
    label: user.name,
    value: user.id
  }))

  const winnersOptions = [
    { label: 'Todos', value: false },
    { label: 'Solo ganadores', value: true },
  ]

  const useFormMethods = useForm<IUseTournaments>({
    mode: 'onChange',
  })

  const handleUserIdChange = (data: { label: string, value: number }) => {
    if (data) {
      onFilter({ name: 'userId', value: data.value })
    } else {
      onFilter({ name: 'userId', value: undefined })
    }
  }

  const handleWinnersChange = (data: { label: string, value: boolean }) => {
    if (data) {
      onFilter({ name: 'onlyWinners', value: data.value })
    } else {
      onFilter({ name: 'onlyWinners', value: undefined })
    }
  }

  return (
    <OlaggForm
      useFormMethods={useFormMethods}
      onSubmit={onFilter}
    >
      <Flex mx={5} justifyContent={"space-between"} w="100%">
        <Box w="50%" mr="15px" zIndex={9999}>
          <OlaggFormSelect
            controlName="userId"
            placeholder="Usuario"
            staticOptions={userOptions}
            isSearchable={true}
            onInputChange={setQuery}
            onChange={handleUserIdChange}
          />
        </Box>
        <Box w="50%" zIndex={9999}>
          <OlaggFormSelect
            controlName="onlyWinners"
            placeholder="Ganadores"
            staticOptions={winnersOptions}
            isSearchable={true}
            onInputChange={setQuery}
            onChange={handleWinnersChange}
          />
        </Box>
      </Flex>
    </OlaggForm>
  )
}

export default Filter
