import { InscriptionData } from "@olagg/db-types"
import { AdminTournamentsEndpoints } from "../endpoints/adminEndpoints"
import { IUseInscriptions } from "../interfaces/IUseInscriptions"
import { useQ } from "./useQ"

export const useAdminInscriptions = (filters: IUseInscriptions) => {
  // const response = useQ<{ inscriptions: InscriptionData[], meta: { count: number } }>(
  const response = useQ<{ intentions: InscriptionData[], participations: InscriptionData[], meta: { count: number } }>(
    AdminTournamentsEndpoints.inscriptions(filters), { revalidateOnFocus: false } 
  )

  return {
    ...response,
    inscriptions: [...(response.data?.intentions ?? []), ...(response.data?.participations ?? [])],
    count: response.data?.meta?.count ?? 0
  }
}
