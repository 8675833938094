import {
  Button,
  Center,
  Flex,
  useToast,
  VStack,
  Heading,
  Switch,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  fetcher,
  AdminOwnersEndpoints,
  useAdminGames,
  useAdminOwnerQuests,
} from '@olagg/api-hooks'
import { QuestOwner } from '@olagg/db-types'
import { formOwnerSchema, OwnerForm } from '@olagg/validation-schemas'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { shortenString } from '../../utils'
import OlaggForm from './OlaggForm'
import OlaggFormInput from './OlaggFormInput'
import OlaggFormSelect from './OlaggFormSelect'

interface IProps {
  owner: QuestOwner | null
  mode: 'edit' | 'create'
}

const QuestOwnerForm: FC<IProps> = ({ mode, owner = {} }) => {
  const [gameFilter, setGameFilter] = useState()
  const toast = useToast()
  const navigate = useNavigate()

  const isCreate = mode === 'create'
  const isEdit = mode === 'edit'

  const [heading, setHeading] = useState(`${isCreate ? 'Creando nuevo' : 'Editar'} Owner`)

  const { games } = useAdminGames({
    title: gameFilter,
    page: 1,
    limit: 1000
  })

  const { owners } = useAdminOwnerQuests({
    page: 1,
    limit: 1000
  })

  const useFormMethods = useForm<OwnerForm>({
    resolver: yupResolver(formOwnerSchema),
    mode: 'onChange'
  });

  const [isEditable, setIsEditable] = useState(
    isEdit ? false : true
  )

  const gameOptions = games.map(game => ({
    value: game.id,
    label: game.title
  })) || [{ value: '', label: 'No hay juegos disponibles' }]

  const orderOptions = owners?.map(owner => ({
    value: owner.order,
    label: `${owner.order} - ${owner.name}`
  })) || [{ value: '', label: 'No hay owners disponibles' }]

  const onSubmit = (data: OwnerForm) => {
    const body: Partial<QuestOwner> = {
      ...data,
      site: data.site || undefined,
      discordId: data.discordId || undefined,
      discordUsername: data.discordUsername || undefined,
      twitterId: data.twitterId || undefined,
      twitterUsername: data.twitterUsername || undefined,
      gameId: data.gameId?.value || undefined,
      order: data.order?.value
    }
    if (isCreate) {
      fetcher(AdminOwnersEndpoints.create(body))
        .then(() => onSuccess('Owner creado correctamente'))
        .catch(e => onError(e))
    }

    if (isEdit) {
      fetcher(
        AdminOwnersEndpoints.update(owner!.id!, body)
      ).then(() =>
        onSuccess('Owner editado correctamente', 'El Owner fue editado con éxito')
      ).catch(onError)
    }
  }

  const onSuccess = async (title: string, body?: string) => {
    if (mode === 'create') useFormMethods.reset()

    toast({
      title,
      colorScheme: 'olaggPink',
      status: 'success',
      description: body || 'El Owner fue creado con éxito',
      position: 'bottom-right'
    })
    if (mode === 'edit') {
      setTimeout(() => { location.reload() }, 750)
    }
  }

  const onError = (e: { message: string } | string) => {
    const message = typeof e === 'string' ? e : e.message
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: message
          }}
        ></div>
      ),
      position: 'bottom-right'
    })
  }

  useEffect(
    () => {
      useFormMethods.setValue('name', owner?.name || '')
      useFormMethods.setValue('site', owner?.site)
      useFormMethods.setValue('discordId', owner?.discordId)
      useFormMethods.setValue('discordUsername', owner?.discordUsername)
      useFormMethods.setValue('twitterId', owner?.twitterId)
      useFormMethods.setValue('twitterUsername', owner?.twitterUsername)
      useFormMethods.setValue('gameId', {
        value: owner?.gameId,
        label: games.find(game => game.id === owner?.gameId)?.title || 'Seleccione un juego'
      })
      useFormMethods.setValue('order', {
        value: owner?.order || owners.length + 1,
        label: `${owner?.order} - ${owner?.name}` || 'Seleccione un juego'
      })
    }, [games]
  )

  useEffect(() => {
    if (isEdit)
      return setHeading(
        `${isEditable ? 'Editar ' : ''}Owner ${shortenString({
          string: owner!.name!,
          characters: 18
        })}`
      )
  }, [isCreate, isEditable])

  return (
    <Center w={'full'}>
      <VStack color={'white'}>
        <Heading my={2}>{heading}</Heading>
        {isEdit && (
          <Flex
            w="full"
            justify="end"
            align="center"
            style={{ marginTop: '30px' }}
          >
            <label
              htmlFor="enableEdition"
              style={{ cursor: 'pointer' }}
            >
              Habilitar edición
            </label>
            <Switch
              id="enableEdition"
              colorScheme="purple"
              size="md"
              ml={2}
              onChange={() => setIsEditable(prev => !prev)}
            />
          </Flex>
        )}
        <OlaggForm
          useFormMethods={useFormMethods}
          onSubmit={onSubmit}
          style={{
            width: '100%'
          }}
        >
          <fieldset
            disabled={!(isEditable || isCreate)}
          >
            <VStack
              w={{ base: '70vw', lg: '50vw' }}
              mt={2}
              mb={10}
            >
              <OlaggFormInput
                label='Nombre'
                controlName='name'
                placeholder='Ej: Ola Guild Games'
                required={true}
              />

              <OlaggFormInput
                label='Sitio web del owner'
                controlName='site'
                placeholder='https://olagg.io/'
              />

              <OlaggFormSelect
                label='Juego'
                controlName='gameId'
                required={false}
                isSearchable={true}
                staticOptions={gameOptions}
                onInputChange={setGameFilter}
              />

              <OlaggFormInput
                label='Discord ID'
                controlName='discordId'
              />

              <OlaggFormInput
                label='Discord Username'
                controlName='discordUsername'
              />
              
              <OlaggFormInput
                label='Twitter ID'
                controlName='twitterId'
              />

              <OlaggFormInput
                label='Twitter Username'
                controlName='twitterUsername'
              />
              
              <OlaggFormSelect
                label='Orden'
                controlName='order'
                required={true}
                isSearchable={false}
                staticOptions={orderOptions}
                onInputChange={setGameFilter}
              />

              {isEditable && (
                <Flex w="full" style={{ marginTop: '20px' }}>
                  <Button
                    variant="filledGradient"
                    type="submit"
                    flex="1"
                  >
                    {isCreate ? 'Crear Owner' : 'Guardar cambios'}
                  </Button>
                  {isEdit && (
                    <Button
                      ml={2}
                      variant="outlinedGradient"
                      onClick={() => navigate(-1)}
                      flex="1"
                    >
                      Descartar Cambios
                    </Button>
                  )}
                </Flex>
              )}
            </VStack>
          </fieldset>
        </OlaggForm>
      </VStack>
    </Center>
  )
}

export default QuestOwnerForm
