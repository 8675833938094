import {
  Button,
  Center,
  Flex,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Wrap,
  WrapItem
} from '@chakra-ui/react'
import { FC } from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

interface IProps {
  params: {
    count?: number
    page: number
    limit: number
  }
  hideLimitInput?: boolean
  onClick: (page: number) => void
  onLimitChange: (value: string) => void
}

const Pagination: FC<IProps> = ({
  params,
  hideLimitInput = false,
  onClick,
  onLimitChange
}) => {
  const { count = 0, limit } = params
  const page = params.page - 1;

  if (count === 0) return null;
  
  const pages = Array.from(
    { length: Math.ceil(count / +limit) },
    (v, i) => i
  )

  const lastPage = pages.length - 1
  const pagesToShow = (() => {
    const _pages = Array.from(
      { length: pages.length <= 5 ? pages.length : 5 },
      (v, i) => {
        if (page < 5) return i
        else return i ? page - i : page
      }
    )
    if (page > 4) return _pages.reverse()
    else return _pages
  })()

  const handleBack = () => {
    if (page - 1 >= 0) onClick(page - 1)
  }

  const handleNext = () => {
    if (page + 1 <= lastPage) onClick(page + 1)
  }

  const handleOnChange = (value: string) => {
    if (!value || Number(value) < 1) return onLimitChange('1')
    onLimitChange(value)
  }

  return (
    <Flex flexDir="column" my={5}>
      <Center gap="5">
        <IconButton
          colorScheme={'olaggBlue'}
          icon={<FaChevronLeft />}
          aria-label=""
          onClick={handleBack}
        />
        <Wrap>
          {pages.length > 5 && page > 4 && (
            <>
              <WrapItem>
                <Button
                  colorScheme={'olaggDark'}
                  onClick={() => onClick(0)}
                >
                  {1}
                </Button>
              </WrapItem>
              <WrapItem
                alignContent="center"
                margin="auto 16px !important"
              >
                ...
              </WrapItem>
            </>
          )}
          {pagesToShow.map(_page => (
            <WrapItem key={_page}>
              <Button
                colorScheme={
                  page === _page ? 'olaggPurple' : 'olaggDark'
                }
                onClick={() => onClick(_page)}
              >
                {_page + 1}
              </Button>
            </WrapItem>
          ))}
          {pages.length > 5 && page !== lastPage && (
            <>
              <WrapItem
                alignContent="center"
                margin="auto 16px !important"
              >
                ...
              </WrapItem>
              <WrapItem>
                <Button
                  colorScheme={
                    lastPage === page
                      ? 'olaggPurple'
                      : 'olaggDark'
                  }
                  onClick={() => onClick(lastPage)}
                >
                  {lastPage + 1}
                </Button>
              </WrapItem>
            </>
          )}
        </Wrap>
        <IconButton
          colorScheme={'olaggBlue'}
          icon={<FaChevronRight />}
          aria-label=""
          onClick={handleNext}
        />
      </Center>
      {!hideLimitInput && (
        <Center gap="5" mt="5">
          <Text fontSize="18px" fontWeight="semibold">
            Cantidad por página:{' '}
          </Text>
          <NumberInput
            onChange={handleOnChange}
            value={limit || 1}
            min={1}
            max={count || +limit}
          >
            <NumberInputField color="white" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Center>
      )}
    </Flex>
  )
}

export default Pagination
