export const Card = ({
    height = '21px',
    width = '20px',
    color = 'white',
    ...props
  }: React.SVGProps<SVGSVGElement>) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1667 19.425H5.83332C5.00738 19.4294 4.21326 19.1067 3.62456 18.5274C3.03586 17.9481 2.70045 17.1592 2.69165 16.3333V4.66666C2.70045 3.84076 3.03586 3.05192 3.62456 2.47259C4.21326 1.89326 5.00738 1.57054 5.83332 1.575H14.1667C14.9926 1.57054 15.7867 1.89326 16.3754 2.47259C16.9641 3.05192 17.2995 3.84076 17.3083 4.66666V16.3333C17.2995 17.1592 16.9641 17.9481 16.3754 18.5274C15.7867 19.1067 14.9926 19.4294 14.1667 19.425ZM5.83332 2.825C5.33888 2.82052 4.86269 3.01154 4.5084 3.35646C4.15412 3.70138 3.95042 4.17228 3.94165 4.66666V16.3333C3.95042 16.8277 4.15412 17.2986 4.5084 17.6435C4.86269 17.9885 5.33888 18.1795 5.83332 18.175H14.1667C14.6611 18.1795 15.1373 17.9885 15.4916 17.6435C15.8458 17.2986 16.0495 16.8277 16.0583 16.3333V4.66666C16.0495 4.17228 15.8458 3.70138 15.4916 3.35646C15.1373 3.01154 14.6611 2.82052 14.1667 2.825H5.83332Z"
          fill={color}
        />
        <path
          d="M10.0001 14.1C9.90079 14.101 9.80274 14.0781 9.71424 14.0331C9.62574 13.9881 9.54941 13.9225 9.49173 13.8417L7.35006 10.8667C7.2728 10.76 7.2312 10.6317 7.2312 10.5C7.2312 10.3683 7.2728 10.24 7.35006 10.1333L9.49173 7.16666C9.54848 7.08433 9.62441 7.01703 9.71297 6.97058C9.80153 6.92412 9.90006 6.8999 10.0001 6.9C10.0993 6.89896 10.1974 6.92191 10.2859 6.96688C10.3744 7.01186 10.4507 7.07753 10.5084 7.15833L12.6501 10.1333C12.7273 10.24 12.7689 10.3683 12.7689 10.5C12.7689 10.6317 12.7273 10.76 12.6501 10.8667L10.5084 13.8333C10.4516 13.9157 10.3757 13.983 10.2871 14.0294C10.1986 14.0759 10.1001 14.1001 10.0001 14.1ZM8.62506 10.5L10.0001 12.4083L11.3751 10.5L10.0001 8.59166L8.62506 10.5Z"
          fill={color}
        />
      </svg>
    )
  }
  
  export default Card
  