export const Knife = ({
  height = '21px',
  width = '20px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.6417 17.4833C2.51783 17.4824 2.39704 17.4446 2.29466 17.3749C2.19229 17.3052 2.11294 17.2066 2.0667 17.0917C2.01701 16.9791 2.00315 16.8539 2.027 16.7332C2.05085 16.6124 2.11126 16.5019 2.20003 16.4167L14.9084 3.7C15.0256 3.58295 15.1844 3.51721 15.35 3.51721C15.5157 3.51721 15.6745 3.58295 15.7917 3.7L17.8 5.70833C17.9171 5.82552 17.9828 5.98437 17.9828 6.15C17.9828 6.31562 17.9171 6.47447 17.8 6.59166L14.5417 9.85L16.35 11.6583C16.4584 11.7677 16.5226 11.9132 16.5303 12.067C16.538 12.2208 16.4886 12.372 16.3917 12.4917C16.225 12.6917 12.2084 17.4833 2.6417 17.4833ZM15.35 5.03333L4.1667 16.1833C10.4917 15.8 13.9 13.1333 15 12.1L13.1917 10.2917C13.0747 10.1745 13.0089 10.0156 13.0089 9.85C13.0089 9.68437 13.0747 9.52552 13.1917 9.40833L16.45 6.15L15.35 5.03333Z"
        fill={color}
      />
    </svg>
  )
}

export default Knife
