import type { Role, User } from '@olagg/db-types'
import { FC } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthStore } from '@olagg/store'

interface IProps {
  roles: Role[] // roles that can access the view
  children: JSX.Element
  me: User | undefined
  loggedIn?: boolean
}

const RequireAuth: FC<IProps> = ({
  children,
  roles,
  me,
  loggedIn
}) => {
  const location = useLocation()
  const { logout } = useAuthStore()
  if (!loggedIn) {
    return (
      <Navigate to="/login" state={{ from: location }} replace />
    )
  }

  if(me?.roles.some(r => roles.includes(r))) {
    return children
  } else {
    logout()
    return (
      <Navigate to="/login" state={{ from: location }} replace />
    )
  }

}

export default RequireAuth
