import { Center, Heading, VStack } from "@chakra-ui/react"; 
import NotificationForm from "../../components/Forms/NotificatioForm";

const CreateNotification = () => {

  return(
    <Center w={'full'} p={4} >
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Crear Notificación
        </Heading>

        <NotificationForm />
      </VStack>
    </Center>
  )
}

export default CreateNotification
