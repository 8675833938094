import { Flex, Box, HStack, Button, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { GameCredentials, ScholarshipApplication } from "@olagg/db-types"
import DisableCredentialsModal from "../../components/Modals/DisableCredentialsModal"
import ReassignModal from "../../components/Modals/ReassignModal"
import RevokeModal from "../../components/Modals/RevokeModal"

const ReassignCredential = ({
  credential,
  isEditable,
  isActive
}: {
  credential: GameCredentials,
  isEditable: boolean,
  isActive: boolean
}) => {
  const toast = useToast()

  const {
    isOpen: isOpenRevoke,
    onOpen: onOpenRevoke,
    onClose: onCloseRevoke
  } = useDisclosure()

  const {
    isOpen: isOpenReassign,
    onOpen: onOpenReassign,
    onClose: onCloseReassign
  } = useDisclosure()

  const {
    isOpen: isOpenDisable,
    onOpen: onOpenDisable,
    onClose: onCloseDisable
  } = useDisclosure()

  const { scholarship } = credential

  const onSuccess = async (title: string, body?: string) =>
    toast({
      title,
      colorScheme: 'olaggPink',
      status: 'success',
      description: body || 'La beca fue creada con éxito',
      position: 'bottom-right'
    })


  const onError = (e: { message: string } | string) => {
    const message = typeof e === 'string' ? e : e.message
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: message
          }}
        ></div>
      ),
      position: 'bottom-right'
    })
  }

  return (
    <>
      <RevokeModal
        isOpen={isOpenRevoke}
        onClose={onCloseRevoke}
        currentApplication={scholarship!}
        currentCredentials={credential!}
        onSuccess={onSuccess}
        onError={onError}
      />
      <DisableCredentialsModal
        id={credential?.id}
        isOpen={isOpenDisable}
        onClose={onCloseDisable}
        onSuccess={onSuccess}
        onError={onError}
      />
      <ReassignModal
        isOpen={isOpenReassign}
        onClose={onCloseReassign}
        currentApplication={scholarship!}
        currentCredentials={credential!}
        onSuccess={onSuccess}
        onError={onError}
      />
      {(credential?.user?.email) && (
        <Flex
          w="100%"
          justify="space-between"
          fontSize="18px"
          border="2px"
          borderColor="white"
          borderRadius={8}
          padding={5}
          align="center"
        >
          <Box>
            <Text>Asignada a:</Text>
            <Box>
              <Text
                fontSize={'16px'}
                textDecoration="underline"
                fontWeight={'semibold'}
              >
                {credential?.user?.email}
              </Text>
              {credential?.user?.email && (
                <Text>
                  (# {credential?.user?.id} )
                </Text>
              )}
            </Box>

          </Box>

          <HStack spacing={4} fontSize="18px">
            {isEditable && isActive && (
              <>
                <Button
                  variant="outlinedGradient"
                  onClick={onOpenReassign}
                >
                  Reasignar
                </Button>
                <Button
                  variant="outlinedGradient"
                  onClick={onOpenRevoke}
                >
                  Revocar
                </Button>
              </>
            )}
            {isActive && <Button
              variant="outlinedGradient"
              onClick={onOpenDisable}
            >
              Desactivar Beca
            </Button>}
          </HStack>
        </Flex>
      )}
    </>
  )
}

export default ReassignCredential
