import { GameCredentials, ScholarshipApplication } from "@olagg/db-types"
import { useState } from "react"
import { AdminCredentialsEndpoints, AdminScholarshipEndpoints } from "../endpoints/adminEndpoints"
import { fetcher } from "../fetcher"
import { IUseScholarships } from "../interfaces/IUseScholarships"

const useAdminScholarships = () => {

  const [scholarships, setScholarships] = useState<ScholarshipApplication[]>([])
  const [scholarship, setScholarship] = useState<ScholarshipApplication>()
  const [credential, setCredential] = useState<GameCredentials>()
  const [count, setCount] = useState<number>(0)

  const getAll = ({ slug, status, country, query, dateSort, page, limit }: IUseScholarships) => {
    const params = new URLSearchParams({
      ...(page && { page: page.toString() }),
      ...(limit && { limit: limit.toString() }),
      ...(slug && { slug }),
      ...(dateSort && { dateSort }),
      ...(status && { status }),
      ...(country && { country }),
      ...(query && { query })
    })

    fetcher(AdminScholarshipEndpoints.all(params))
      .then((data) => {
        setScholarships(data.scholarships)
        setCount(data.meta.count)
      })
      .catch(() => setScholarships([]))
  }

  const getCredential = (id: string) =>
    fetcher(AdminCredentialsEndpoints.find(id))
      .then((data) => {
        setCredential(data.credential)
        return data.credential
      })
      .catch(() => setCredential(undefined))

  return {
    scholarships,
    scholarship,
    credential,
    count,
    getAll,
    getCredential,
  }
}

export default useAdminScholarships
