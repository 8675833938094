export const Sword = ({
  height = '21px',
  width = '20px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99997 15.8833C9.83497 15.8797 9.67734 15.8143 9.5583 15.7L4.8083 10.9417C4.70406 10.8279 4.64624 10.6793 4.64624 10.525C4.64624 10.3707 4.70406 10.222 4.8083 10.1083L11.8 2.99998C11.9171 2.88274 12.0759 2.8168 12.2416 2.81665H17.0333C17.1976 2.81664 17.3553 2.88134 17.4723 2.99675C17.5893 3.11217 17.6561 3.26901 17.6583 3.43332L17.7083 8.16665C17.7085 8.33488 17.6427 8.49648 17.525 8.61665L10.4333 15.7083C10.3169 15.8205 10.1616 15.8832 9.99997 15.8833ZM6.12497 10.5L9.99997 14.3667L16.4666 7.90832L16.4166 4.05832L12.5 4.04165L6.12497 10.5Z"
        fill={color}
      />
      <path
        d="M12.325 18.2083C12.2429 18.2087 12.1615 18.1927 12.0857 18.1612C12.0098 18.1297 11.941 18.0834 11.8833 18.025L2.49999 8.61662C2.42384 8.49916 2.38932 8.35953 2.40197 8.22011C2.41463 8.08069 2.47373 7.94955 2.56979 7.84772C2.66586 7.74589 2.79333 7.67927 2.93178 7.65852C3.07023 7.63777 3.21163 7.6641 3.33333 7.73329L12.7667 17.1666C12.8837 17.2838 12.9494 17.4427 12.9494 17.6083C12.9494 17.7739 12.8837 17.9328 12.7667 18.05C12.7072 18.1043 12.6375 18.1462 12.5617 18.1734C12.4859 18.2006 12.4054 18.2125 12.325 18.2083Z"
        fill={color}
      />
      <path
        d="M5.83338 18.1583C5.74622 18.1673 5.65815 18.1578 5.57488 18.1306C5.49161 18.1033 5.41501 18.0588 5.35005 18L2.50005 15.125C2.44074 15.0645 2.39401 14.9928 2.36254 14.9142C2.33108 14.8355 2.3155 14.7514 2.31672 14.6667C2.31672 14.325 2.31672 14.3167 5.74172 10.9833C5.86106 10.8673 6.02162 10.8034 6.18806 10.8058C6.35449 10.8081 6.51318 10.8765 6.62922 10.9958C6.74525 11.1152 6.80912 11.2757 6.80677 11.4422C6.80443 11.6086 6.73606 11.7673 6.61672 11.8833C5.55005 12.925 4.38338 14.075 3.80838 14.6667L5.83338 16.65L8.59172 13.8333C8.64917 13.7744 8.71785 13.7275 8.79371 13.6955C8.86956 13.6635 8.95106 13.647 9.03338 13.647C9.11571 13.647 9.19721 13.6635 9.27306 13.6955C9.34891 13.7275 9.41759 13.7744 9.47505 13.8333C9.59209 13.9505 9.65783 14.1094 9.65783 14.275C9.65783 14.4406 9.59209 14.5995 9.47505 14.7167L6.23338 18C6.12154 18.0961 5.98068 18.1519 5.83338 18.1583Z"
        fill={color}
      />
      <path
        d="M9.99994 11.125C9.91782 11.1254 9.83645 11.1094 9.76061 11.0779C9.68477 11.0464 9.61598 11.0001 9.55827 10.9417C9.44123 10.8245 9.37549 10.6656 9.37549 10.5C9.37549 10.3344 9.44123 10.1755 9.55827 10.0583L12.8916 6.72499C12.9488 6.66358 13.0178 6.61433 13.0945 6.58017C13.1712 6.54601 13.2539 6.52764 13.3378 6.52616C13.4218 6.52468 13.5051 6.54012 13.5829 6.57155C13.6608 6.60298 13.7315 6.64977 13.7908 6.70912C13.8502 6.76847 13.8969 6.83916 13.9284 6.91699C13.9598 6.99481 13.9752 7.07817 13.9738 7.16209C13.9723 7.24601 13.9539 7.32877 13.9198 7.40543C13.8856 7.4821 13.8363 7.5511 13.7749 7.60832L10.4416 10.9417C10.3839 11.0001 10.3151 11.0464 10.2393 11.0779C10.1634 11.1094 10.0821 11.1254 9.99994 11.125Z"
        fill={color}
      />
    </svg>
  )
}

export default Sword
