import {
  Quest
} from '@olagg/db-types'
import { AdminQuestEndpoints } from '../endpoints/adminEndpoints'
import { IUseQuests } from '../interfaces/IUseQuests'
import { useQ } from './useQ'

export const useAdminQuests = ({
  title,
  page,
  limit
}: IUseQuests) =>{
  const response = useQ<{quests: Quest[], meta: {count: number}}>(
    AdminQuestEndpoints.all({title,page,limit}),
    {
      revalidateOnFocus: true
    }
  )

  return {
    ...response,
    quests: response.data?.quests ?? [],
    count: response.data?.meta.count ?? 0
  }
}