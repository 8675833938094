import { TypeOf, z } from 'zod'
import { SocialLinksType } from '../db-types'

const gameCategorySchema = z.object({
  id: z.number(),
  name: z.string(),
  longName: z.string(),
  icon: z.string().optional().nullable(),
  categoryType: z.string()
})

export const createGameSchema = z.object({
  body: z.object({
    slug: z
      .string({
        required_error: 'El slug del juego es requerido'
      })
      .min(
        3,
        'El slug del juego debe ser de mínimo 3 caracteres'
      )
      .max(
        64,
        'El slug del juego debe ser de máximo 64 caracteres'
      )
      .regex(
        /^[\w-]+$/,
        'El slug del juego contiene caracteres inválidos'
      ), // axie-infinity
    title: z
      .string({
        required_error: 'El título del juego es requerido'
      })
      .min(
        3,
        'El título del juego debe ser de mínimo 3 caracteres'
      )
      .max(
        64,
        'El título del juego debe ser de máximo 64 caracteres'
      )
      .regex(
        /^[\w _-]*$/,
        'El título del juego contiene caracteres inválidos'
      ),
    link: z.string().nullish().optional(),
    token: z.string().nullish().optional(),
    hideTokenMetrics: z.boolean().optional(),
    linkText: z.string().nullish().optional(),
    description: z
      .string({
        required_error: 'La descripción del juego es requerida'
      })
      .max(
        166,
        'La descripción del juego debe ser de máximo 166 caracteres'
      ),

    tos: z.string().optional(),
    longDescription: z
      .string({
        required_error: 'La descripción del juego es requerida'
      })
      .min(
        300,
        'La descripción del juego debe ser de mínimo 300 caracteres'
      ),
    newsroomDescription: z.boolean().optional(),
    image: z.string().nullish(),
    banner: z.string().nullish(),
    screenshots: z.array(z.string()).optional(),
    visible: z.boolean().optional(),
    status: z.string().optional(),
    blockchains: z.array(z.string()).optional(),
    categories: z
      .array(gameCategorySchema)
      .nonempty()
      .optional(),
    platforms: z.array(z.string()).optional(),
    index: z
      .number({
        required_error: 'El índice del juego es requerido'
      })
      .optional(),
    socialLinks: z.object({
      type: z.nativeEnum(SocialLinksType),
      link: z.string()
    }).array().optional()
  })
})

export const updateGameSchema = z.object({
  body: z.object({
    slug: z
      .string({
        required_error: 'El slug del juego es requerido'
      })
      .min(
        3,
        'El slug del juego debe ser de mínimo 3 caracteres'
      )
      .max(
        64,
        'El slug del juego debe ser de máximo 64 caracteres'
      )
      .regex(
        /^[\w-]+$/,
        'El slug del juego contiene caracteres inválidos'
      ) // axie-infinity
      .optional(),
    title: z
      .string({
        required_error: 'El título del juego es requerido'
      })
      .min(
        3,
        'El título del juego debe ser de mínimo 3 caracteres'
      )
      .max(
        64,
        'El título del juego debe ser de máximo 64 caracteres'
      )
      .regex(
        /^[\w _-]*$/,
        'El título del juego contiene caracteres inválidos'
      )
      .optional(),
    description: z
      .string()
      .max(
        166,
        'La descripción del juego debe ser de máximo 166 caracteres'
      )
      .optional(),
    longDescription: z
      .string({
        required_error: 'La descripción del juego es requerida'
      })
      .min(
        300,
        'La descripción del juego debe ser de mínimo 300 caracteres'
      ),
    image: z.string().nullish(),
    banner: z.string().nullish(),
    screenshots: z.array(z.string()).optional(),
    visible: z.boolean().optional(),
    status: z.string().optional(),
    blockchains: z.array(z.string()).optional(),
    categories: z.array(gameCategorySchema).nonempty(),
    platforms: z.array(z.string()).optional(),
    index: z
      .number({
        required_error: 'El índice del juego es requerido'
      })
      .optional()
  })
})

export const searchGamesSchema = z.object({
  body: z.object({
    text: z.string().nullish(),
    orderBy: z.enum(['AZ', 'ZA']).nullish().default('AZ')
  })
})

export type CreateGameInput = TypeOf<
  typeof createGameSchema
>['body']

export type UpdateGameInput = TypeOf<
  typeof updateGameSchema
>['body']

export type SearchGamesInput = TypeOf<
  typeof searchGamesSchema
>['body']

export const createGameContentSchema = z.object({
  body: z.object({
    gameId: z
      .string({
        required_error: 'El slug del juego es requerido'
      })
      .min(
        3,
        'El slug del juego debe ser de mínimo 3 caracteres'
      )
      .max(
        64,
        'El slug del juego debe ser de máximo 64 caracteres'
      )
      .regex(
        /^[\w-]+$/,
        'El slug del juego contiene caracteres inválidos'
      ),
    link: z.string({ required_error: 'El link es requerido' }),
    thumbnail: z.string({
      required_error: 'La imagen miniatura es requerida'
    }),
    type: z.string({
      required_error: 'El tipo de contenido es requerido'
    })
  })
})
