import { Method, Endpoint } from "./constants";
import { IUseQuests } from '../interfaces/IUseQuests'
import { 
  Quest,
  BatchCompleteQuest,
  Game,
  QuestOwner,
  Device,
  Notification,
  UserContent,
  CreateTournamentParams,
  UpdateTournamentData,
  InscriptionData,
  GameCredentials
} from '@olagg/db-types'
import { CreateTokenData, UpdateTokenData } from '@olagg/web3/dist/domain/types/token.type'
import { IUseUsers } from '../interfaces/IUseUsers'
import { IUseUserContent } from "../interfaces/IUserUserContent";
import { IUseItems } from "../interfaces/IUseItems";
import { IUseTournaments } from "../interfaces/IUseTournaments";
import { IUseInscriptions } from "../interfaces/IUseInscriptions";
import { IUseTokens } from "../interfaces/IUseTokens";
import { IUseAssignments } from "../interfaces/IUseAssignments";
import { IUseCredentials } from "../interfaces/IUseCredentials";

const parameterize = (params: object) => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    if (value) {
      return `${acc}&${key}=${value}`
    }
    return acc
  }, '')
}

export const AdminQuestEndpoints = {
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/quests/${id}`
  }),
  all: ({ title, page, limit }: IUseQuests) => ({
    method: Method.GET,
    path: `/admin/quests?page=${page}&limit=${limit}&title=${title ?? ''}`
  }),
  create: (data: Partial<Quest>) =>
  ({
    method: Method.POST,
    path: '/admin/quests',
    body: { quest: data }
  } as Endpoint<Partial<Quest>>),
  update: (id: string, data: Partial<Quest>) =>
  ({
    method: Method.PUT,
    path: `/admin/quests/${id}`,
    body: data
  } as Endpoint<Partial<Quest>>),
  delete: (id: string) => ({
    method: Method.DELETE as Method.DELETE,
    path: `/admin/quests/${id}`
  }),
  batchComplete: (id: string, data: BatchCompleteQuest) =>
  ({
    method: Method.PUT,
    path: `/admin/quests/bulk_complete`,
    body: data,
    isForm: true
  } as Endpoint<Partial<Quest>>),
}

export const AdminGameEndpoints = {
  all: ({ page, limit, title }: { page: number, limit: number, title?: string }) => ({
    method: Method.GET,
    path: `/admin/games?page=${page}&limit=${limit}&title=${title ?? ''}`
  }),
  find: (slug: string) => ({
    method: Method.GET,
    path: `/admin/games/${slug}`
  }),
  create: (data: Partial<Game>) => ({
    method: Method.POST,
    path: '/admin/games',
    body: { game: data }
  } as Endpoint<Partial<Quest>>),
  update: (slug: string, data: Partial<Game>) => ({
    method: Method.PUT,
    path: `/admin/games/${slug}`,
    body: { game: data }
  } as Endpoint<Partial<Quest>>),
}

export const AdminOwnersEndpoints = {
  all: ({ name, page, limit }: { name?: string, page: number, limit: number }) => ({
    method: Method.GET,
    path: `/admin/owners?name=${name ?? ''}&page=${page}&limit=${limit}`
  }),
  find: (address: string) => ({
    method: Method.GET,
    path: `/admin/owners/${address}`
  }),
  create: (data: Partial<QuestOwner>) => ({
    method: Method.POST,
    path: '/admin/owners',
    body: { owner: data }
  } as Endpoint<Partial<QuestOwner>>),
  update: (address: string, data: Partial<QuestOwner>) => ({
    method: Method.PUT,
    path: `/admin/owners/${address}`,
    body: { owner: data }
  } as Endpoint<Partial<QuestOwner>>),
}

export const AdminNotificationEndpoints = {
  all: ({
    page,
    limit,
    title,
    userId
  }: {
    page: number,
    limit: number,
    title?: string,
    userId?: number,
  }) => ({
    method: Method.GET,
    path: `/admin/notifications?page=${page}&limit=${limit}&title=${title ?? ''}&userId=${userId ?? ''}`
  }),
  create: (data: Partial<Notification>) => ({
    method: Method.POST,
    path: '/admin/notifications',
    body: { notification: data }
  }),
  subscribe: (data: Partial<Device>) => ({
    method: Method.POST,
    path: '/admin/notifications/subscriptions',
    body: data
  }),
}

export const AdminSegmentEndpoints = {
  all: ({ page, limit, name }: { page: number, limit: number, name?: string }) => ({
    method: Method.GET,
    path: `/admin/segments?page=${page}&limit=${limit}&name=${name ?? ''}`
  }),
  create: (body : { name: string, description: string }) => ({
    method: Method.POST,
    path: `/admin/segments`,
    body: body
  })
}

export const AdminUserEndpoints = {
  all: ({ page, limit, country, query, withWallets }: IUseUsers) => ({
    method: Method.GET,
    path: `/admin/users?page=${page}&limit=${limit}&country=${country ?? ''}&query=${query ?? ''}${withWallets ? '&withWallets=true' : ''}`
  }),
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/users/${id}`
  }),
}

export const AdminUserContentEndpoints = {
  all: ({ page, limit, title, gameId }: IUseUserContent) => ({
    method: Method.GET,
    path: `/admin/user_contents?page=${page}&limit=${limit}&title=${title ?? ''}&gameId=${gameId ?? ''}`
  }),
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/user_contents/${id}`
  }),
  update: (id: string, data: Partial<UserContent>) => ({
    method: Method.PUT,
    path: `/admin/user_contents/${id}`,
    body: { userContent: data }
  }),
  approve: (id: string, data: Partial<UserContent>) => ({
    method: Method.PUT,
    path: `/admin/user_contents/${id}/approve`,
    body: { userContent: data }
  }),
  reject: (id: string, data: Partial<UserContent>) => ({
    method: Method.PUT,
    path: `/admin/user_contents/${id}/reject`,
    body: { userContent: data }
  }),
}

export const AdminMarketPlaceEndpoints = {
  all: ({ page, limit, title }: IUseItems) => ({
    method: Method.GET,
    path: `/admin/items?page=${page}&limit=${limit}&title=${title ?? ''}`
  }),
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/items/${id}`
  }),
  create: (data: FormData) => ({
    method: Method.POST,
    path: '/admin/items',
    body: data,
    isForm: true
  }),
  update: (id: string, data: FormData) => ({
    method: Method.PUT,
    path: `/admin/items/${id}`,
    body: data,
    isForm: true
  }),
  delete: (id: string) => ({
    method: Method.DELETE,
    path: `/admin/items/${id}`
  }),
  stocks: (id: string) => ({
    method: Method.GET,
    path: `/admin/items/${id}/stocks`
  }),
}

export const AdminTournamentsEndpoints = {
  all: ({ page, limit, title, game, sortByStartDate }: IUseTournaments) => ({
    method: Method.GET,
    path: `/admin/tournaments?page=${page}&limit=${limit}&title=${title ?? ''}&slug=${game ?? ''}&sortByStartDate=${sortByStartDate ?? 'asc'}`
  }),
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/tournaments/${id}`
  }),
  create: (data: CreateTournamentParams) => ({
    method: Method.POST,
    path: '/admin/tournaments',
    body: { tournament: data }
  }),
  update: (id: string, data: UpdateTournamentData) => ({
    method: Method.PUT,
    path: `/admin/tournaments/${id}`,
    body: { tournament: data }
  }),
  delete: (id: string) => ({
    method: Method.DELETE,
    path: `/admin/tournaments/${id}`
  }),
  inscriptions: (filters: IUseInscriptions) => ({
    method: Method.GET,
    path: `/admin/tournaments/${filters.tournamentId}/inscriptions?${parameterize(filters)}`
  }),
  deleteInscription: (id: number, inscriptionId: string) => ({
    method: Method.DELETE,
    path: `/admin/tournaments/${id}/inscriptions/${inscriptionId}`
  }),
  addInscription: (id: string, data: Partial<InscriptionData>) => ({
    method: Method.POST,
    path: `/admin/tournaments/${id}/inscriptions`,
    body: { inscription: data },
  }),
  addInscriptionBatch: (id: string, data: FormData) => ({
    method: Method.POST,
    path: `/admin/tournaments/${id}/inscriptions/batch`,
    body: data,
    isForm: true
  }),
}

export const AdminTokensEndpoints = {
  all: ({ page, limit, name }: IUseTokens) => ({
    method: Method.GET,
    path: `/admin/tokens?page=${page}&limit=${limit}&name=${name ?? ''}`
  }),
  create: (data: CreateTokenData) => ({
    method: Method.POST,
    path: '/admin/tokens',
    body: data
  }),
  update: (id: string, data: UpdateTokenData) => ({
    method: Method.PUT,
    path: `/admin/tokens/${id}`,
    body: { token: data }
  }),
  delete: (id: string) => ({
    method: Method.DELETE,
    path: `/admin/tokens/${id}`
  }),
}

export const AdminAssignmentEndpoints = {
  all: ({ page, limit, userId, tokenId }: IUseAssignments) => ({
    method: Method.GET,
    path: `/admin/tokens/${tokenId}/assignments?page=${page}&limit=${limit}&userId=${userId ?? ''}`
  }),
  create: (tokenId: string, userId: number) => ({
    method: Method.POST,
    path: `/admin/tokens/${tokenId}/assignments`,
    body: {
      assignment: { userId },
    }
  }),
  delete: (tokenId: string, id: string) => ({
    method: Method.DELETE,
    path: `/admin/tokens/${tokenId}/assignments/${id}`
  }),
}

export const AdminScholarshipEndpoints = {
  all: (params: URLSearchParams) => ({
    method: Method.GET,
    path: `/admin/scholarships?${params.toString()}`
  }),
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/scholarships/${id}`
  })
}

export const AdminCredentialsEndpoints = {
  find: (id: string) => ({
    method: Method.GET,
    path: `/admin/credentials/${id}`
  }),
  all: ({ slug, status, query, page, limit }: IUseCredentials) => ({
    method: Method.GET,
    path: `/admin/credentials?${parameterize({ slug, status, query, page, limit })}`
  }),
  update: (id: string, data: Partial<GameCredentials>) => ({
    method: Method.PUT,
    path: `/admin/credentials/${id}`,
    body: { credential: data }
  }),
  create: (data: Partial<GameCredentials>) => ({
    method: Method.POST,
    path: `/admin/credentials`,
    body: { credential: data }
  }),
}
