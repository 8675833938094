import { FC, useState } from 'react'
import { useNavigate, useParams, generatePath } from 'react-router-dom'
import { ROUTES } from '../../../consts/routes'
import { useForm } from 'react-hook-form'
import { Center, Heading, VStack, Button, Box, Spinner, useToast, FormControl, FormLabel, Input, FormErrorMessage, Text } from '@chakra-ui/react'
import OlaggForm from '../../../components/Forms/OlaggForm'
import OlaggFormSelect from '../../../components/Forms/OlaggFormSelect'
import OlaggFormInput from '../../../components/Forms/OlaggFormInput'
import { AdminTournamentsEndpoints, AdminUserEndpoints, BackendError, fetcher, useAdminUsers } from '@olagg/api-hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import { formInscriptionSchema, FormInscriptionData, FormInscriptionBatchData, formInscriptionBatchSchema } from '@olagg/validation-schemas/tournament.schemas'
import { User } from '@olagg/db-types'

const TournamentAddInscription: FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const tournamentId = useParams()?.id;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const useFormMethods = useForm<FormInscriptionData>({
    resolver: yupResolver(formInscriptionSchema),
    mode: 'onChange',
  });

  const { handleSubmit: handleSubmitFile, register: registerFile, formState: { errors: errorsFile } } = useForm<FormInscriptionBatchData>({
    resolver: yupResolver(formInscriptionBatchSchema),
    mode: 'onChange',
  });

  const asyncUserOptions = async (input: string) => {
    return new Promise(async (resolve) => {
      const { users } = await fetcher<{ users: User[], meta: { count: number } }>(AdminUserEndpoints.all({query: input, page: 1, limit: 100}))

      const userOptions = users?.map(user => ({
        value: user.id, label: `${user.id} - ${user.name} ${user.discordId ? `(${user.discordId})` : ''}`
      }));

      resolve(userOptions);
    });  
  }

  const onSubmit = (dataForm: FormInscriptionData) => {
    setIsLoading(true);

    const data = {
      userId: dataForm.userId.value,
      position: dataForm.position,
      winner: dataForm.winner,
      score: dataForm.score,
      prize: {
        other: dataForm.prizeOther,
        usdc: dataForm.prizeUsdc
      },
    }

    fetcher(
      AdminTournamentsEndpoints.addInscription(tournamentId!, data)
    ).then(() => {
      toast({
        title: 'Inscripciones',
        colorScheme: 'olaggPink',
        status: 'success',
        description: `Inscripción realizada con éxito`,
        position: 'bottom-right'
      });
      navigate(generatePath(ROUTES.TOURNAMENTS.inscriptions, { id: tournamentId }))
    }).catch((error: BackendError) => {
      toast({
        title: 'Error',
        colorScheme: 'olaggYellow',
        status: 'error',
        description: error.message || "Error al crear la inscripción",
        position: 'bottom-right'
      });
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const onSubmitFile = (dataForm: FormInscriptionBatchData) => {
    setIsLoading(true);
    const body = new FormData();
    body.append('file', dataForm.file[0]);

    fetcher(AdminTournamentsEndpoints.addInscriptionBatch(tournamentId!, body))
      .then(() => {
        toast({
          title: 'Inscripciones',
          colorScheme: 'olaggPink',
          status: 'success',
          description: `Carga masiva realizada con éxito`,
          position: 'bottom-right'
        });
        navigate(generatePath(ROUTES.TOURNAMENTS.inscriptions, { id: tournamentId }))
      })
      .catch((error: any) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Añadir Participantes
        </Heading>

        {isLoading ? (
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
        ) : (
          <>
            <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
              <Text fontSize='xl' fontWeight='600' mb='2' mt='10'>Carga Masiva</Text>
              <form onSubmit={handleSubmitFile(onSubmitFile)} encType="multipart/form-data" style={{ width: '100%' }}>
                <FormControl
                  id="file"
                  isInvalid={errorsFile.file ? true : false}
                >
                  <FormLabel>
                    Seleccionar archivo .xlsx
                  </FormLabel>
                  <Input
                    type='file'
                    {...registerFile('file')}
                    id='file'
                  />
                  <FormErrorMessage>
                    {errorsFile.file && errorsFile.file.message?.toString()}
                  </FormErrorMessage>
                </FormControl>

                <Box textAlign='left' w='100%'>
                  <a href='/downloads/participantes.xlsx'>
                    <Button
                      variant="filledGradient"
                      flex="1"
                      size={'sm'} py='5px' mt='5px'
                    >
                      Descargar archivo de ejemplo
                    </Button>
                  </a>
                </Box>

                <Button
                  variant='filledGradient'
                  type='submit'
                  mt='10'
                  w='full'
                >
                  Cargar
                </Button>
              </form>
            </VStack>

            <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
              <Text fontSize='xl' fontWeight='600' mb='2' mt='10'>Carga Manual</Text>
              <OlaggForm useFormMethods={useFormMethods} onSubmit={onSubmit} style={{ width: '100%' }}>

                <OlaggFormSelect
                  label='Participante'
                  controlName='userId'
                  isSearchable={true}
                  staticOptions={[{}]}
                  asyncOptions={asyncUserOptions}
                  required={true}
                  placeholder='Buscar por nombre, user id o discord id'
                />

                <OlaggFormInput
                  label='Posición'
                  controlName='position'
                />

                <OlaggFormInput
                  label='XP a otorgar'
                  description='Opcional - Si no se especifica se tomará el valor definido en el registro del torneo'
                  controlName='score'
                />

                <OlaggFormInput
                  label='Premio en USDC'
                  description='Opcional - Valor en USDC a otorgar al jugador'
                  controlName='prizeUsdc'
                />

                <OlaggFormInput
                  label='Otros premios'
                  description='Opcional - Cualquier otro tipo de premio, ej.: una remera'
                  controlName='prizeOther'
                />

                <OlaggFormInput
                  label='Ganador'
                  controlName='winner'
                  inputType='switch'
                />

                <Button
                  variant={'filledGradient'}
                  isLoading={isLoading}
                  disabled={isLoading}
                  type="submit"
                  w={'full'}
                  mt='30px'
                  style={{
                    marginTop: '30px',
                    marginBottom: '50px'
                  }}
                >
                  Añadir
                </Button>

              </OlaggForm>
            </VStack>
          </>

        )}
      </VStack>
    </Center>
  )
}

export default TournamentAddInscription;
