import { TournamentData } from "@olagg/db-types"
import { AdminTournamentsEndpoints } from "../endpoints/adminEndpoints"
import { useQ } from "./useQ"

export const useAdminTournament = (id: string) => {
  const response = useQ<{ tournament: TournamentData }>(AdminTournamentsEndpoints.find(id), {
    revalidateOnFocus: true
  })

  return {
    ...response,
    tournament: response.data?.tournament ?? null
  }
}
