import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Wrap
} from '@chakra-ui/react'
import {
  fetcher,
  GameEndpoints,
  useAdminGames
} from '@olagg/api-hooks'
import { IGetAllProps } from '@olagg/api-hooks/interfaces/IUseGames'
import { ApplicationStatus } from '@olagg/db-types'
import { GameCardAsAdmin, Joystick } from '@olagg/ui-kit'
import Pagination from '@olagg/ui-kit/Pagination'
import { FC, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import GamesFilter from '../components/Filters/GamesFilter'
import { ROUTES } from '../consts/routes'

const Games: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<IGetAllProps>({
    title: searchParams.get('title') || undefined,
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 10)
  })

  const { title, page, limit } = filter

  const { data, error, isLoading, count } = useAdminGames({
    title,
    page: Number(page || 1),
    limit: limit
  })
  const { mutate } = useSWRConfig()

  const navigate = useNavigate()

  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  const { GAMES, SCHOLARSHIP_APPLICATIONS } = ROUTES
  return (
    <Center color={'white'} flexDirection={'column'}>
      <Heading textAlign={'center'} mt={5} mb={2}>
        Juegos
      </Heading>

      <Center display="block" px={8}>
        <Flex>
          <GamesFilter onFilter={onFilter} filter={filter} />
          <Button
            variant="outlinedGradient"
            leftIcon={<Joystick />}
            rightIcon={
              <AiOutlinePlus style={{ marginBottom: '4px' }} />
            }
            onClick={() => navigate(GAMES.create)}
          >
            Crear nuevo juego
          </Button>

        </Flex>
        <Box overflow="auto" mt={4} w="75vw" h="65vh">
          {isLoading && <Box>Loading...</Box>}
          {error && <Box>Error: {error.message}</Box>}
          <Wrap justify={'center'} spacing={4} pb={6}>
            {data?.games?.map(game => (
              <Box w={'96'} key={game.slug}>
                <GameCardAsAdmin
                  {...game}
                  toggleGameStatus={async slug => {
                    await fetcher(
                      GameEndpoints.update(slug, {
                        status:
                          game.status === 'Activo'
                            ? 'Próximamente'
                            : 'Activo'
                      })
                    )
                    await mutate(
                      GameEndpoints.all.path,
                      data.games.map(g =>
                        g.slug === slug
                          ? {
                            ...g,
                            status:
                              game.status === 'Activo'
                                ? 'Próximamente'
                                : 'Activo'
                          }
                          : g
                      ),
                      {
                        revalidate: false
                      }
                    )
                  }}
                  toggleGameVisibility={async slug => {
                    await fetcher(
                      GameEndpoints.update(slug, {
                        visible: !game.visible
                      })
                    )
                    await mutate(
                      GameEndpoints.all.path,
                      data.games.map(g =>
                        g.slug === slug
                          ? {
                            ...g,
                            visible: !game.visible
                          }
                          : g
                      ),
                      {
                        revalidate: false
                      }
                    )
                  }}
                  viewRequests={() =>
                    navigate(
                      `${SCHOLARSHIP_APPLICATIONS}?status=${ApplicationStatus.PENDING}&slug=${game.slug}`
                    )
                  }
                  viewApproved={() =>
                    navigate(
                      `${SCHOLARSHIP_APPLICATIONS}?status=${ApplicationStatus.APPROVED}&slug=${game.slug}`
                    )
                  }
                  editGame={() =>
                    navigate(`${GAMES.all}/${game.slug}`)
                  }
                  gameContent={() =>
                    navigate(`${GAMES.content}?slug=${game.slug}`)
                  }
                />
              </Box>
            ))}
          </Wrap>
        </Box>
        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: page + 1
            })
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            })
          }
          hideLimitInput
        />
      </Center>
    </Center>
  )
}

export default Games
