import { Box } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { ISidebarRoute } from '../Navigation/routes'
import SidebarContent from './SidebarContent'

interface IProps {
  routes: ISidebarRoute[]
  logo: ReactElement
  style?: {
    bg?: string
    w?: string
    maxW?: string
    h?: string
    ps?: string
    pe?: string
    borderRadius?: string
    mt?: number
    mb?: number
    transition?: string
  }
}

function Sidebar({ routes, logo, style }: IProps) {
  let bg = 'white'
  let w = '250px'
  let borderRadius = '16px'
  let maxW = '260px'
  let h = 'calc(100vh - 32px)'
  let ps = '20px'
  let pe = '20px'
  let mt = 5
  let mb = 5
  let transition = '0.2s linear'

  if (style && Object.keys(style).length) {
    bg = style.bg || bg
    w = style.w || w
    borderRadius = style.borderRadius || borderRadius
    maxW = style.maxW || maxW
    h = style.h || h
    ps = style.ps || ps
    pe = style.pe || pe
    mt = style.mt || mt
    mb = style.mb || mb
    transition = style.transition || transition
  }

  return (
    <Box
      sx={{
        '@media screen and (max-width: 70rem)': {
          transform: 'translateX(-180px)',
          opacity: 0.5,
          transition: 'all ease 0.3s'
        }
      }}
      zIndex='10'
      position="fixed"
      _hover={{opacity: 1, transform: 'translateX(0px)'}}
    >
      <Box
        bg={bg}
        transition={transition}
        w={w}
        maxW={maxW}
        h={h}
        ps={ps}
        pe={pe}
        borderRadius={borderRadius}
        mt={mt}
        mb={mb}
      >
        <SidebarContent routes={routes} logo={logo} />
      </Box>
    </Box>
  )
}

export default Sidebar
