export const TicTacToe = ({
  height = '21px',
  width = '20px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6584 10.35C15.576 10.3519 15.4942 10.3365 15.4182 10.305C15.3421 10.2734 15.2735 10.2263 15.2167 10.1667L10.875 5.83336C10.7646 5.71489 10.7045 5.55818 10.7074 5.39626C10.7102 5.23434 10.7758 5.07986 10.8903 4.96535C11.0048 4.85084 11.1593 4.78524 11.3212 4.78238C11.4832 4.77953 11.6399 4.83963 11.7584 4.95003L16.1 9.28336C16.2171 9.40055 16.2828 9.5594 16.2828 9.72503C16.2828 9.89066 16.2171 10.0495 16.1 10.1667C16.0423 10.2251 15.9735 10.2714 15.8977 10.3029C15.8218 10.3344 15.7405 10.3504 15.6584 10.35Z"
        fill={color}
      />
      <path
        d="M11.3583 10.3084C11.2762 10.3087 11.1949 10.2927 11.119 10.2612C11.0432 10.2298 10.9744 10.1834 10.9167 10.125C10.7996 10.0078 10.7339 9.84898 10.7339 9.68335C10.7339 9.51773 10.7996 9.35887 10.9167 9.24169L15.2583 4.90835C15.3158 4.84939 15.3845 4.80253 15.4603 4.77053C15.5362 4.73853 15.6177 4.72205 15.7 4.72205C15.7823 4.72205 15.8638 4.73853 15.9397 4.77053C16.0155 4.80253 16.0842 4.84939 16.1417 4.90835C16.2587 5.02554 16.3245 5.1844 16.3245 5.35002C16.3245 5.51565 16.2587 5.6745 16.1417 5.79169L11.8 10.125C11.7426 10.1838 11.6739 10.2304 11.5979 10.2619C11.522 10.2934 11.4405 10.3092 11.3583 10.3084Z"
        fill={color}
      />
      <path
        d="M6.49172 10.3333C5.93463 10.3333 5.39006 10.1682 4.92686 9.85865C4.46366 9.54915 4.10264 9.10925 3.88946 8.59457C3.67627 8.07989 3.62049 7.51356 3.72917 6.96718C3.83785 6.4208 4.10611 5.91891 4.50003 5.525C4.89395 5.13108 5.39583 4.86282 5.94221 4.75413C6.48859 4.64545 7.05493 4.70123 7.56961 4.91442C8.08429 5.12761 8.52419 5.48863 8.83369 5.95182C9.14319 6.41502 9.30838 6.9596 9.30838 7.51668C9.30619 8.26303 9.00872 8.97818 8.48097 9.50594C7.95322 10.0337 7.23807 10.3311 6.49172 10.3333ZM6.49172 5.95001C6.18186 5.95001 5.87896 6.0419 5.62132 6.21404C5.36369 6.38619 5.16288 6.63087 5.04431 6.91714C4.92573 7.20341 4.8947 7.51842 4.95515 7.82232C5.0156 8.12623 5.16481 8.40538 5.38392 8.62448C5.60302 8.84358 5.88217 8.99279 6.18608 9.05324C6.48998 9.11369 6.80498 9.08267 7.09125 8.96409C7.37753 8.84551 7.6222 8.64471 7.79435 8.38707C7.9665 8.12944 8.05838 7.82654 8.05838 7.51668C8.05838 7.10117 7.89332 6.70269 7.59952 6.40888C7.30571 6.11507 6.90722 5.95001 6.49172 5.95001Z"
        fill={color}
      />
      <path
        d="M6.49172 17.1667C5.93463 17.1667 5.39006 17.0015 4.92686 16.692C4.46366 16.3825 4.10264 15.9426 3.88946 15.4279C3.67627 14.9132 3.62049 14.3469 3.72917 13.8005C3.83785 13.2541 4.10611 12.7522 4.50003 12.3583C4.89395 11.9644 5.39583 11.6961 5.94221 11.5874C6.48859 11.4788 7.05493 11.5345 7.56961 11.7477C8.08429 11.9609 8.52419 12.3219 8.83369 12.7851C9.14319 13.2483 9.30838 13.7929 9.30838 14.35C9.30619 15.0963 9.00872 15.8115 8.48097 16.3392C7.95322 16.867 7.23807 17.1645 6.49172 17.1667ZM6.49172 12.7833C6.18186 12.7833 5.87896 12.8752 5.62132 13.0474C5.36369 13.2195 5.16288 13.4642 5.04431 13.7505C4.92573 14.0367 4.8947 14.3517 4.95515 14.6556C5.0156 14.9595 5.16481 15.2387 5.38392 15.4578C5.60302 15.6769 5.88217 15.8261 6.18608 15.8866C6.48998 15.947 6.80498 15.916 7.09125 15.7974C7.37753 15.6788 7.6222 15.478 7.79435 15.2204C7.9665 14.9628 8.05838 14.6599 8.05838 14.35C8.05838 13.9345 7.89332 13.536 7.59952 13.2422C7.30571 12.9484 6.90722 12.7833 6.49172 12.7833Z"
        fill={color}
      />
      <path
        d="M13.5083 17.1667C12.9512 17.1667 12.4067 17.0015 11.9435 16.692C11.4803 16.3825 11.1192 15.9426 10.9061 15.4279C10.6929 14.9132 10.6371 14.3469 10.7458 13.8005C10.8545 13.2541 11.1227 12.7522 11.5166 12.3583C11.9106 11.9644 12.4124 11.6961 12.9588 11.5874C13.5052 11.4788 14.0715 11.5345 14.5862 11.7477C15.1009 11.9609 15.5408 12.3219 15.8503 12.7851C16.1598 13.2483 16.325 13.7929 16.325 14.35C16.3228 15.0963 16.0253 15.8115 15.4976 16.3392C14.9698 16.867 14.2547 17.1645 13.5083 17.1667ZM13.5083 12.7833C13.1985 12.7833 12.8956 12.8752 12.6379 13.0474C12.3803 13.2195 12.1795 13.4642 12.0609 13.7505C11.9423 14.0367 11.9113 14.3517 11.9718 14.6556C12.0322 14.9595 12.1814 15.2387 12.4005 15.4578C12.6196 15.6769 12.8988 15.8261 13.2027 15.8866C13.5066 15.947 13.8216 15.916 14.1079 15.7974C14.3941 15.6788 14.6388 15.478 14.811 15.2204C14.9831 14.9628 15.075 14.6599 15.075 14.35C15.075 13.9345 14.9099 13.536 14.6161 13.2422C14.3223 12.9484 13.9238 12.7833 13.5083 12.7833Z"
        fill={color}
      />
    </svg>
  )
}

export default TicTacToe
