import { User } from '@olagg/db-types'
import { AdminUserEndpoints } from '../endpoints/adminEndpoints'
import { IUseUsers } from '../interfaces/IUseUsers'
import { useQ } from './useQ'

export const useAdminUsers = ({
  country,
  withWallets,
  query,
  page,
  limit
}: IUseUsers) => {
  const response = useQ<{ users: User[], meta: { count: number } }>(
    AdminUserEndpoints.all({
      country,
      query,
      page,
      limit,
      withWallets
    }),
    {
      revalidateOnFocus: true
    }
  )
  return {
    ...response,
    users: response.data?.users ?? [],
    count: response.data?.meta?.count ?? 0
  }
}
