import { TypeOf, z } from 'zod'

export const createScholarshipSchema = z.object({
  body: z.object({
    gameId: z
      .string({
        required_error: 'La ID del juego es requerida'
      })
      .min(
        3,
        'El slug del juego debe ser de mínimo 3 caracteres'
      )
      .max(
        64,
        'El slug del juego debe ser de máximo 64 caracteres'
      )
      .regex(
        /^[\w-]+$/,
        'El slug del juego contiene caracteres inválidos'
      ) // axie-infinity
  })
})

export const cancelScholarshipSchema = z.object({
  params: z.object({
    slug: z.string({
      required_error: 'La ID del juego es requerida'
    })
  })
})

export const actionScholarshipSchema = z.object({
  params: z.object({
    id: z
      .preprocess(
        a => parseInt(z.string().parse(a), 10),
        z.number().min(0)
      )
      .transform(String),
    action: z
      .string()
      .refine(
        v => v === 'approve' || v === 'reject' || v === 'suspend'
      )
  })
})

export const createScholarshipCredentialsSchema = z.object({
  body: z.object({
    gameId: z.string().optional(),
    username: z.string().optional(),
    email: z.string().optional(),
    password: z.string().optional(),
    walletAddress: z.string().optional(),
    seedPhrase: z.string().optional(),
    blockchain: z.string().optional().default('ethereum'),
    personal: z.boolean().optional().default(false),
    nftIdentifier: z.string().optional(),
    userNotes: z.string().optional(),
    adminNotes: z.string().optional(),
    userRequired: z.array(z.string()).optional(),
    blank: z.array(z.string()).optional()
  })
})

export type CreateScholarshipInput = TypeOf<
  typeof createScholarshipSchema
>['body']

export type CreateScholarshipCredentialsInput = TypeOf<
  typeof createScholarshipCredentialsSchema
>['body']

export type CancelScholarshipInput = TypeOf<
  typeof cancelScholarshipSchema
>['params']

export type ActionScholarshipSchemaInput = TypeOf<
  typeof actionScholarshipSchema
>['params']
