import { Button, Center, FormControl, FormLabel, Heading, Input, useToast, VStack } from "@chakra-ui/react";
import { fetcher, AdminSegmentEndpoints } from "@olagg/api-hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../consts/routes";

const CreateSegment = () => {
  const [segmentName, setSegmentName] = useState('');
  const [segmentDescription, setSegmentDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    await fetcher(AdminSegmentEndpoints.create({
      name: segmentName,
      description: segmentDescription
    }))
    .then(() => {
      toast({
        title: 'Segmento creado',
        colorScheme: 'olaggPink',
        status: 'success',
        description: 'El segmento se ha creado correctamente, se enviará a los usuarios en cuanto se publique.',
        position: 'bottom-right'
      })
      navigate(ROUTES.SEGMENTS.all)
    })
    .catch(e => {
      toast({
        title: 'Error al crear el segmento',
        colorScheme: 'olaggPink',
        status: 'error',
        description: 'Ha ocurrido un error al crear el segmento, por favor intente de nuevo.',
        position: 'bottom-right'
      })
    })
    .finally(() => {
      setLoading(false);
    })
  }

  return (
    <Center w={'full'} p={4} >
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Crear segmento
        </Heading>
        <form onSubmit={handleSubmit}>
          <FormControl id="name" isRequired>
            <FormLabel>Nombre</FormLabel>
            <Input type="text" value={segmentName} onChange={(e) => setSegmentName(e.target.value)} />
          </FormControl>
          <FormControl id="description" isRequired>
            <FormLabel>Descripción</FormLabel>
            <Input type="text" value={segmentDescription} onChange={(e) => setSegmentDescription(e.target.value)} />
          </FormControl>
          <Button
            type="submit"
            variant='filledGradient'
            isLoading={loading}
            loadingText="Creando segmento"
            my={4}
          >
            Crear segmento
          </Button>
        </form>
      </VStack>
    </Center>
  )
}

export default CreateSegment;
