import { FC } from 'react'
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import { FaDownload } from 'react-icons/fa'
import UploadFile from './UploadFile'

interface IProps {
  isOpen: boolean
  onClose: () => void
}
const BulkCreationScholarship: FC<IProps> = ({
  isOpen,
  onClose
}) => (
  <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
    <ModalOverlay backdropFilter='blur(10px)' />
    <ModalContent>
      <ModalHeader />
      <ModalCloseButton />
      <ModalBody>
        <Box>
          <Heading fontSize="24px" color="olaggPurple.100">
            Creación masiva de becas
          </Heading>
          <a
            href="/OLA GG Massive Scholarships Template.csv"
            download
          >
            <Button
              variant="link"
              w="full"
              justifyContent="space-between"
              mt={5}
              pr={2}
            >
              <Text fontFamily="text" mt={1}>
                Descarga, completa y sube este archivo
              </Text>
              <FaDownload />
            </Button>
          </a>
        </Box>
      </ModalBody>
      <ModalFooter>
        <UploadFile onClose={onClose} />
      </ModalFooter>
    </ModalContent>
  </Modal>
)

export default BulkCreationScholarship
