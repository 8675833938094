import { Center, Spinner, Text } from '@chakra-ui/react'
import { useAdminUser } from '@olagg/api-hooks'
import { useParams } from 'react-router-dom'
import UserForm from '../components/Forms/UserForm'

interface IProps {
  mode: 'edit' | 'view'
}

// TODO: Use the param to get the mode. By default will be 'view'
const ManageUser = () => {
  const params = useParams()

  const {
    data,
    error,
    isLoading
  } = useAdminUser(params?.id || '')

  if (!data || isLoading) return <Spinner />

  const { user } = data
  
  if (user)
    return (
      <UserForm
        mode={
          ['view', 'edit'].includes(params.mode as string)
            ? (params.mode as 'view' | 'edit')
            : 'view'
        }
        user={user}
      />
    )

  return (
    <Center h="80vh" color="white">
      {isLoading && <Text>Loading...</Text>}
      {!isLoading && (error || !user) && (
        <Text>User not found</Text>
      )}
    </Center>
  )
}

export default ManageUser
