import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import MultiSelectModal from '@olagg/ui-kit/MultiSelectModal'
import Edit from '@olagg/ui-kit/svg/Edit'
import { useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'

const BLOCKCHAINS = [
  'RONIN',
  'ETHEREUM',
  'SOLANA',
  'POLYGON',
  'BINANCE',
  'AVALANCHE',
  'XPLA'
]

export default function ChooseBlockchains({
  blockchains,
  setValue
}: {
  blockchains?: string[]
  //TODO: properly type below
  setValue: UseFormSetValue<any>
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [selectedBlockchains, setSelectedBlockchains] = useState<
    string[]
  >(blockchains || [])

  const handleBlockchains = (blockchains: string[]) => {
    setValue('blockchains', blockchains)
    setSelectedBlockchains(blockchains)
    onClose()
  }

  return (
    <FormControl pt={4}>
      <FormLabel htmlFor="categories">Blockchains</FormLabel>
      <Flex
        justify="space-between"
        align="end"
        cursor="pointer"
        onClick={onOpen}
      >
        {!selectedBlockchains?.length ? (
          <Text
            color="gray.500"
            fontWeight="400"
            fontSize="18px"
          >
            Selecciona las blockchains
          </Text>
        ) : (
          <Box
            display="inline-flex"
            flexWrap="wrap"
            w="100%"
            gap="1"
          >
            {selectedBlockchains?.map(blockchain => (
              <Flex
                key={blockchain}
                bg="olaggPink.100"
                p={1}
                borderRadius="4px"
              >
                <Text fontSize="15px" fontWeight="600">
                  {blockchain}
                </Text>
              </Flex>
            ))}
          </Box>
        )}
        <Edit color="#CBD5E0" />
      </Flex>

      <MultiSelectModal
        title="Selecciona las blockchains"
        defaultValues={BLOCKCHAINS}
        loadedValues={selectedBlockchains}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleBlockchains}
      />
    </FormControl>
  )
}
