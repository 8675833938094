import {
  Box,
  Button,
  Center,
  Checkbox,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import { fetcher, Method } from '@olagg/api-hooks'
import { useState } from 'react'

interface IProps {
  id?: string
  isOpen: boolean
  onClose: () => void
  onSuccess: (s: string, b?: string) => void
  onError: (s: string) => void
}

const DisableCredentialsModal = ({
  id,
  isOpen,
  onClose,
  onSuccess,
  onError
}: IProps) => {
  const [confirmAction, setConfirmAction] = useState(false)

  const disable = async () => {
    if (!confirmAction) return
    if (!id) return

    const path = `/admin/credentials/${id}`
    fetcher({
      method: Method.PUT,
      path,
      body: {
        active: false
      }
    })
      .then(() => {
        onSuccess(
          'Beca desactivada correctamente',
          'La beca fue desactivada con éxito'
        )
      })
      .catch(e => onError(e))

    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={5}>
        <ModalCloseButton />
        <ModalBody>
          <Box mt={4} textAlign={'center'}>
            <Heading fontSize={'24px'} color={'olaggPurple.100'}>
              ¿Estás seguro quieres desactivar esta beca?
            </Heading>
          </Box>
          <Center mt={5}>
            <Text color="red" fontWeight="bold">
              Una vez la desactives, NO podrás volver a
              activarla, tampoco podrás editar sus datos.
            </Text>
          </Center>
          <Box pt={5} px={6}>
            <Checkbox
              checked={confirmAction}
              onChange={() => {
                setConfirmAction(prev => !prev)
              }}
            >
              Si, estoy seguro
            </Checkbox>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            ml={2}
            w="full"
            variant="filledGradient"
            onClick={disable}
          >
            Aceptar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DisableCredentialsModal
