import * as React from 'react'

export const ColouredLogoWithText = ({
  height = '24px',
  width = '24px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="140"
    height="173"
    viewBox="0 0 140 173"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M69.9585 130.76L57.0993 108.666L19.6415 87.2306V44.3386L6.78223 22.2446H32.5007L69.9585 0.808899L107.396 22.2446H133.114L120.255 44.3386V87.2306L82.8178 108.666L69.9585 130.76ZM21.923 85.9346L58.7585 107.021L69.9585 126.235L81.1585 107.021L81.4281 106.877L117.994 85.9346V43.742L129.194 24.5281H106.794L69.9585 3.4215L33.123 24.5075H10.7437L21.9437 43.7215V85.9346H21.923Z"
      fill="white"
    />
    <mask
      id="mask0_1089_9595"
      style={{
        maskType: 'alpha'
      }}
      maskUnits="userSpaceOnUse"
      x="8"
      y="1"
      width="124"
      height="128"
    >
      <path
        d="M114.24 23.1703H25.6563H8.75262L17.2148 37.694L61.4963 113.768L69.9585 128.292L78.4208 113.768L122.702 37.694L131.164 23.1703H114.24ZM69.9585 108.934L25.6563 32.8596H114.24L69.9585 108.934Z"
        fill="white"
      />
      <path
        d="M69.9585 1.8992L20.7822 30.0619V86.3666L69.9585 114.529L119.135 86.3666V30.0619L69.9585 1.8992ZM109.366 80.7917L74.8326 100.561L69.9585 108.954L65.0844 100.561L30.5511 80.7917V41.2323L25.677 32.839H35.4459L69.9793 13.0696L104.513 32.839H114.281L109.407 41.2323V80.7917H109.366Z"
        fill="white"
      />
      <path
        d="M69.9586 81.3677L61.0193 66.0418H49.446L69.9586 101.281L86.8208 72.275H75.2475L69.9586 81.3677Z"
        fill="white"
      />
      <path
        d="M80.4949 36.4597L64.1926 64.4577L69.9585 74.3733L78.7526 59.2531H82.8178L77.0726 69.1481H88.646L107.686 36.4597H80.4949ZM83.2326 51.5799L86.4682 46.0049H90.5334L87.2978 51.5799H83.2326Z"
        fill="white"
      />
      <path
        d="M76.4089 36.4597H32.2311L47.6415 62.9149H61.04L76.4089 36.4597ZM55.046 53.2873H53.5941L49.363 46.0049H59.2771L55.046 53.2873Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_1089_9595)">
      <path
        d="M137.594 -3.69629H2.40594V130.39H137.594V-3.69629Z"
        fill="url(#paint0_linear_1089_9595)"
      />
    </g>
    <path
      d="M17.2978 145.613C19.2059 145.613 20.5126 146.909 20.5126 148.802V169.62C20.5126 171.513 19.2059 172.809 17.2978 172.809H3.21481C1.30667 172.809 0 171.513 0 169.62V148.781C0 146.889 1.30667 145.593 3.21481 145.593H17.2978V145.613ZM13.44 151.929C13.44 151.682 13.2326 151.476 12.9837 151.476H7.42518C7.25926 151.476 7.05185 151.682 7.05185 151.929V166.555C7.05185 166.761 7.25926 166.925 7.42518 166.925H12.9837C13.2326 166.925 13.44 166.761 13.44 166.555V151.929Z"
      fill="white"
    />
    <path
      d="M28.4563 145.613H35.4874V166.926H44.2193V172.809H28.4563V145.613Z"
      fill="white"
    />
    <path
      d="M70.7259 172.788H63.6741V166.02H57.4519V172.788H50.3793V148.781C50.3793 146.889 51.6859 145.593 53.5941 145.593H67.5319C69.44 145.593 70.7467 146.889 70.7467 148.781V172.788H70.7259ZM63.6741 160.137V151.97C63.6741 151.497 63.2593 151.167 62.6993 151.167H58.323C57.8459 151.167 57.4726 151.538 57.4726 151.97V160.137H63.6741Z"
      fill="white"
    />
    <path
      d="M98.5807 156.125H111.004V161.885H108.723V169.703C108.723 171.472 107.333 172.809 105.591 172.809H91.8815C90.1392 172.809 88.7081 171.472 88.7081 169.703V148.658C88.7081 147.012 90.1392 145.593 91.8815 145.593H108.847V151.435H96.1333C95.9259 151.435 95.7185 151.641 95.7185 151.887V166.473C95.7185 166.72 95.9259 166.884 96.1333 166.884H101.837C102.127 166.884 102.293 166.72 102.293 166.473V161.885H98.6015V156.125H98.5807Z"
      fill="white"
    />
    <path
      d="M127.576 156.125H140V161.885H137.719V169.703C137.719 171.472 136.329 172.809 134.587 172.809H120.856C119.114 172.809 117.683 171.472 117.683 169.703V148.658C117.683 147.012 119.114 145.593 120.856 145.593H137.822V151.435H125.108C124.901 151.435 124.693 151.641 124.693 151.887V166.473C124.693 166.72 124.901 166.884 125.108 166.884H130.791C131.081 166.884 131.247 166.72 131.247 166.473V161.885H127.556V156.125H127.576Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1089_9595"
        x1="121.939"
        y1="7.5108"
        x2="28.7062"
        y2="109.398"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0982117" stopColor="#FFAD4B" />
        <stop offset="0.1522" stopColor="#FFA355" />
        <stop offset="0.2455" stopColor="#FF8771" />
        <stop offset="0.3668" stopColor="#FF599E" />
        <stop offset="0.51" stopColor="#FF1BDC" />
        <stop offset="0.5668" stopColor="#FF00F6" />
        <stop offset="0.6236" stopColor="#FB00F6" />
        <stop offset="0.6817" stopColor="#EF00F6" />
        <stop offset="0.7405" stopColor="#DA00F6" />
        <stop offset="0.7998" stopColor="#BD00F7" />
        <stop offset="0.8595" stopColor="#9800F7" />
        <stop offset="0.9195" stopColor="#6B01F8" />
        <stop offset="0.9786" stopColor="#3601F9" />
        <stop offset="1" stopColor="#2101F9" />
      </linearGradient>
    </defs>
  </svg>
)
export default ColouredLogoWithText
