export const ROUTES = {
  LOGIN: '/login',
  SCHOLARSHIP_APPLICATIONS: '/solicitudes',
  GAMES: {
    all: '/juegos',
    create: '/juegos/crear',
    content: '/juegos/contenido',
    content_update: '/juegos/contenido/editar',
    content_create: '/juegos/contenido/crear',
    update: '/juegos/:slug',
  },
  CREDENTIALS: {
    all: '/becas',
    create: '/becas/crear',
    update: '/becas/:id'
  },
  USERS: {
    all: '/usuarios',
    update: '/usuarios/:id'
  },
  OWNERS: {
    all: '/owners',
    create: '/owners/crear',
    update: '/owners/:id'
  },
  QUESTS: {
    all: '/quest',
    create: '/quest/crear',
    batch_complete: '/quest/batch_complete/:id',
    update: '/quest/:id',
  },
  USER_CONTENT: {
    all: '/user-content',
    create: '/user-content/crear',
    update: '/user-content/:id'
  },
  NOTIFICATIONS: {
    all: '/notifications',
    create: '/notifications/create',
  },
  SEGMENTS: {
    all: '/segments',
    create: '/segments/create',
    assignDevice: '/segments/assign_device',
  },
  DEVICES: {
    all: '/devices',
  },
  MARKETPLACE: {
    all: '/tienda',
    create: '/tienda/crear',
    update: '/tienda/:id'
  },
  TOURNAMENTS: {
    all: '/tournaments',
    create: '/tournaments/crear',
    update: '/tournaments/:id',
    inscriptions: '/tournaments/:id/inscriptions',
    addInscription: '/tournaments/:id/inscriptions/add',
  }, TOKENS: {
    all: '/tokens',
    create: '/tokens/crear',
    update: '/tokens/:id',
    whitelist: '/tokens/:tokenId/assignments',
    addWhitelistedUser: '/tokens/:id/whitelist/add',
  },
}
