import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { VStack, Button, Box, Text, Flex, Switch } from '@chakra-ui/react'
import OlaggForm from '../../components/Forms/OlaggForm'
import OlaggFormSelect from '../../components/Forms/OlaggFormSelect'
import OlaggFormInput from '../../components/Forms/OlaggFormInput'
import OlaggMarkdown from '@olagg/ui-kit/OlaggMarkdown'
import { AdminGameEndpoints, fetcher } from '@olagg/api-hooks'
import { Game, TournamentData } from '@olagg/db-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { formTournamentSchema, FormTournamentData } from '@olagg/validation-schemas/tournament.schemas'
import { convertUTCToLocalISOString } from '../../utils'

interface ITournamentFormProps {
  tournament?: TournamentData;
  onSubmit: (dataForm: FormTournamentData) => void;
};

const toLocalTimezone = (input: any) => {
  return convertUTCToLocalISOString(input).slice(0, 16) as unknown as Date;
}

const TournamentForm: FC<ITournamentFormProps> = ({ tournament, onSubmit }) => {
  const { state } = useLocation();
  const [instructions, setInstructions] = useState<string>('');
  const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const [matchInstructions, setMatchInstructions] = useState<string>('');
  const [editMatch, setEditMatch] = useState<boolean>(false);
  const [autoMatchStart, setAutoMatchStart] = useState<boolean>(false);
  const [autoMatchStartValue, setAutoMatchStartValue] = useState<number>(5);

  const useFormMethods = useForm<FormTournamentData>({
    resolver: yupResolver(formTournamentSchema),
    mode: 'onChange',
  });

  useEffect(
    () => {
      if (tournament) {
        const { inscriptionBases, gameId, ...rest } = tournament;
        const dataForm: FormTournamentData = {
          ...rest,
          discordNotification: tournament.discordNotification || false,
          matchStartDate: tournament.matchStartDate || null,
          inscriptionBasesMin: inscriptionBases.min,
          inscriptionBasesMax: inscriptionBases.max,
          inscriptionBasesOpenAt: inscriptionBases.openAt,
          inscriptionBasesCloseAt: inscriptionBases.closeAt,
          inscriptionBasesPrice: inscriptionBases.price,
          inscriptionBasesLink: inscriptionBases.link,
          ...(tournament.organizers?.organizerId && {
            organizers: {
              organizerId: tournament.organizers?.organizerId
            }
          }),
          gameId: tournament.gameId ?
            { value: tournament.gameId, label: tournament.game!.title } :
            { value: 0, label: '' },
        }

        // // Convert timezones
        dataForm.startDate = toLocalTimezone(dataForm.startDate);
        dataForm.endDate = toLocalTimezone(dataForm.endDate);
        dataForm.inscriptionBasesOpenAt = toLocalTimezone(dataForm.inscriptionBasesOpenAt);
        dataForm.inscriptionBasesCloseAt = toLocalTimezone(dataForm.inscriptionBasesCloseAt);
        dataForm.matchStartDate = toLocalTimezone(dataForm.matchStartDate);

        useFormMethods.reset(dataForm);

        if (state?.section === 'matches') setEditMatch(true) // navigate to the Edit Match section

        // Set instructions and matchInstructions states to trigger the markdown previews
        setInstructions(dataForm?.instructions || '');
        setMatchInstructions(dataForm?.matchInstructions || '');
      }
    }, []
  )

  const asyncGameOptions = async (input: string) => {
    return new Promise(async (resolve) => {
      const { games } = await fetcher<{ games: Game[], meta: { count: number } }>(AdminGameEndpoints.all({title: input, page: 1, limit: 100}))

      const gameOptions = games?.map(game => ({
        value: game.id, label: game.title
      }));

      resolve(gameOptions);
    });  
  }

  const beforeSumbit = () => {
    if (autoMatchStart) {
      const autoMatchStartDate = new Date(Date.now() + autoMatchStartValue * 60000);
      useFormMethods.setValue('matchStartDate', convertUTCToLocalISOString(autoMatchStartDate.toISOString()).slice(0, -1) as any);
    }
  }

  return (
    <OlaggForm
      useFormMethods={useFormMethods}
      onSubmit={onSubmit}
      style={{
        width: '100%'
      }}
    >
      <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
        {/* Main section */}
        <Box w='full' display={!editMatch ? 'block' : 'none'}>
          <OlaggFormInput
            label='Título'
            controlName='title'
            required={true}
          />

          <OlaggFormInput
            label='Imagen de la Card'
            controlName='image'
            description='URL de la imagen para mostrar en la card, Ej.: https://...'
            required={true}
          />

          <OlaggFormInput
            label='Imagen del Banner (se recomienda tamaño 1366x400)'
            controlName='banner'
            description='URL de la imagen para mostrar como banner, Ej.: https://...'
            required={true}
          />

          <OlaggFormSelect
            label='Juego'
            controlName='gameId'
            required={true}
            isSearchable={true}
            staticOptions={[]}
            asyncOptions={asyncGameOptions}
          />

          <OlaggFormInput
            label='Organizador'
            controlName='organizers.organizerId'
            required={false}
          />

          <OlaggFormInput
            label='Slug GGTech'
            controlName='externalSlug'
            required={false}
          />

          <OlaggFormInput
            label='Región'
            controlName='region'
            required={false}
          />

          <OlaggFormInput
            label='Premios'
            controlName='reward'
            required={true}
          />

          <OlaggFormInput
            label='Fecha de inicio del torneo'
            controlName='startDate'
            inputType='datetime'
            description={`Zona horaria: ${currentTimeZone}`}
            datePickerProps={{ backgroundColor: 'white' }}
            required={true}
          />

          <OlaggFormInput
            label='Fecha de finalización del torneo'
            controlName='endDate'
            inputType='datetime'
            description={`Zona horaria: ${currentTimeZone} - Si no se especifica fecha, el torneo se considerará como finalizado 2 horas después de la fecha y hora de inicio`}
            datePickerProps={{ backgroundColor: 'white' }}
            required={false}
          />

          <OlaggFormInput
            label='Tipo de torneo'
            controlName='type'
            required={false}
          />

          <OlaggFormInput
            label='Instrucciones'
            controlName='instructions'
            description='Escribe las instrucciones (puedes usar texto con Markdown)'
            inputType='textarea'
            required={false}
            onChange={(e) => setInstructions(e.target.value)}
          />

          <Text lineHeight='normal' fontWeight={600} mb='2'>
            Instrucciones (Vista previa)
          </Text>
          <Box w='full' mb='5' border='1px solid white' minH='10' borderRadius='5' p='3'>
            <OlaggMarkdown>
              {instructions}
            </OlaggMarkdown>
          </Box>

          <OlaggFormInput
            label='Cupos mínimos'
            controlName='inscriptionBasesMin'
            required={true}
          />

          <OlaggFormInput
            label='Cupos máximos'
            controlName='inscriptionBasesMax'
            required={false}
          />

          <OlaggFormInput
            label='Inicio de inscripción'
            controlName='inscriptionBasesOpenAt'
            inputType='datetime'
            description={`Zona horaria: ${currentTimeZone}`}
            datePickerProps={{ backgroundColor: 'white' }}
            required={true}
          />

          <OlaggFormInput
            label='Fin de inscripción'
            controlName='inscriptionBasesCloseAt'
            inputType='datetime'
            description={`Zona horaria: ${currentTimeZone}`}
            datePickerProps={{ backgroundColor: 'white' }}
            required={false}
          />

          <OlaggFormInput
            label='Precio de entrada'
            controlName='inscriptionBasesPrice'
            required={false}
          />

          <OlaggFormInput
            label='Link para participar'
            controlName='inscriptionBasesLink'
            required={true}
          />

          <OlaggFormInput
            label='XP de participación'
            controlName='participationScore'
            description='XP que recibe el usuario por participar del torneo'
            required={false}
          />

          <OlaggFormInput
            label='XP de ganador'
            controlName='winnerScore'
            description='XP que recibe el ganador del torneo'
            required={false}
          />

          <OlaggFormInput
            label='Link con resultados del torneo'
            controlName='resultsUrl'
            required={false}
          />

          <OlaggFormInput
            label='Require Login'
            controlName='requiredAuth'
            inputType='switch'
            required={false}
          />

          <OlaggFormInput
            label='Requiere Discord vinculado'
            description='Será necesario vincular el Discord para poder participar del torneo'
            controlName='requiredDiscord'
            inputType='switch'
            required={false}
          />

          <OlaggFormInput
            label='Mostrar en Banner'
            controlName='showInBanner'
            inputType='switch'
            required={false}
          />

          <OlaggFormInput
            label='Torneo Cancelado'
            controlName='canceled'
            inputType='switch'
            required={false}
            chakraStyles={{ colorScheme: 'red' }}
          />
        </Box>

        {/* Edit Match section */}
        <Box w='full' display={editMatch ? 'block' : 'none'}>
          <OlaggFormInput
            label='Código de la partida'
            controlName='matchCode'
            required={editMatch}
          />

          <OlaggFormInput
            label='Inicio de la partida'
            controlName='matchStartDate'
            inputType='datetime'
            description={`Zona horaria: ${currentTimeZone}`}
            datePickerProps={{ backgroundColor: 'white' }}
            required={editMatch}
            chakraStyles={{ isDisabled: autoMatchStart }}
          />

          <Flex mb='5' alignItems='center'>
            <Switch
              mr='3'
              onChange={() => { setAutoMatchStart(!autoMatchStart) }}
              isChecked={autoMatchStart}
            >
            </Switch>
            Iniciar automáticamente dentro de
            <input
              type='number' min={1}
              value={autoMatchStartValue}
              onChange={(e) => { setAutoMatchStartValue(Number(e.target.value)); setAutoMatchStart(true) }}
              style={{
                marginInline: '5px',
                padding: '5px',
                background: 'none',
                border: '1px solid white',
                borderRadius: '6px',
                maxWidth: '40px',
                textAlign: 'center',
              }}
            ></input>
            minutos una vez guardados los cambios
          </Flex>

          <OlaggFormInput
            label='Instrucciones de la partida'
            controlName='matchInstructions'
            description='Escribe las instrucciones para la partida (puedes usar texto con Markdown)'
            inputType='textarea'
            required={false}
            onChange={(e) => setMatchInstructions(e.target.value)}
          />

          <Text lineHeight='normal' fontWeight={600} mb='2'>
            Instrucciones de la partida (Vista previa)
          </Text>
          <Box w='full' mb='5' border='1px solid white' minH='10' borderRadius='5' p='3'>
            <OlaggMarkdown>
              {matchInstructions}
            </OlaggMarkdown>
          </Box>

          <OlaggFormInput
            label='Canal de Discord'
            controlName='discordChannel'
            required={false}
          />

          <OlaggFormInput
            label='URL del canal de Discord'
            controlName='discordUrl'
            required={false}
          />

          <OlaggFormInput
            label='Enviar notificacion por Discord'
            controlName='discordNotification'
            inputType='switch'
            required={false}
          />
        </Box>

        <Button
          onClick={beforeSumbit}
          variant={'filledGradient'}
          type="submit"
          w={'full'}
          style={{
            marginTop: '30px'
          }}
        >
          Guardar
        </Button>

      </VStack>
    </OlaggForm>
  )
}

export default TournamentForm;
