import {
  Button,
  Center,
  Flex,
  Spinner,
  Table as ChakraTable,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { IUseUsers } from '@olagg/api-hooks/interfaces/IUseUsers'
import type {
  ApplicationStatus,
  DiscordAccount,
  MetamapVerification,
  User
} from '@olagg/db-types'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../consts/routes'
import { toggleAscDesc } from '../../utils'
import { Sort } from '../Sort/Sort'

interface ITrs {
  onFilter: (value: any) => void
  filter: IUseUsers
}

const Trs = ({ filter, onFilter }: ITrs) => {
  const { referralCountSort } = filter

  const togglereferralCountSort = () => {
    onFilter({
      name: 'referralCountSort',
      value: toggleAscDesc(referralCountSort!)
    })
  }

  return (
    <Tr>
      <Th color="white">Id <br />OLA</Th>
      <Th color="white">User <br />OLA</Th>
      <Th color="white">Email <br />OLA</Th>
      <Th color="white">ID <br />GGTech</Th>
      <Th color="white">User <br />GGTech</Th>
      <Th color="white">Email <br />GGtech</Th>
      <Th color="white">ID <br />Discord</Th>
      <Th color="white">País</Th>
      <Th color="white" onClick={togglereferralCountSort}>
        <Flex align="center" cursor="pointer">
          Nº Referidos
          <Sort value={referralCountSort!} />
        </Flex>
      </Th>
      <Th color="white">Acciones</Th>
    </Tr>
  )
}

interface IProps {
  users?: User[]
  onFilter: (value: any) => void
  filter: IUseUsers
  isLoading: boolean
  error: boolean
}

const UsersTable = ({
  users = [],
  filter,
  onFilter,
  isLoading,
  error
}: IProps) => {
  if (!users.length) {
    if (isLoading)
      return (
        <Center h="full">
          <Spinner />
        </Center>
      )
    if (error)
      return <Center h="full">Se ha producido un error</Center>

    return <Center h="full">No hay resultados</Center>
  }

  return (
    <ChakraTable variant="striped" colorScheme="olaggPurple">
      <Thead position='sticky' top='0' bg='gray.800' zIndex='100'>
        <Trs filter={filter} onFilter={onFilter} />
      </Thead>
      <Tbody>
        {users?.map(user => (
          <Tr key={user.id}>
            <Td>{user?.id}</Td>
            <Td>{user?.name}</Td>
            <Td>{user?.email}</Td>
            <Td>{user?.authProviders?.find((auth) => auth.provider == 'ggtech')?.internal_id}</Td>
            <Td>{user?.authProviders?.find((auth) => auth.provider == 'ggtech')?.username}</Td>
            <Td>{user?.authProviders?.find((auth) => auth.provider == 'ggtech')?.email}</Td>
            <Td>{user?.authProviders?.find((auth) => auth.provider == 'discord')?.internal_id}</Td>
            <Td>{user?.country}</Td>
            <Td>{user?.referrals.length}</Td>
            <Td>
              <Link
                to={`${ROUTES.USERS.all}/${user.id}`}>
                <Button colorScheme="green" ml='5px' size={'sm'} py='5px' mt='5px'>Ver</Button>
              </Link>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </ChakraTable>
  )
}

export default UsersTable
