import { UserContent } from "@olagg/db-types";
import { AdminUserContentEndpoints } from "../endpoints/adminEndpoints";
import { IUseUserContent } from "../interfaces/IUserUserContent";
import { useQ } from "./useQ";

export const useAdminUserContents = ({page, limit, title, gameId}: IUseUserContent) => {
    const response = useQ<{userContents: UserContent[], meta: {count: number}}>(
        AdminUserContentEndpoints.all({
            page,
            limit,
            title,
            gameId
        }),
        {
            revalidateOnFocus: true
        }
    )

    return {
        ...response,
        userContents: response.data?.userContents || [],
        count: response.data?.meta?.count || 0
    }
}