import type {
  UserContent
} from '@olagg/db-types'
import { AdminUserContentEndpoints } from '../endpoints/adminEndpoints'
import { useQ } from './useQ'

export const useAdminUserContent = (id: string) =>{
  const response = useQ<{userContent: UserContent}>(
    AdminUserContentEndpoints.find(id), {revalidateOnFocus: true}
  )

  return {
    ...response,
    userContent: response.data?.userContent
  }
}