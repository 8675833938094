import {
  Button,
  Center,
  Input,
  Spinner,
  Text,
  useToast
} from '@chakra-ui/react'
import { fetcher, ScholarshipsEndpoints } from '@olagg/api-hooks'
import { GameCredentials } from '@olagg/db-types'
import { ChangeEvent, FC, useRef, useState } from 'react'
import { FaEdit } from 'react-icons/fa'
import Error from './Error'

interface IError {
  message: string
  status: string
  extraData: string[][] | GameCredentials[]
}

interface IProps {
  onClose: () => void
}

const UploadFile: FC<IProps> = ({ onClose }) => {
  const toast = useToast()

  const inputFile = useRef<HTMLInputElement | null>(null)
  const [selectedFile, setSelectedFile] = useState<File>()

  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState<string[][]>([])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files

    if (!file) return

    setSelectedFile(file[0])
  }

  const onUpload = async () => {
    if (!selectedFile) return

    setIsLoading(true)
    try {
      const formData = new FormData()
      formData.append('file', selectedFile)

      const { success } = await fetcher(
        ScholarshipsEndpoints.createBulkCredentials(formData)
      )

      if (!success) return
      toast({
        title: 'Éxito',
        status: 'success',
        description: 'Las becas fueron creadas correctamente',
        position: 'bottom-right',
        duration: 6000,
        isClosable: true
      })
      onClose()
    } catch (_error) {
      let error = _error as IError
      switch (error.status) {
        case 'fail+duplicates':
          const _data = error.extraData as GameCredentials[]

          const data =
            _data.length <= 5 ? _data : [..._data.slice(0, 5)]

          const errorMessages = data.map((d, i) => {
            return [
              `<a href="${window.location.origin}/becas/${
                d.id
              }" target="_blank">Ver Beca Duplicada #${
                i + 1
              }</a>`
            ]
          })

          setErrors(errorMessages)
          break
        case 'fail+validations':
          setErrors(error.extraData as string[][])
          break
      }
      toast({
        title: 'Ups! No se han podido crear las becas',
        status: 'error',
        description: error.message,
        position: 'bottom-right'
      })
    } finally {
      setIsLoading(false)
    }
  }

  const clearValues = () => {
    setErrors([])
    setSelectedFile(undefined)
  }
  return (
    <Center flexDir="column" w="full">
      {!selectedFile && (
        <Button
          variant="outlinedGradient"
          w="full"
          onClick={() => inputFile.current?.click()}
        >
          <Text color="olaggPurple.100">Elegir Archivo</Text>
        </Button>
      )}

      {selectedFile && (
        <>
          <Button
            variant="filledGradient"
            w="full"
            onClick={onUpload}
            disabled={!!errors.length}
          >
            {!isLoading ? 'Subir Archivo' : <Spinner />}
          </Button>
          <Button
            mt={2}
            gap="3"
            variant="link"
            onClick={() => inputFile.current?.click()}
            disabled={!!errors.length}
          >
            <Text>{selectedFile.name}</Text>
            <FaEdit />
          </Button>
        </>
      )}
      {!!errors.length && (
        <Error errors={errors} clearValues={clearValues} />
      )}
      <Input
        display="none"
        type="file"
        accept=".csv"
        id="file"
        ref={inputFile}
        onChange={handleChange}
      />
    </Center>
  )
}

export default UploadFile
