import { Stack } from '@chakra-ui/react'
import { useAdminGames } from '@olagg/api-hooks'
import { IUseCredentials } from '@olagg/api-hooks/interfaces/IUseCredentials'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import OlaggForm from '../Forms/OlaggForm'
import OlaGGFormInput from '../Forms/OlaggFormInput'
import OlaggFormSelect from '../Forms/OlaggFormSelect'

interface IProps {
  onFilter: (value: any) => void
}

const CredentialsFilter = ({ onFilter }: IProps) => {
  const [title, setTitle] = useState('')
  const { games } = useAdminGames({
    title,
    page: 1,
    limit: 100
  })

  const useFormMethods = useForm({
    mode: 'onChange'
  })

  const statusOptions = [
    { value: 'FREE', label: 'Libre' },
    { value: 'USER_INPUTS_REQUIRED', label: 'Requiere ingreso de datos del usuario' },
    { value: 'NOT_BUILT_YET', label: 'Requiere creación de cuenta / Arrendamiento' },
    { value: 'ASSIGNED', label: 'Asignada' },
    { value: 'DISABLED', label: 'Deshabilitada' }
  ]

  const handleStatusChange = (data: any) =>
    onFilter({ name: 'status', value: data?.value })

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onFilter({ name: 'query', value: e.target.value })

  const handleGameChange = (data: any) =>
    onFilter({ name: 'slug', value: data?.value })

  return (
    <OlaggForm
      useFormMethods={useFormMethods}
      onSubmit={() => { }}
      style={{ color: 'white', zIndex: '2' }}
    >
      <Stack spacing={4} direction='row' align='center'>
        <OlaggFormSelect
          controlName={'slug'}
          staticOptions={games?.map(game => ({
            value: game.slug,
            label: game.title
          }))}
          isSearchable={true}
          onInputChange={setTitle}
          onChange={handleGameChange}
          required={true}
        />

        <OlaggFormSelect
          controlName={'status'}
          staticOptions={statusOptions}
          onChange={handleStatusChange}
          required={true}
        />

        <OlaGGFormInput
          controlName={'query'}
          placeholder="Usuario, email, identificador NFT"
          onChange={handleQueryChange}
        />
      </Stack>
    </OlaggForm>
  )
}

export default CredentialsFilter
