import { GameWithTournament, TournamentData } from "@olagg/db-types"
import { useState } from "react"
import { AdminTournamentsEndpoints } from "../endpoints/adminEndpoints"
import { fetcher } from "../fetcher"
import { useToast } from "@chakra-ui/react"
import { IUseTournaments } from "../interfaces/IUseTournaments"

export const useAdminTournaments = () => {
  const toast = useToast()
  const [tournaments, setTournaments] = useState<TournamentData[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [games, setGames] = useState<GameWithTournament[]>([])
  const [sortByStartDate, setSortByStartDate] = useState<'asc' | 'desc'>('desc')
  const [count, setCount] = useState<number>(0)

  const all = async (filter: IUseTournaments) => {
    setIsLoading(true)
    fetcher(AdminTournamentsEndpoints.all(filter))
      .then(response => {
        setTournaments(response.tournaments)
        setCount(response.meta.count)
        setIsLoading(false)
      })
  }

  const deleteTournament = async (id: string) => {
    fetcher(AdminTournamentsEndpoints.delete(id))
      .then(() => {
        toast({
          title: 'Torneos',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Torneo eliminado con éxito',
          position: 'bottom-right'
        });
        window.location.reload()
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
  }

  const setGamesList = async () => {
    fetcher(AdminTournamentsEndpoints.all({
      page: 1, 
      limit: 9999999
    })).then(response => {
      const allGames: GameWithTournament[] = response.tournaments?.map((tournament: TournamentData) => ({
        title: tournament.game?.title,
        slug: tournament.game?.slug
      }))
      const uniqueGames = [...new Map(allGames.map(item => [item.slug, item]))].map(item => item[1]);
      setGames(uniqueGames);
    })
  }

  const togleSortByStartDate = () => setSortByStartDate((prevValue) => prevValue === 'asc' ? 'desc' : 'asc');

  return {
    all,
    deleteTournament,
    setGamesList,
    togleSortByStartDate,
    isLoading,
    sortByStartDate,
    tournaments,
    games,
    count
  }
}
