import {
  Box,
  BoxProps,
  Button,
  chakra,
  Checkbox,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import type { Game } from '@olagg/db-types'
import { FC, useState } from 'react'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { FaUserClock, FaUserTie, FaVideo } from 'react-icons/fa'
import { FiEdit } from 'react-icons/fi'
import { GrStatusGood } from 'react-icons/gr'
import { Clock } from './svg'

const GameCardAsAdmin: FC<
  Partial<Game> &
    BoxProps & {
      toggleGameStatus: (slug: string) => Promise<void>
      toggleGameVisibility: (slug: string) => Promise<void>
      viewRequests: (slug: string) => void
      viewApproved: (slug: string) => void
      editGame: (slug: string) => void
      gameContent: (slug: string) => void
    }
> = ({
  slug = '',
  title,
  description,
  image,
  visible,
  status,
  updatedAt,
  createdAt,
  toggleGameStatus,
  toggleGameVisibility,
  viewRequests,
  viewApproved,
  editGame,
  gameContent,
  ...rest
}) => {
  const {
    onOpen: openHandleChangeGameStatus,
    isOpen: isOpenHandleChangeGameStatus,
    onClose: closeHandleChangeGameStatus
  } = useDisclosure()

  const {
    onOpen: openHandleChangeGameVisibility,
    isOpen: isOpenHandleChangeGameVisibility,
    onClose: closeHandleChangeGameVisibility
  } = useDisclosure()

  const [confirmChangeGameStatus, setConfirmChangeGameStatus] =
    useState(false)

  const [
    confirmChangeGameVisibility,
    setConfirmChangeGameVisibility
  ] = useState(false)

  return (
    <>
      {/* MODALS */}
      <Modal
        isOpen={isOpenHandleChangeGameVisibility}
        onClose={closeHandleChangeGameVisibility}
        isCentered
      >
        <ModalOverlay />
        <ModalContent mx={5}>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box pt={4} px={1}>
              <Heading
                fontSize={'24px'}
                color={'olaggPurple.100'}
                pb={4}
                textAlign={'center'}
              >
                Actualizando la visibilidad de{' '}
                <Text color="olaggBlue.100">{title}</Text>
              </Heading>
              <Box>
                <Text
                  fontSize={'16px'}
                  fontFamily={'text'}
                  textAlign={'center'}
                >
                  ¿Seguro que quieres cambiar la visibilidad del
                  juego?
                </Text>
                <Text
                  pt={4}
                  fontSize={'16px'}
                  fontFamily={'text'}
                  textAlign={'center'}
                >
                  Pasará a ser
                </Text>
                {visible ? (
                  <Text
                    color="black"
                    fontWeight={'bold'}
                    fontSize={'16px'}
                    fontFamily={'text'}
                    textAlign={'center'}
                    backgroundColor="olaggYellow.100"
                    py={2}
                  >
                    "Oculto"
                  </Text>
                ) : (
                  <Text
                    color="black"
                    fontWeight={'bold'}
                    fontSize={'16px'}
                    fontFamily={'text'}
                    textAlign={'center'}
                    backgroundColor="green.300"
                    py={2}
                  >
                    "Visible"
                  </Text>
                )}
              </Box>
              <Box pt={5} px={6}>
                <Checkbox
                  fontFamily={'text'}
                  checked={confirmChangeGameVisibility}
                  onChange={() => {
                    setConfirmChangeGameVisibility(prev => !prev)
                  }}
                >
                  Si, estoy seguro
                </Checkbox>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant={'filledGradient'}
              w={'full'}
              onClick={async () => {
                await toggleGameVisibility(slug)
                closeHandleChangeGameVisibility()
              }}
            >
              Aceptar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isOpenHandleChangeGameStatus}
        onClose={closeHandleChangeGameStatus}
        isCentered
      >
        <ModalOverlay />
        <ModalContent mx={5}>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box pt={4} px={1}>
              <Heading
                fontSize={'24px'}
                color={'olaggPurple.100'}
                pb={4}
                textAlign={'center'}
              >
                Actualizando el estado de{' '}
                <Text color="olaggBlue.100">{title}</Text>
              </Heading>
              <Box>
                <Text
                  fontSize={'16px'}
                  fontFamily={'text'}
                  textAlign={'center'}
                >
                  ¿Seguro que quieres cambiar el estado del
                  juego?
                </Text>
                <Text
                  pt={4}
                  fontSize={'16px'}
                  fontFamily={'text'}
                  textAlign={'center'}
                >
                  Pasará a ser
                </Text>
                {status === 'Activo' ? (
                  <Text
                    color="black"
                    fontWeight={'bold'}
                    fontSize={'16px'}
                    fontFamily={'text'}
                    textAlign={'center'}
                    backgroundColor="olaggYellow.100"
                    py={2}
                  >
                    "Próximamente"
                  </Text>
                ) : (
                  <Text
                    color="black"
                    fontWeight={'bold'}
                    fontSize={'16px'}
                    fontFamily={'text'}
                    textAlign={'center'}
                    backgroundColor="green.300"
                    py={2}
                  >
                    "Activo"
                  </Text>
                )}
              </Box>
              <Box pt={5} px={6}>
                <Checkbox
                  fontFamily={'text'}
                  checked={confirmChangeGameStatus}
                  onChange={() => {
                    setConfirmChangeGameStatus(prev => !prev)
                  }}
                >
                  Si, estoy seguro
                </Checkbox>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              variant={'filledGradient'}
              w={'full'}
              onClick={async () => {
                await toggleGameStatus(slug)
                closeHandleChangeGameStatus()
              }}
            >
              Aceptar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* END MODALS */}

      <Box
        bg="olaggPurple.100"
        shadow="lg"
        rounded="xl"
        {...rest}
        mx={1}
      >
        <Box mx={3} pt={4} pb={2}>
          <Box pos={'relative'}>
            <Box
              w="full"
              backgroundSize={'cover'}
              bgRepeat={'no-repeat'}
              bgPos={'center'}
              boxSize={'auto'}
              h={48}
              borderRadius={'md'}
              backgroundImage={`linear-gradient(254.57deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%), url(${
                image ||
                'https://whalejournal.com/wp-content/uploads/2021/11/Axie-Infinity.jpeg'
              })`}
            />

            <Tag
              size={'md'}
              variant="solid"
              backgroundColor={visible ? 'white' : 'black'}
              rounded={'3px'}
              py={1}
              pos={'absolute'}
              top={2}
              left={2}
              cursor={'pointer'}
              onClick={openHandleChangeGameVisibility}
            >
              <TagLabel
                fontWeight={'semibold'}
                color={visible ? 'black' : 'white'}
              >
                {visible ? 'Visible' : 'Oculto'}
              </TagLabel>
              {(visible && (
                <TagRightIcon
                  ml={1}
                  mb={0.5}
                  as={AiFillEye}
                  color={'black'}
                />
              )) || (
                <TagRightIcon
                  ml={1}
                  mb={0.5}
                  as={AiFillEyeInvisible}
                  color="white"
                />
              )}
            </Tag>

            <Tag
              size={'md'}
              variant="solid"
              colorScheme={
                status === 'Próximamente'
                  ? 'olaggYellow'
                  : 'green'
              }
              rounded={'3px'}
              py={1}
              pos={'absolute'}
              top={2}
              right={2}
              cursor={'pointer'}
              onClick={openHandleChangeGameStatus}
            >
              <TagLabel fontWeight={'semibold'} color={'white'}>
                {status}
              </TagLabel>
              {(status === 'Próximamente' && (
                <TagRightIcon ml={1} mb={0.5} as={Clock} />
              )) || (
                <TagRightIcon
                  ml={1}
                  mb={0.5}
                  as={GrStatusGood}
                />
              )}
            </Tag>
          </Box>
        </Box>

        <Box mt={1} mx={3}>
          <chakra.h1
            color="white"
            fontSize="24px"
            fontWeight="semibold"
            lineHeight={'9'}
          >
            {title}
          </chakra.h1>
          <chakra.p
            fontSize="15px"
            color="white"
            fontFamily={'text'}
            lineHeight={'150%'}
            fontWeight={'medium'}
            alignSelf={'stretch'}
            fontStyle={'normal'}
          >
            {description}
          </chakra.p>
        </Box>

        <VStack
          alignItems="center"
          justifyContent="space-around"
          pb={4}
          pt={2}
          px={3}
          w={'full'}
          roundedBottom="lg"
        >
          <Button
            variant={'filledGradient'}
            leftIcon={<FiEdit />}
            w={'full'}
            color={'white'}
            onClick={() => editGame(slug)}
          >
            Editar juego
          </Button>
          <Button
            variant={'filledGradient'}
            leftIcon={<FiEdit />}
            w={'full'}
            color={'white'}
            onClick={() => gameContent(slug)}
          >
            Contenido
          </Button>
          <Button
            variant={'filledGradient'}
            leftIcon={<FaUserClock />}
            w={'full'}
            color={'white'}
            onClick={() => viewRequests(slug)}
          >
            Ver solicitudes
          </Button>
          <Button
            variant={'filledGradient'}
            leftIcon={<FaUserTie />}
            w={'full'}
            color={'white'}
            onClick={() => viewApproved(slug)}
          >
            Gestionar Becados
          </Button>
        </VStack>
      </Box>
    </>
  )
}

export { GameCardAsAdmin }
