import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import Check from './svg/Check'

interface IProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: (categories: string[]) => void
  defaultValues: string[]
  loadedValues: string[]
  title?: string
}
export default function MultiSelectModal({
  isOpen,
  onClose,
  defaultValues,
  loadedValues,
  onConfirm,
  title
}: IProps) {
  const [selectedValues, setSelectedValues] = useState<string[]>(
    []
  )

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setSelectedValues(loadedValues)
  }, [loadedValues])

  useEffect(() => {
    if (!isOpen) setIsLoading(false)
  }, [isOpen])

  const checkIfSelected = (value: string) =>
    selectedValues?.some(
      selectedValue => selectedValue === value
    )

  const selectValue = (value: string) => {
    const valueExists: boolean = selectedValues?.some(
      selectedValue => selectedValue === value
    )

    if (valueExists) {
      return setSelectedValues(prev =>
        prev.filter(selectedValue => selectedValue !== value)
      )
    }

    setSelectedValues(prev => [...prev, value])
  }

  const onClickConfirm = () => {
    setIsLoading(true)
    onConfirm(selectedValues)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={5}>
        <ModalCloseButton />
        <ModalHeader color="olaggPurple.100">
          {title}
        </ModalHeader>
        <ModalBody>
          {defaultValues.map(value => (
            <Flex
              key={value}
              bg={
                checkIfSelected(value)
                  ? 'olaggPink.100'
                  : 'gray.200'
              }
              mb={2}
              p={1}
              color="white"
              borderRadius="4px"
              w="fit-content"
              cursor="pointer"
              onClick={() => selectValue(value)}
            >
              <Text
                fontWeight="600"
                color={
                  checkIfSelected(value) ? 'white' : '#3F2091'
                }
              >
                {value}
              </Text>
            </Flex>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button
            w="full"
            gap="2"
            variant="filledGradient"
            onClick={onClickConfirm}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <Check />
                <Text mt={1} fontSize="20px" fontWeight="500">
                  Confirmar
                </Text>
              </>
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
