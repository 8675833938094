import {
  Button,
  Center,
  Flex,
  Spinner,
  Stack,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr
} from '@chakra-ui/react'
import { IUseScholarships } from '@olagg/api-hooks/interfaces/IUseScholarships'
import type {
  ApplicationStatus,
  ApplicationStatusUpdate,
  DiscordAccount,
  ScholarshipApplication,
  User
} from '@olagg/db-types'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../consts/routes'
import {
  mapScholarshipStatusToSpanish,
  normalizeDate,
  toggleAscDesc
} from '../../utils'
import { Sort } from '../Sort/Sort'

interface ITrs {
  onFilter: (value: any) => void
  filter: IUseScholarships
}

const Trs = ({ filter, onFilter }: ITrs) => {
  const { dateSort } = filter
  const toggleDateSort = () => {
    onFilter({
      name: 'dateSort',
      value: toggleAscDesc(dateSort)
    })
  }

  return (
    <Tr color="white">
      <Th color="white">ID Juego</Th>
      <Th color="white">Usuario</Th>
      <Th color="white">Email</Th>
      <Th color="white">Discord ID</Th>
      <Th
        color="white"
        onClick={toggleDateSort}
        cursor="pointer"
      >
        <Flex align="start">
          Fecha
          <Sort value={dateSort} />
        </Flex>
      </Th>
      <Th color="white">Estado</Th>
      <Th color="white">Acciones</Th>
    </Tr>
  )
}

interface IProps {
  scholarships?: ScholarshipApplication[]
  setCurrentAction: (value: ApplicationStatus) => void
  setCurrentApplication: (value: ScholarshipApplication) => void
  onOpen: () => void
  onFilter: (value: any) => void
  filter: IUseScholarships
}

const ScholarshipTable = ({
  scholarships = [],
  setCurrentAction,
  setCurrentApplication,
  onOpen,
  onFilter,
  filter
}: IProps) => {
  const navigate = useNavigate()

  return (
    <TableContainer
      pr={8}
      mt={8}
      w={'calc(100vw  - 240px)'}
      backgroundColor={'olaggDark.100'}
    >
      <ChakraTable
        variant="striped"
        colorScheme="olaggPurple"
        fontSize={'18px'}
        fontWeight="semibold"
      >
        <Thead position='sticky' top='0' bg='gray.800' zIndex='1'>
          <Trs filter={filter} onFilter={onFilter} />
        </Thead>
        <Tbody>
          {scholarships.map(scholarship => (
            <Tr key={scholarship.id}>
              <Td>{scholarship.game!.slug}</Td>
              <Td>
                <Tooltip label={scholarship.user?.id}>
                  <p>
                    {scholarship.user?.name ||
                      scholarship.user?.authProviders?.find(provider => provider.provider === 'discord')?.id ||
                      '-'}
                  </p>
                </Tooltip>
              </Td>
              <Td>{scholarship.user?.email}</Td>
              <Td>{scholarship.user?.authProviders?.find(provider => provider.provider === 'discord')?.internal_id || '-'}</Td>
              <Td>{normalizeDate(scholarship.createdAt)}</Td>
              <Td>{mapScholarshipStatusToSpanish(scholarship.status)}</Td>
              <Td>
                {scholarship.status === 'PENDING' ? (
                  <>
                    <Button
                      colorScheme="green" ml='5px' size={'sm'} py='5px' mt='5px'
                      onClick={() => {
                        setCurrentAction('APPROVED')
                        setCurrentApplication(scholarship)
                        onOpen()
                      }}
                    >
                      Aceptar
                    </Button>
                    <Button
                      colorScheme="red" ml='5px' size={'sm'} py='5px' mt='5px'
                      onClick={() => {
                        setCurrentAction('REJECTED')
                        setCurrentApplication(scholarship)
                        onOpen()
                      }}
                    >
                      Rechazar
                    </Button>
                  </>
                ) : scholarship.status === 'PENDING_NO_KYC_YET' ? (
                  <>
                    <Button
                      colorScheme="green" ml='5px' size={'sm'} py='5px' mt='5px'
                      onClick={() => {
                        setCurrentAction('APPROVED')
                        setCurrentApplication(scholarship)
                        onOpen()
                      }}
                    >
                      Aceptar
                    </Button>
                    <Button
                      colorScheme="red" ml='5px' size={'sm'} py='5px' mt='5px'
                      onClick={() => {
                        setCurrentAction('REJECTED')
                        setCurrentApplication(scholarship)
                        onOpen()
                      }}
                    >
                      Rechazar
                    </Button>
                  </>
                ) : scholarship.status === 'APPROVED' ? (
                  <Button
                    colorScheme="green" ml='5px' size={'sm'} py='5px' mt='5px'
                    onClick={() =>
                      navigate(
                        `${ROUTES.CREDENTIALS.all}/${scholarship.credential!.id}`
                      )
                    }
                  >
                    Ver Beca
                  </Button>
                ) : (
                  scholarship.status === 'SUSPENDED' && (
                    <Button
                      colorScheme={'green'}
                      disabled={true}
                    >
                      Accion
                    </Button>
                  )
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}

export default ScholarshipTable
