import { Center, VStack, Heading, Text, Flex, Switch, useToast, useDisclosure } from "@chakra-ui/react"
import { AdminCredentialsEndpoints, fetcher, ScholarshipsEndpoints } from "@olagg/api-hooks"
import useAdminScholarships from "@olagg/api-hooks/queries/useAdminScholarships"
import { GameCredentials } from "@olagg/db-types"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import CredentialsForm from "./CredentialsForm"
import ReassignCredential from "./ReassignCredential"

const CreateCredential = () => {
  const toast = useToast()

  const onSubmit = (data: GameCredentials) => {
    fetcher(
      AdminCredentialsEndpoints.create(data)
    ).then(() =>
      toast({
        title: 'Beca creada',
        colorScheme: 'olaggPink',
        status: 'success',
        description: 'La beca fue creada con éxito',
        position: 'bottom-right'
      })
    ).catch(e =>
      toast({
        title: 'Error',
        colorScheme: 'olaggPink',
        status: 'error',
        description: e.message,
        position: 'bottom-right'
      })
    )
  }
  const credential: GameCredentials = {
    id: '',
    username: '',
    email: '',
    password: '',
    walletAddress: '',
    seedPhrase: '',
    userRequired: [],
    notShow: [],
    blockchain: '',
    nftIdentifier: '',
    personal: false,
    gameId: '',
    userNotes: '',
    adminNotes: '',
    built: false,
    userInputsCompleted: false,
    active: true,
    createdAt: new Date(),
    updatedAt: new Date(),
  }

  return (
    <Center w={'full'}>
      <VStack color={'white'}>
        <Center flexDir="column">
          <Flex w="full" justify="space-between" align="center" my={2}>
            <Heading w={"100%"}>Crear Beca</Heading>
          </Flex>

          <CredentialsForm
            credential={credential}
            onSubmit={onSubmit}
            isEditable={true}
          />
        </Center>

      </VStack>
    </Center>
  )
}


export default CreateCredential
