import type { QuestOwner } from '@olagg/db-types'
import { AdminOwnersEndpoints } from '../endpoints/adminEndpoints'
import { useQ } from './useQ'

export const useAdminOwnerQuests = ({page, limit, name}:{page: number, limit: number, name?: string}) =>{
  const response = useQ<{owners: QuestOwner[], meta: {count: number}}>(
    AdminOwnersEndpoints.all({name, page, limit})
  )
  return {
    ...response,
    owners: response.data?.owners ?? [],
    count: response.data?.meta.count ?? 0
  }
}
