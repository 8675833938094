import { FC, useState } from 'react'
import {
  Center,
  Heading,
  VStack,
  Button,
  Box,
  Text,
  Spinner,
} from '@chakra-ui/react'
import { useAdminItems } from '@olagg/api-hooks'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTES } from '../../consts/routes'
import { FaPlus } from 'react-icons/fa'
import { IUseItems } from '@olagg/api-hooks/interfaces/IUseItems'
import Pagination from '@olagg/ui-kit/Pagination'
import ItemsTable from './Table'

const GamesContent: FC = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<IUseItems>({
    title: searchParams.get('title') || undefined,
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 10)
  })

  const onFilter = ({
    name,
    value
  }: EventTarget & HTMLInputElement) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }
  const { title, page, limit } = filter
  const { items, count, isLoading } = useAdminItems({
    page, limit, title
  })

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Tienda
        </Heading>

        <Button
          display='flex'
          alignSelf='self-start'
          variant="outlinedGradient"
          leftIcon={<FaPlus />}
          onClick={() => navigate(ROUTES.MARKETPLACE.create)}
        >
          Agregar Item
        </Button>

        {isLoading ? (
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
        ) : (
          items?.length > 0 ? (
            <ItemsTable items={items} />
          ) : (
            <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
              <Text>No hay items en la tienda</Text>
            </Box>
          )
        )}
        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: String(page + 1)
            } as EventTarget & HTMLInputElement)
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            } as EventTarget & HTMLInputElement)
          }
          hideLimitInput
        />
      </VStack >
    </Center >
  )
}

export default GamesContent
