import { FC, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

/**
 * OlaggMarkdown
 * 
 * Implements a markdown component, using the OlaGG styles, fonts and colors
 * 
 * @param {string} baseFontSize Optional - the base font size for the markdown, default is 1rem
 * @param {string} children - the string with markdown to render
 * 
 * @example
 * 
 * import OlaggMarkdown from '@olagg/ui-kit/OlaggMarkdown'
 * 
 * const textWithMarkdown = 'This is a text with **Markdown**: \n # h1 Heading \n ## h2 Heading \n ### h3 Heading';
 *  
 * <OlaggMarkdown
 *   baseFontSize='1.2rem'
 * >
 *   {textWithMarkdown}
 * </OlaggMarkdown>
 * 
 */

interface IOlaggMarkdownProps {
    baseFontSize?: string
    children: string // children MUST be a string
};

const OlaggMarkdown: FC<IOlaggMarkdownProps> = ({
    baseFontSize = '1rem',
    children
}) => {
    // Load Twitter script after page loads for rendering tweet styles
    // (TODO: evaluate using `loader` from react-router-dom to preload the script)
    useEffect(() => {
        loadTwitterWidgetScript('https://platform.twitter.com/widgets.js');
    }, []);

    return (
        <Box
            fontSize={baseFontSize}
            sx={{
                '& h1': {
                    fontSize: '2em',
                    marginBottom: '1.375em'
                },
                '& h2': {
                    fontSize: '1.688em',
                    marginBottom: '1.375em'
                },
                '& h3': {
                    fontSize: '1.438em',
                    marginBottom: '1.375em'
                },
                '& p, li': {
                    fontSize: '1.125em',
                    marginBottom: '1.125em'
                },
                '& li': {
                    listStylePosition: 'inside',
                    marginBottom: '0.625em'
                },
                '& li p': {
                    display: 'inline',
                },
                '& a': {
                    color: '#FEAD10'
                },
                '& img': {
                    margin: '1.875em auto',
                },
                '& iframe': {
                    width: '100%'
                },
            }}
        >
            <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]} linkTarget='_blank'>
                {children || ''}
            </ReactMarkdown>
        </Box>
    )
}

function loadTwitterWidgetScript(src: string) {
    const scriptTag = document.createElement('script');
    scriptTag.src = src;
    document.body.appendChild(scriptTag);
}

export default OlaggMarkdown;
