import { TypeOf, z } from 'zod'

const status = z
  .enum(['verified', 'reviewNeeded', 'rejected'])
  .transform(v => {
    switch (v) {
      case 'verified':
        return 'APPROVED'
      case 'reviewNeeded':
        return 'PENDING'
      case 'rejected':
        return 'REJECTED'
    }
  })
  .nullish()

export const metamapWebhookData = z.object({
  body: z.object({
    resource: z.string(),
    eventName: z.enum([
      'verification_started', // cuando el usuario abre la SDK
      'verification_inputs_completed', // cuando el usuario termina de llenar los inputs y metamap comienza a verificarlos
      'verification_completed', // cuando metamap termina de verificar los inputs del usuario
      'verification_updated', // cuando alguien del equipo de OLA actualiza manualmente el estado
      'step_completed' // cuando el usuario termina alguno de los pasos
    ]),
    flowId: z.string(),
    timestamp: z.string(),
    step: z
      .object({
        status: z.number(),
        id: z.string(),
        data: z.any().nullish(),
        error: z
          .object({
            type: z.string(),
            code: z.string(),
            message: z.string()
          })
          .nullish(),
        documentType: z.string().nullish(),
        phase: z.string().nullish(),
        startedManuallyAt: z.string().nullish(),
        readDetails: z.array(z.any()).nullish()
      })
      .nullish(),
    deviceFingerprint: z.any().nullish(),
    matiDashboard: z.string().nullish(),
    status: status,
    identityStatus: status,
    details: z.any().nullish(),
    metadata: z.object({
      userId: z.number()
    })
  })
})

export type MetamapWebhookDataInput = TypeOf<
  typeof metamapWebhookData
>['body']

// status, id, data, error
