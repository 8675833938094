export const Dice = ({
  height = '21px',
  width = '20px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.32498 11.225C6.15928 11.2249 6.00042 11.1589 5.88332 11.0417C5.82683 10.9839 5.78161 10.9161 5.74998 10.8417C5.69428 10.7251 5.67611 10.594 5.69797 10.4666C5.71982 10.3393 5.78063 10.2218 5.87202 10.1304C5.96341 10.039 6.08089 9.97818 6.20828 9.95633C6.33566 9.93447 6.46669 9.95265 6.58332 10.0083C6.73419 10.0709 6.85407 10.1908 6.91665 10.3417C6.94988 10.4179 6.96691 10.5002 6.96665 10.5833C6.96621 10.6664 6.94921 10.7486 6.91665 10.825C6.88827 10.9011 6.84263 10.9696 6.78332 11.025C6.7248 11.0881 6.65389 11.1384 6.57503 11.1729C6.49616 11.2073 6.41103 11.225 6.32498 11.225Z"
        fill={color}
      />
      <path
        d="M10 11.1167C9.91685 11.117 9.83455 11.0999 9.75833 11.0667C9.68396 11.0351 9.61613 10.9899 9.55833 10.9334C9.4426 10.8153 9.37692 10.657 9.375 10.4917C9.37598 10.4094 9.39004 10.3279 9.41667 10.25C9.48814 10.1004 9.60873 9.97984 9.75833 9.90837C9.83543 9.87561 9.91825 9.85846 10.002 9.85792C10.0858 9.85739 10.1688 9.87347 10.2463 9.90525C10.3238 9.93703 10.3943 9.98386 10.4535 10.043C10.5128 10.1022 10.5598 10.1726 10.5917 10.25C10.6249 10.3263 10.6419 10.4086 10.6417 10.4917C10.6417 10.6575 10.5758 10.8164 10.4586 10.9336C10.3414 11.0509 10.1824 11.1167 10.0167 11.1167H10Z"
        fill={color}
      />
      <path
        d="M10.0918 7.45833C10.0117 7.47097 9.93019 7.47097 9.85013 7.45833C9.77418 7.42581 9.70601 7.37752 9.65013 7.31667C9.5911 7.2592 9.54309 7.19142 9.50846 7.11667C9.48184 7.03883 9.46777 6.95726 9.4668 6.875C9.46851 6.79553 9.48256 6.71681 9.50846 6.64167C9.54085 6.56321 9.58907 6.49229 9.65013 6.43333C9.73783 6.34842 9.84819 6.29067 9.96795 6.26701C10.0877 6.24336 10.2117 6.25481 10.3251 6.3C10.4035 6.32875 10.4746 6.3742 10.5336 6.4332C10.5926 6.4922 10.638 6.56334 10.6668 6.64167C10.6835 6.71855 10.6835 6.79812 10.6668 6.875C10.6692 6.95665 10.655 7.03795 10.6249 7.11389C10.5948 7.18984 10.5495 7.25884 10.4918 7.31667C10.4314 7.37596 10.3608 7.42394 10.2835 7.45833C10.2198 7.46635 10.1554 7.46635 10.0918 7.45833Z"
        fill={color}
      />
      <path
        d="M9.86673 14.7583C9.7424 14.7597 9.62048 14.724 9.51657 14.6557C9.41266 14.5874 9.33147 14.4897 9.2834 14.375C9.25017 14.2988 9.23314 14.2165 9.2334 14.1333C9.23384 14.0502 9.25084 13.9681 9.2834 13.8917C9.31503 13.8173 9.36024 13.7495 9.41673 13.6917C9.50457 13.6042 9.61644 13.5448 9.73812 13.521C9.8598 13.4973 9.98579 13.5103 10.1001 13.5583C10.1744 13.5899 10.2423 13.6352 10.3001 13.6917C10.3591 13.7491 10.4071 13.8169 10.4417 13.8917C10.4703 13.9693 10.4872 14.0507 10.4917 14.1333C10.4879 14.216 10.4711 14.2976 10.4417 14.375C10.4071 14.4497 10.3591 14.5175 10.3001 14.575C10.1855 14.691 10.0297 14.7569 9.86673 14.7583Z"
        fill={color}
      />
      <path
        d="M13.625 11C13.5419 11.0002 13.4596 10.9832 13.3833 10.95C13.309 10.9184 13.2411 10.8731 13.1833 10.8167C13.069 10.6976 13.0036 10.54 13 10.375C12.9997 10.2918 13.0168 10.2095 13.05 10.1333C13.0803 10.0558 13.1256 9.98499 13.1833 9.92499C13.2432 9.87111 13.3106 9.8262 13.3833 9.79165C13.4983 9.74779 13.6233 9.73703 13.7441 9.7606C13.8649 9.78417 13.9766 9.84112 14.0667 9.92499C14.1234 9.98574 14.1686 10.0563 14.2 10.1333C14.2332 10.2095 14.2503 10.2918 14.25 10.375C14.2494 10.4554 14.2323 10.5347 14.2 10.6083C14.1686 10.6853 14.1234 10.7559 14.0667 10.8167C13.9496 10.9339 13.7907 10.9998 13.625 11Z"
        fill={color}
      />
      <path
        d="M10.0001 19.5333C9.58979 19.5334 9.18349 19.4525 8.80453 19.2952C8.42557 19.1379 8.0814 18.9073 7.79177 18.6167L1.8751 12.7C1.28989 12.1141 0.961182 11.3198 0.961182 10.4917C0.961182 9.66355 1.28989 8.86928 1.8751 8.28334L7.79177 2.36668C8.37771 1.78147 9.17197 1.45276 10.0001 1.45276C10.8282 1.45276 11.6225 1.78147 12.2084 2.36668L18.1251 8.28334C18.4159 8.57292 18.6466 8.91706 18.804 9.29603C18.9614 9.67499 19.0425 10.0813 19.0425 10.4917C19.0425 10.902 18.9614 11.3084 18.804 11.6873C18.6466 12.0663 18.4159 12.4104 18.1251 12.7L12.2084 18.6167C11.9188 18.9073 11.5746 19.1379 11.1957 19.2952C10.8167 19.4525 10.4104 19.5334 10.0001 19.5333ZM10.0001 2.70001C9.75382 2.69931 9.50985 2.74757 9.28238 2.84199C9.05492 2.93641 8.84849 3.07511 8.6751 3.25001L2.75843 9.16668C2.40731 9.51824 2.21008 9.9948 2.21008 10.4917C2.21008 10.9886 2.40731 11.4651 2.75843 11.8167L8.6751 17.7333C9.02666 18.0845 9.50322 18.2817 10.0001 18.2817C10.497 18.2817 10.9735 18.0845 11.3251 17.7333L17.2418 11.8167C17.5929 11.4651 17.7901 10.9886 17.7901 10.4917C17.7901 9.9948 17.5929 9.51824 17.2418 9.16668L11.3251 3.25001C11.1517 3.07511 10.9453 2.93641 10.7178 2.84199C10.4903 2.74757 10.2464 2.69931 10.0001 2.70001Z"
        fill={color}
      />
    </svg>
  )
}

export default Dice
