import { TypeOf, z } from 'zod'

export const updateUserSchema = z.object({
  body: z.object({
    name: z
      .string({
        required_error: 'El nombre es requerido'
      })
      .min(3, 'El nombre debe ser de mínimo 3 caracteres')
      .max(64, 'El nombre debe ser de máximo 64 caracteres')
      .regex(
        /^[\w-]+$/,
        'El nombre contiene caracteres inválidos'
      )
      .optional(),
    email: z
      .string({
        required_error: 'El email del usuario es requerido'
      })
      .min(
        3,
        'El email del usuario debe ser de mínimo 3 caracteres'
      )
      .max(
        64,
        'El email del usuario debe ser de máximo 64 caracteres'
      )
      .email()
      .optional()
  })
})

export type UpdateUserInput = TypeOf<
  typeof updateUserSchema
>['body']
