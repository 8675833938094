import { FC, useState, useEffect, useCallback } from 'react'
import {
  Center,
  Heading,
  VStack,
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Image,
  Button,
  Box,
  Text,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
  useToast
} from '@chakra-ui/react'
import {
  fetcher,
  GameEndpoints,
  GameContentEndpoints
} from '@olagg/api-hooks'
import type { Game as GameType } from '@olagg/db-types'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import { ROUTES } from '../../consts/routes'
import { FaPlus } from 'react-icons/fa'

const GamesContent: FC = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const slug = searchParams.get('slug')
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(true)
  const [currentGame, setCurrentGame] = useState<GameType>()

  const initRequest = useCallback(async () => {
    setIsLoading(true)
    await fetcher(
      GameEndpoints.bySlug(slug)
    ).then((response: GameType) => {
      setCurrentGame(response)
    })

    setIsLoading(false)
  }, [])

  useEffect(() => {
    initRequest();
  }, [])

  // REPLACE IN DB
  const setImgURL = (url) => {
    if (url.startsWith('https://olagg.io/wp-content/')) {
      return url.replace('https://olagg.io/wp-content/', 'https://wpp.olagg.io/wp-content/')
    } else {
      return url
    }
  }

  const editGameContent = c => {
    localStorage.removeItem('gameContentEdit')
    localStorage.setItem('gameContentEdit', JSON.stringify(c))
    console.log(JSON.stringify(c));
    navigate(ROUTES.GAMES.content_update)
  }

  const deleteGameContent = async c => {
    setIsLoading(true)
    await fetcher(
      GameContentEndpoints.delete(c.id)
    ).then(() => {
      toast({
        title: 'Contenido eliminado',
        colorScheme: 'olaggPink',
        status: 'success',
        description: 'Imagen/Video eliminado con éxito',
        position: 'bottom-right'
      })
      window.location.reload();
    })
    .catch(e => {
      toast({
        title: 'Error',
        colorScheme: 'olaggYellow',
        status: 'error',
        description: e.message,
        position: 'bottom-right'
      })
    })

    setIsLoading(false)
  }

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Imagenes y Videos de {currentGame?.title}
        </Heading>

        <Button
          display='flex'
          alignSelf='self-start'
          variant="outlinedGradient"
          leftIcon={<FaPlus />}
          onClick={() => navigate(`${ROUTES.GAMES.content_create}?slug=${slug}`)}
        >
          Agregar contenido
        </Button>

        {isLoading ? (
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
        ) : (
          currentGame?.content?.length > 0 ? (
            <ChakraTable
              variant="striped"
              colorScheme="olaggPurple"
              fontSize="18px"
              fontWeight="semibold"
            >
              <Thead>
                <Tr>
                  <Th color="white">Miniatura</Th>
                  <Th color="white">Tipo</Th>
                  <Th color="white">Link</Th>
                  <Th textAlign='center' color="white">Acción</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentGame?.content.map(c => (
                  <Tr key={c.id}>
                    <Td>
                      <Image maxW={'200px'} src={setImgURL(c?.thumbnail)} />
                    </Td>
                    <Td>{c?.type}</Td>
                    <Td>{c?.link}</Td>
                    <Td textAlign='center'>
                      <Button onClick={() => {
                        editGameContent(c)
                      }} colorScheme="green">Editar
                      </Button>

                      <Popover>
                        <PopoverTrigger>
                          <Button ml={5} colorScheme="red">Eliminar</Button>
                        </PopoverTrigger>
                        <Portal>
                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverBody>
                              <Button width={'100%'} colorScheme='blue' onClick={() => {
                                deleteGameContent(c)
                              }}>
                                Confirmar Eliminar
                              </Button>
                            </PopoverBody>
                          </PopoverContent>
                        </Portal>
                      </Popover>

                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </ChakraTable>
          ) : (
            <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
              <Text>El juego no tiene contenido cargado</Text>
            </Box>
          )
        )}
      </VStack>
    </Center>
  )
}

export default GamesContent
