import CreateCredential from "./Credentials/CreateCredential"
import EditCredential from "./Credentials/EditCredential"

interface IProps {
  mode: 'create' | 'edit'
}

const ManageCredentials = ({ mode }: IProps) => {
  if (mode === 'create') {
    return (
      <CreateCredential />
    )
  } else {
    return (
      <EditCredential />
    )
  }
}

export default ManageCredentials
