import { FC, useState } from 'react'
import { Box, Center, Heading } from '@chakra-ui/react'
import Pagination from '@olagg/ui-kit/Pagination'
import UsersFilter from '../components/Filters/UsersFilter'
import UsersTable from '../components/Tables/UsersTable'
import { IUseUsers } from '@olagg/api-hooks/interfaces/IUseUsers'
import { useSearchParams } from 'react-router-dom'
import { useAdminUsers } from '@olagg/api-hooks'

const Users: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [filter, setFilter] = useState<IUseUsers>({
    country: searchParams.get('country') || '',
    query: searchParams.get('query') || '',
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 100)
  })

  const { country, query, referralCountSort, page, limit } = filter
  const {
    users,
    count,
    error,
    isLoading: isLoadingUsers
  } = useAdminUsers({
    query,
    country,
    referralCountSort,
    page,
    limit
  })

  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  return (
    <>
      <Box color="white" flexDirection="column">
        <Heading textAlign="center" my={2}>
          Usuarios
        </Heading>

        <Center display="block" px={8}>
          {/* FILTER */}
          <UsersFilter
            filter={filter}
            onFilter={onFilter}
          />
          {/* TABLE */}
          {!isLoadingUsers &&
            <Box overflow="auto" mt={4} w="75vw" h="60vh">
              <UsersTable
                users={users}
                filter={filter}
                onFilter={onFilter}
                isLoading={isLoadingUsers}
                error={!!error}
              />
            </Box>
          }
          <Pagination
            params={{ limit, page, count }}
            onClick={page =>
              onFilter({
                name: 'page',
                value: page + 1
              })
            }
            onLimitChange={val =>
              onFilter({
                name: 'limit',
                value: val
              })
            }
            hideLimitInput
          />
        </Center>
      </Box>
    </>
  )
}

export default Users
