import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Select
} from '@chakra-ui/react'
import { useAdminGames, useGames } from '@olagg/api-hooks'
import { IUseScholarships } from '@olagg/api-hooks/interfaces/IUseScholarships'
import type { ApplicationStatus, Game } from '@olagg/db-types'
import { BsSearch } from 'react-icons/bs'
import { countryList } from '../../consts/countryList'
import Option from '../Option'

interface IProps {
  onFilter: (value: any) => void
  filter: IUseScholarships
}

const ScholarshipsFilter = ({ onFilter, filter }: IProps) => {
  const { games } = useAdminGames({ page: 1, limit: 1000 })

  const { slug, status, query, country, dateSort } = filter
  return (
    <Flex w="100%">
      <Select
        w="25%"
        name="slug"
        value={slug || ''}
        onChange={e => onFilter(e)}
      >
        <Option value="">Juego</Option>
        {games?.map(({ slug, title }, index) => (
          <Option key={index} value={slug}>
            {title}
          </Option>
        ))}
      </Select>
      <Select
        w="25%"
        ml={5}
        name="status"
        value={status}
        onChange={e => onFilter(e)}
      >
        <Option value="">Estado</Option>
        <Option value="PENDING">Pendiente</Option>
        <Option value="APPROVED">Aprobada</Option>
        <Option value="REJECTED">Rechazada</Option>
        <Option value="SUSPENDED">Suspendida</Option>
      </Select>
      <Select
        w="25%"
        ml={5}
        name="country"
        value={country}
        onChange={e => onFilter(e)}
      >
        <Option value="">País</Option>
        {countryList?.map(country => (
          <Option key={country} value={country}>
            {country}
          </Option>
        ))}
      </Select>
      <Select
        w="25%"
        ml={5}
        name="dateSort"
        value={dateSort}
        onChange={e => onFilter(e)}
      >
        <Option value="desc">Fecha Descendiente</Option>
        <Option value="asc">Fecha Ascendiente</Option>
      </Select>
      <InputGroup mx={5} pr={5} w="60%">
        <Input
          placeholder="Usuario, email"
          name="query"
          value={query}
          onChange={e => onFilter(e)}
        />
        <InputRightElement
          mr={5}
          children={<BsSearch color="white" />}
        />
      </InputGroup>
    </Flex>
  )
}

export default ScholarshipsFilter
