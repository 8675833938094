import { Box, Button, Heading, Text } from '@chakra-ui/react'
import { FC } from 'react'

interface IProps {
  errors: string[][]
  clearValues: () => void
}
const Error: FC<IProps> = ({ errors, clearValues }) => {
  return (
    <>
      <Box
        mt={2}
        p={2}
        border="2px"
        borderColor="red"
        borderRadius="10"
        w="full"
        maxH="500px"
        overflowY="auto"
      >
        <Heading as="h4" size="md" mb={2}>
          Error subiendo archivo:
        </Heading>
        {errors?.map(error =>
          error?.map((e, index) =>
            e.includes('<a') ? (
              <div
                color="blue"
                dangerouslySetInnerHTML={{
                  __html: e
                }}
              ></div>
            ) : (
              <Text key={index} mt={1}>
                {e}
              </Text>
            )
          )
        )}
      </Box>
      <Button
        mt={2}
        variant="outlinedGradient"
        color="olaggPurple.100"
        onClick={clearValues}
      >
        Ok
      </Button>
    </>
  )
}

export default Error
