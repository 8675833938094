import React from 'react';
import { Box, Button, Flex, Input } from '@chakra-ui/react';
import { ChakraStylesConfig, Select } from "chakra-react-select"
import { useNavigate } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { ROUTES } from '../../consts/routes';
import { useAdminUsers } from '@olagg/api-hooks';


const chakraStyles: ChakraStylesConfig = {
  input: (provided, _) => ({
    ...provided,
    width: "250px",
  }),
  container: (provided, _) => ({
    ...provided,
    marginRight: "20px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    background: state.isFocused ? "blue.100" : provided.background,
    p: 0,
    w: "40px",
  }),
  option: (provided, _) => ({
    ...provided,
    color: "black",
  }),
};

type IFilter = {
  onFilter: ({ name, value }: { name: string, value: string }) => void;
}

const NotificationsFilter = ({ onFilter }: IFilter) => {
  const [userFilter, setUserFilter] = React.useState<string>();
  const navigate = useNavigate();
  const { users } = useAdminUsers({ query: userFilter, page: 1, limit: 10 });

  const handleOnChange = (e: any) => {
    const { name, value } = e.target;
    onFilter({ name, value })
  }

  const handleUserFilter = (e: any) => onFilter({ name: 'userId', value: e?.value })

  return (
    <Flex>
      <Flex justifyContent={'flex'} w={'100%'}>
        <Input
          id="title"
          name='title'
          onChange={handleOnChange}
          placeholder="Buscar por título"
          mr={4}
          w={'250px'}
        />

        <Select
          useBasicStyles
          id="userId"
          isClearable={true}
          chakraStyles={chakraStyles}
          colorScheme="purple"
          tagVariant={'outline'}
          onChange={handleUserFilter}
          placeholder="Buscar por usuario"
          options={users.map(user => ({ value: user.id, label: user.email, colorScheme: 'purple' }))}
          onInputChange={setUserFilter}
          inputValue={userFilter}
        />
      </Flex>
      <Flex justifyContent={'flex-end'} w={'100%'} alignItems={'center'}>
        <Button
          display='flex'
          alignSelf='self-end'
          variant="outlinedGradient"
          // leftIcon={<FaPlus />}
          onClick={() => navigate(ROUTES.SEGMENTS.all)}
          mr={2}
        >
          Ver segmentos
        </Button>
      </Flex>
      <Flex justifyContent={'flex-end'} w={'100%'} alignItems={'center'}>
        <Button
          display='flex'
          alignSelf='self-end'
          variant="outlinedGradient"
          leftIcon={<FaPlus />}
          onClick={() => navigate(ROUTES.NOTIFICATIONS.create)}
          mr={2}
        >
          Crear notificación
        </Button>
      </Flex>
    </Flex>
  )
}

export default NotificationsFilter;
