export const Ball = ({
  height = '21px',
  width = '20px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.79175 18.4167C8.18478 18.4167 6.61389 17.9402 5.27774 17.0474C3.94159 16.1546 2.90019 14.8856 2.28523 13.401C1.67027 11.9163 1.50937 10.2827 1.82287 8.70658C2.13638 7.13049 2.91021 5.68275 4.04651 4.54645C5.18281 3.41015 6.63055 2.63632 8.20664 2.32281C9.78274 2.00931 11.4164 2.17021 12.9011 2.78517C14.3857 3.40013 15.6547 4.44153 16.5474 5.77768C17.4402 7.11383 17.9168 8.68472 17.9168 10.2917C17.9145 12.4459 17.0578 14.5112 15.5346 16.0345C14.0113 17.5578 11.946 18.4145 9.79175 18.4167ZM9.79175 3.41669C8.43201 3.41669 7.10279 3.8199 5.97221 4.57534C4.84162 5.33077 3.96043 6.4045 3.44008 7.66074C2.91973 8.91698 2.78358 10.2993 3.04885 11.6329C3.31413 12.9666 3.96891 14.1916 4.93039 15.1531C5.89188 16.1145 7.11689 16.7693 8.45051 17.0346C9.78413 17.2999 11.1665 17.1637 12.4227 16.6434C13.6789 16.123 14.7527 15.2418 15.5081 14.1112C16.2635 12.9806 16.6668 11.6514 16.6668 10.2917C16.6645 8.46901 15.9395 6.7216 14.6507 5.43277C13.3618 4.14393 11.6144 3.4189 9.79175 3.41669Z"
        fill={color}
      />
      <path
        d="M4.45006 13.2417C3.77224 13.2431 3.09712 13.1563 2.44173 12.9833C2.36173 12.9638 2.28639 12.9287 2.22001 12.88C2.15364 12.8313 2.09754 12.7699 2.05495 12.6994C2.01236 12.629 1.98411 12.5508 1.97183 12.4694C1.95955 12.3879 1.96348 12.3049 1.98339 12.225C2.02654 12.0662 2.12983 11.9304 2.27138 11.8464C2.41293 11.7624 2.58163 11.7368 2.74173 11.775C3.30158 11.9055 3.87527 11.967 4.45006 11.9583C6.27275 11.9561 8.02015 11.2311 9.30898 9.94227C10.5978 8.65343 11.3229 6.90603 11.3251 5.08334C11.3279 4.43443 11.2352 3.78862 11.0501 3.16668C11.0287 3.08515 11.0236 3.00021 11.0351 2.91672C11.0466 2.83323 11.0744 2.75282 11.117 2.68009C11.1596 2.60735 11.216 2.5437 11.2832 2.49279C11.3503 2.44187 11.4269 2.40468 11.5084 2.38334C11.673 2.34025 11.8481 2.36432 11.995 2.45028C12.1419 2.53623 12.2486 2.67702 12.2917 2.84168C12.5098 3.58024 12.6193 4.3466 12.6167 5.11668C12.6156 6.18687 12.4034 7.24633 11.992 8.23432C11.5807 9.22232 10.9785 10.1194 10.2198 10.8742C9.46113 11.629 8.56095 12.2267 7.57086 12.6329C6.58077 13.0392 5.52025 13.2461 4.45006 13.2417Z"
        fill={color}
      />
      <path
        d="M10.0834 18.4083C9.93502 18.4083 9.7915 18.3554 9.67852 18.2592C9.56554 18.163 9.49047 18.0298 9.46673 17.8833C9.40935 17.5531 9.38146 17.2185 9.3834 16.8833C9.3856 15.1513 10.0746 13.4908 11.2994 12.266C12.5242 11.0412 14.1847 10.3522 15.9167 10.35C16.4106 10.3454 16.9032 10.4014 17.3834 10.5167C17.4638 10.5343 17.5399 10.5678 17.6071 10.6153C17.6744 10.6628 17.7314 10.7233 17.7749 10.7932C17.8184 10.8631 17.8475 10.941 17.8604 11.0223C17.8733 11.1037 17.8698 11.1867 17.8501 11.2667C17.8124 11.4279 17.7123 11.5677 17.5716 11.6552C17.431 11.7427 17.2614 11.7708 17.1001 11.7333C16.7123 11.6419 16.3151 11.5971 15.9167 11.6C15.2222 11.5989 14.5343 11.7347 13.8923 11.9998C13.2504 12.2648 12.6669 12.6538 12.1755 13.1445C11.684 13.6352 11.2941 14.218 11.028 14.8596C10.762 15.5011 10.6251 16.1888 10.6251 16.8833C10.6263 17.1486 10.6485 17.4133 10.6917 17.675C10.7185 17.8383 10.6803 18.0056 10.5853 18.1411C10.4903 18.2766 10.346 18.3695 10.1834 18.4L10.0834 18.4083Z"
        fill={color}
      />
      <path
        d="M15.0916 16.2167C14.9266 16.2131 14.769 16.1477 14.65 16.0333L4.04997 5.43335C3.93293 5.31616 3.86719 5.15731 3.86719 4.99168C3.86719 4.82606 3.93293 4.6672 4.04997 4.55002C4.10743 4.49105 4.17611 4.44419 4.25196 4.41219C4.32782 4.38019 4.40931 4.36371 4.49164 4.36371C4.57397 4.36371 4.65546 4.38019 4.73131 4.41219C4.80717 4.44419 4.87585 4.49105 4.9333 4.55002L15.5333 15.15C15.6503 15.2672 15.7161 15.4261 15.7161 15.5917C15.7161 15.7573 15.6503 15.9162 15.5333 16.0333C15.4143 16.1477 15.2566 16.2131 15.0916 16.2167Z"
        fill={color}
      />
    </svg>
  )
}

export default Ball
