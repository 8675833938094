import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  fetcher,
  ScholarshipsEndpoints,
  useAdminGames,
} from '@olagg/api-hooks'
import useAdminScholarships from '@olagg/api-hooks/queries/useAdminScholarships'
import {
  CreateScholarshipCredentialsInput,
  createScholarshipCredentialsSchema,
  credentialInput,
  CredentialRequired
} from '@olagg/validation-schemas'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import CheckboxesScholarship from '../../components/Checkboxes/CheckboxesScholarship'
import DisableCredentialsModal from '../../components/Modals/DisableCredentialsModal'
import ReassignModal from '../../components/Modals/ReassignModal'
import RevokeModal from '../../components/Modals/RevokeModal'
import OlaggForm from '../../components/Forms/OlaggForm'
import { CredentialInput } from '@olagg/validation-schemas'
import { yupResolver } from '@hookform/resolvers/yup'
import OlaggFormSelect from '../../components/Forms/OlaggFormSelect'
import OlaGGFormInput from '../../components/Forms/OlaggFormInput'
import ReassignCredential from './ReassignCredential'
import { GameCredentials, ScholarshipApplication } from '@olagg/db-types'
interface IProps {
  credential: GameCredentials,
  onSubmit: (data: any) => void,
  isEditable: boolean,
  scholarship?: ScholarshipApplication,
}

const CredentialsForm = (data: IProps) => {
  const { credential, onSubmit, isEditable, scholarship } = data
  const { games } = useAdminGames({ page: 1, limit: 1000 })
  const [userRequired, setUserRequired] = useState<Array<keyof GameCredentials>>(data.credential.userRequired || [])
  const [notShow, setNotShow] = useState<Array<keyof GameCredentials>>(data.credential.notShow || [])
  const [_discordId, setDiscordId] = useState<string>()

  const handleSubmit = (data: CredentialInput) => {
    const payload = {
      ...data,
      game: null,
      user: null,
      gameId: data.gameId.value,
      blockchain: data.blockchain.value,
      userRequired,
      notShow,
      discordId: _discordId
    }
    onSubmit(payload)
  }

  const useFormMethods = useForm<CredentialInput>({
    resolver: yupResolver(credentialInput),
    mode: 'onChange',
    defaultValues: {
      ...credential,
      username: credential.username || '',
      password: credential.password || '',
      email: credential.email || '',
      walletAddress: credential.walletAddress || '',
      seedPhrase: credential.seedPhrase || '',
      nftIdentifier: credential.nftIdentifier || '',
      userNotes: credential.userNotes || '',
      // @ts-ignore
      userRequired: (credential.userRequired || []),
      adminNotes: credential.adminNotes || '',
      gameId: {
        value: credential.game?.id,
        label: credential.game?.title
      },
      blockchain: {
        value: credential.blockchain,
        label: credential.blockchain
      },
    }
  })

  const handleNotShow = (value: keyof GameCredentials) => {
    if (notShow.includes(value)) {
      return setNotShow(prev => prev.filter(e => e !== value))
    }
    setNotShow(prev => [...prev, value])

    if (userRequired.includes(value)) {
      setUserRequired(prev => prev.filter(e => e !== value)) //remove from userRequired
    }

  }

  const isRequired = (value: keyof GameCredentials) =>
    !userRequired.includes(value) && !notShow.includes(value)

  const isDisabled = (value: keyof GameCredentials) =>
    userRequired.includes(value) || notShow.includes(value)

  const handleRequiredChange = (
    value: keyof GameCredentials
  ) => {
    if (userRequired.includes(value)) {
      return setUserRequired(prev =>
        prev.filter(e => e !== value)
      )
    }

    setUserRequired(prev => [...prev, value])

    if (notShow.includes(value)) {
      setNotShow(prev => prev.filter(e => e !== value))
    }
  }

  return (
    <OlaggForm
      useFormMethods={useFormMethods}
      onSubmit={handleSubmit}
      style={{ color: 'white' }}
    >
      <Flex mt={5} justifyContent={'space-between'}>
        <Flex>
          <fieldset disabled={!isEditable}>
            <VStack
              w={{ base: '70vw', lg: '50vw' }}
              mt={2}
              mb={10}
            >
              <Box w="100%">
                <OlaggFormSelect
                  controlName={'gameId'}
                  label={'Juego'}
                  staticOptions={games?.map(game => ({
                    value: game.id,
                    label: game.title
                  }))}
                  required={true}
                />


                <OlaGGFormInput
                  labelAside={
                    <CheckboxesScholarship
                      name='username'
                      notShow={notShow}
                      userRequired={userRequired}
                      handleRequiredChange={handleRequiredChange}
                      handleNotShow={handleNotShow}
                    />
                  }
                  disabled={isDisabled('username')}
                  controlName={'username'}
                  label={'Username'}
                  required={isRequired('username')}
                />

                <OlaGGFormInput
                  labelAside={
                    <CheckboxesScholarship
                      name='email'
                      notShow={notShow}
                      userRequired={userRequired}
                      handleNotShow={handleNotShow}
                      handleRequiredChange={handleRequiredChange}
                    />
                  }
                  controlName={'email'}
                  label={'Email'}
                  required={isRequired('email')}
                  disabled={isDisabled('email')}
                />

                <OlaGGFormInput
                  labelAside={
                    <CheckboxesScholarship
                      name='password'
                      notShow={notShow}
                      userRequired={userRequired}
                      handleNotShow={handleNotShow}
                      handleRequiredChange={handleRequiredChange}
                    />
                  }
                  controlName={'password'}
                  label={'Password'}
                  required={isRequired('password')}
                  disabled={isDisabled('password')}
                />

                <OlaGGFormInput
                  labelAside={
                    <CheckboxesScholarship
                      name='walletAddress'
                      notShow={notShow}
                      userRequired={userRequired}
                      handleNotShow={handleNotShow}
                      handleRequiredChange={handleRequiredChange}
                    />
                  }
                  controlName={'walletAddress'}
                  label={'Wallet Address'}
                  required={isRequired('walletAddress')}
                  disabled={isDisabled('walletAddress')}
                />

                <OlaGGFormInput
                  inputType='textarea'
                  labelAside={
                    <CheckboxesScholarship
                      name='nftIdentifier'
                      notShow={notShow}
                      userRequired={userRequired}
                      handleNotShow={handleNotShow}
                      handleRequiredChange={handleRequiredChange}
                    />
                  }
                  controlName={'nftIdentifier'}
                  label={'Identificador NFT'}
                  required={isRequired('nftIdentifier')}
                  disabled={isDisabled('nftIdentifier')}
                />

                <OlaggFormSelect
                  controlName={'blockchain'}
                  label={'Blockchain'}
                  staticOptions={[
                    { value: 'ETHEREUM', label: 'Ethereum' },
                    { value: 'RONIN', label: 'Ronin' },
                    { value: 'POLYGON', label: 'Polygon' },
                    { value: 'BINANCE', label: 'Binance' },
                    { value: 'SOLANA', label: 'Solana' },
                    { value: 'AVALANCHE', label: 'Avalanche' },
                    { value: 'XPLA', label: 'XPLA' }
                  ]}
                  required={true}
                />

                <OlaGGFormInput
                  label='Es cuenta personal?'
                  controlName='personal'
                  inputType='switch'
                  chakraStyles={{ colorScheme: 'red' }}
                />

                <OlaGGFormInput
                  inputType='textarea'
                  controlName={'adminNotes'}
                  label={'Notas del administrador'}
                />

                <OlaGGFormInput
                  labelAside={
                    <CheckboxesScholarship
                      name='userNotes'
                      notShow={notShow}
                      userRequired={userRequired}
                      handleNotShow={handleNotShow}
                      handleRequiredChange={handleRequiredChange}
                    />
                  }
                  inputType='textarea'
                  controlName={'userNotes'}
                  label={'Notas del usuario'}
                  required={isRequired('userNotes')}
                  disabled={isDisabled('userNotes')}
                />

                <OlaGGFormInput
                  label='Lista para jugar?'
                  controlName='built'
                  inputType='switch'
                  chakraStyles={{ colorScheme: 'red' }}
                />

                {!scholarship && (
                  <FormControl>
                    <Flex justify="space-between" mt={5}>
                      <FormLabel htmlFor="discordId">
                        Discord ID al que asignar ésta beca
                        (opcional)
                      </FormLabel>
                    </Flex>
                    <Input
                      id="discordId"
                      placeholder=""
                      name='_discordId'
                      onChange={e => {
                        if (e.target.value.trim().length) {
                          setDiscordId(e.target.value.trim())
                        } else {
                          setDiscordId(undefined)
                        }
                      }}
                    />
                  </FormControl>
                )}

                {isEditable && (
                  <Flex w="full" style={{ marginTop: '20px' }}>
                    <Button
                      variant="filledGradient"
                      type="submit"
                      flex="1"
                    >
                      Guardar cambios
                    </Button>
                  </Flex>
                )}
              </Box>
            </VStack>
          </fieldset>
        </Flex>
      </Flex>
    </OlaggForm>
  )
}

export default CredentialsForm
