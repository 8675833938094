import {
  Button,
  Center,
  Spinner,
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { IUseQuests } from '@olagg/api-hooks/interfaces/IUseQuests'
import type {
  Quest
} from '@olagg/db-types'
import {
  QUEST_TYPES
} from '@olagg/db-types'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../consts/routes'
import { slugify } from '../../utils'

interface IProps {
  quests?: (Quest)[]
  onFilter: (value: any) => void
  filter: IUseQuests
  isLoading: boolean
  error: boolean
}

const QuestsTable = ({
  quests = [],
  isLoading,
  error
}: IProps) => {
  if (!quests.length) {
    if (isLoading)
      return (
        <Center h="full">
          <Spinner />
        </Center>
      )
    if (error)
      return <Center h="full">Se ha producido un error</Center>

    return <Center h="full">No hay resultados</Center>
  }

  return (
    <ChakraTable variant="striped" colorScheme="olaggPurple">
      <Thead position='sticky' top='0' bg='gray.800' zIndex='100'>
        <Tr>
          <Th color="white">ID</Th>
          <Th color="white">Título</Th>
          <Th color="white">Score</Th>
          <Th color="white">URL</Th>
          <Th color="white">Acción</Th>
        </Tr>
      </Thead>
      <Tbody>
        {quests?.map(quest => (
          <Tr key={quest.id}>
            <Td>{quest.id}</Td>
            <Td>{quest?.title}</Td>
            <Td>{quest?.score}</Td>
            <Td>{`/quests/${slugify(quest?.title || '')}`}</Td>
            <Td>
              <Link to={`${ROUTES.QUESTS.all}/${quest.id}`}>
                <Button colorScheme="green" ml='5px' size={'sm'} py='5px' mt='5px'>Ver</Button>
              </Link>
              {quest?.type == QUEST_TYPES.ADMIN_COMPLETION && (
                <Link to={`/quest/batch_complete/${quest.id}`}>
                  <Button colorScheme="green" ml='5px' size={'sm'} py='5px' mt='5px'>Batch complete</Button>
                </Link>
              )}
            </Td>
          </Tr>
        ))}
      </Tbody>
    </ChakraTable>
  )
}

export default QuestsTable
