import { ReactNode } from 'react'

interface IProps {
  value: string | number
  children: ReactNode
  style?: React.CSSProperties
}

const Option = ({ value, children, style }: IProps) => (
  <option value={value} style={{ backgroundColor: '#1a202c', ...style }}>
    {children}
  </option>
)

export default Option
