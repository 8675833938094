import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Select
} from '@chakra-ui/react'
import { IUseUsers } from '@olagg/api-hooks/interfaces/IUseUsers'
import { BsSearch } from 'react-icons/bs'
import { countryList } from '../../consts/countryList'
import Option from '../Option'

interface IProps {
  //TODO: type below properly
  onFilter: (value: any) => void
  filter: IUseUsers
}

const UsersFilter = ({ onFilter, filter }: IProps) => {
  const { country, query } = filter

  const handleChange = (e: any) => onFilter(e.target)

  return (
    <Flex gap={5}>
      <Select
        w="200px"
        name="country"
        value={country || ''}
        onChange={handleChange}
      >
        <Option value="">País</Option>
        {countryList?.map(country => (
          <Option key={country} value={country}>
            {country}
          </Option>
        ))}
      </Select>
      <InputGroup w="400px">
        <Input
          placeholder="Buscar por nombre, email o id"
          name="query"
          value={query || ''}
          onChange={handleChange}
        />
        <InputRightElement
          children={<BsSearch color="white" />}
        />
      </InputGroup>
    </Flex>
  )
}

export default UsersFilter
