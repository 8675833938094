import { ApplicationStatus } from '@olagg/db-types'
import { ReactElement } from 'react'
import {
  FaList,
  FaRegFile,
  FaRocket,
  FaUser,
  FaGem,
  FaHandHolding,
  FaBell,
  FaUsers,
  FaGift,
  FaAward,
  FaBitcoin,
} from 'react-icons/fa'
import { ROUTES } from '../../consts/routes'

export interface ISidebarRoute {
  path: string
  name: string
  icon: ReactElement
}

export const routes: ISidebarRoute[] = [
  {
    path: ROUTES.GAMES.all,
    name: 'Juegos',
    icon: <FaRocket color="white" />
  },
  {
    path: `${ROUTES.SCHOLARSHIP_APPLICATIONS}?status=${ApplicationStatus.PENDING}`,
    name: 'Solicitudes',
    icon: <FaRegFile color="white" />
  },
  {
    path: ROUTES.CREDENTIALS.all,
    name: 'Becas',
    icon: <FaList color="white" />
  },
  {
    path: ROUTES.USERS.all,
    name: 'Usuarios',
    icon: <FaUser color="white" />
  },
  {
    path: ROUTES.OWNERS.all,
    name: 'Owners',
    icon: <FaHandHolding color="white" />
  },
  {
    path: ROUTES.QUESTS.all,
    name: 'Quests',
    icon: <FaGem color="white" />
  },
  {
    path: ROUTES.NOTIFICATIONS.all,
    name: 'Notificaciones',
    icon: <FaBell color="white" />
  },
  {
    path: ROUTES.USER_CONTENT.all,
    name: 'UGC',
    icon: <FaUsers color="white" />
  },
  {
    path: ROUTES.MARKETPLACE.all,
    name: 'Tienda',
    icon: <FaGift color="white" />
  },
  {
    path: ROUTES.TOURNAMENTS.all,
    name: 'Torneos',
    icon: <FaAward color="white" />
  },
  {
    path: ROUTES.TOKENS.all,
    name: 'Tokens',
    icon: <FaBitcoin color="white" />
  },
]
