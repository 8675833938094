import type { InferType } from 'yup';
import * as Yup from 'yup';

export const formOwnerSchema = Yup.object({
  name: Yup
    .string()
    .required('El nombre es requerido')
    .min(3, 'El nombre debe ser de mínimo 3 caracteres')
    .max(100, 'El nombre debe ser de máximo 64 caracteres'),
  site: Yup.string().nullable(),
  gameId: Yup
    .object({
      value: Yup
        .number()
        .nullable()
        .optional(),
      label: Yup
        .string()
        .nullable()
        .optional(),
    })
    .optional(),
  discordId: Yup.string().nullable(),
  discordUsername: Yup.string().nullable(),
  twitterId: Yup.string().nullable(),
  twitterUsername: Yup.string().nullable(),
  order: Yup
    .object({
      value: Yup
        .number()
        .required('Introduce el juego al que hace referencia el torneo'),
      label: Yup
        .string()
        .required('Opción inválida (el juego no puede estar en blanco)'),
    })
    .optional(),
})


export type OwnerForm = InferType<typeof formOwnerSchema>
