export const Camera = ({
  height = '24px',
  width = '24px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.0001 10.6667C13.1094 10.6667 10.6667 13.1093 10.6667 16C10.6667 18.8907 13.1094 21.3333 16.0001 21.3333C18.8907 21.3333 21.3334 18.8907 21.3334 16C21.3334 13.1093 18.8907 10.6667 16.0001 10.6667ZM16.0001 18.6667C14.5801 18.6667 13.3334 17.42 13.3334 16C13.3334 14.58 14.5801 13.3333 16.0001 13.3333C17.4201 13.3333 18.6667 14.58 18.6667 16C18.6667 17.42 17.4201 18.6667 16.0001 18.6667Z"
        fill={color}
      />
      <path
        d="M26.6667 6.66667H23.2187L19.6094 3.05734C19.4858 2.93328 19.3389 2.8349 19.1771 2.76785C19.0153 2.70081 18.8419 2.66642 18.6667 2.66667H13.3334C13.1583 2.66642 12.9848 2.70081 12.8231 2.76785C12.6613 2.8349 12.5144 2.93328 12.3907 3.05734L8.78141 6.66667H5.33341C3.86275 6.66667 2.66675 7.86267 2.66675 9.33334V24C2.66675 25.4707 3.86275 26.6667 5.33341 26.6667H26.6667C28.1374 26.6667 29.3334 25.4707 29.3334 24V9.33334C29.3334 7.86267 28.1374 6.66667 26.6667 6.66667ZM5.33341 24V9.33334H9.33342C9.68808 9.33334 10.0267 9.19334 10.2761 8.94267L13.8854 5.33334H18.1147L21.7241 8.94267C21.8477 9.06673 21.9946 9.16511 22.1564 9.23216C22.3182 9.29921 22.4916 9.33359 22.6667 9.33334H26.6667L26.6694 24H5.33341Z"
        fill={color}
      />
    </svg>
  )
}
export default Camera
