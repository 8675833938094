export const Edit = ({
  height = '16px',
  width = '16px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 15.013L8.413 14.998L18.045 5.45799C18.423 5.07999 18.631 4.57799 18.631 4.04399C18.631 3.50999 18.423 3.00799 18.045 2.62999L16.459 1.04399C15.703 0.287994 14.384 0.291994 13.634 1.04099L4 10.583V15.013ZM15.045 2.45799L16.634 4.04099L15.037 5.62299L13.451 4.03799L15.045 2.45799ZM6 11.417L12.03 5.44399L13.616 7.02999L7.587 13.001L6 13.006V11.417Z"
        fill={color}
      />
      <path
        d="M2 19H16C17.103 19 18 18.103 18 17V8.33199L16 10.332V17H5.158C5.132 17 5.105 17.01 5.079 17.01C5.046 17.01 5.013 17.001 4.979 17H2V2.99999H8.847L10.847 0.999994H2C0.897 0.999994 0 1.89699 0 2.99999V17C0 18.103 0.897 19 2 19Z"
        fill={color}
      />
    </svg>
  )
}

export default Edit
