import type { InferType } from 'yup';
import * as Yup from 'yup';

export const formNotificationSchema = Yup.object({
    title: Yup
        .string()
        .required('El título es requerido')
        .min(3, 'El título debe ser de mínimo 3 caracteres')
        .max(100, 'El título debe ser de máximo 64 caracteres'),
    body: Yup
        .string()
        .required('El cuerpo es requerid')
        .min(3, 'El cuerpo debe ser de mínimo 3 caracteres')
        .max(100, 'El cuerpo debe ser de máximo 64 caracteres'),
    link: Yup.string().optional(),
    image: Yup.string().optional(),
    segment: Yup
        .object({
            value: Yup
                .string()
                .required('Introduce el segmento de notificación'),
            label: Yup
                .string()
                .required('Opcion inválida (el segmento de notificación no puede estar en blanco)'),
        })
        .required('Introduce el segmento de notificación'),
    type: Yup
        .object({
            value: Yup
                .string()
                .required('Introduce el tipo de notificación'),
            label: Yup
                .string()
                .required('Opcion inválida (el tipo de notificación no puede estar en blanco)'),
        })
        .required('Introduce el tipo de notificación'),
    discordId: Yup.string().optional(),
    discordUsername: Yup.string().optional()
})

export type NotificationFormData = InferType<typeof formNotificationSchema>