import { Box, Checkbox } from '@chakra-ui/react'
import { GameCredentials } from '@olagg/db-types'
import { useForm } from 'react-hook-form'

interface IProps {
  name: keyof GameCredentials,
  handleRequiredChange: (name: keyof GameCredentials) => void
  handleNotShow: (name: keyof GameCredentials) => void
  notShow: Array<keyof GameCredentials>,
  userRequired: Array<keyof GameCredentials>,
}

const CheckboxesScholarship = ({
  name,
  handleRequiredChange,
  notShow,
  handleNotShow,
  userRequired
}: IProps) => {

  return <Box>
    <Checkbox
      mr={5}
      id={`notShow[${name}]`}
      name={`notShow[${name}]`}
      isChecked={notShow.includes(name)}
      isDisabled={false}
      required={false}
      onChange={() => { handleNotShow(name) }}>
      Ocultar
    </Checkbox>
    <Checkbox
      id={`userRequired[${name}]`}
      name={`userRequired[${name}]`}
      isChecked={userRequired.includes(name)}
      isDisabled={false}
      required={false}
      onChange={() => handleRequiredChange(name)}>
      Completado por el usuario
    </Checkbox>
  </Box>
}


export default CheckboxesScholarship
