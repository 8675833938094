import { Box, Flex } from '@chakra-ui/react'
import { KYC_STATUS } from '../enums/KYC_STATUS'
import { Camera } from '../svg'
import VerificationIcon from '../VerificationIcon'
import s from './styles.module.css'

interface IProfileHexagon {
  w?: string
  img: string
  showEdit?: boolean
  showBadge?: boolean
  badgeWidth?: string
  verificationStatus?: KYC_STATUS
}

export const ProfileHexagon = ({
  img,
  w = '100px',
  showEdit,
  showBadge,
  verificationStatus,
  badgeWidth = '100%'
}: IProfileHexagon) => {
  return (
    <Flex
      w={w}
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      {showBadge && (
        <Box
          zIndex="9"
          position="absolute"
          bottom="3px"
          right="-3px"
          width="35%"
        >
          {/* <VerificationIcon
            width="100%"
            status={verificationStatus}
          /> */}
        </Box>
      )}
      <svg
        id={showEdit ? s.svg : ''}
        viewBox="0 0 100 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
      >
        <defs>
          <pattern
            id={`img`}
            patternUnits="userSpaceOnUse"
            width="100"
            height="100"
          >
            <image
              id={`img`}
              xlinkHref={img}
              x="-25"
              width="150"
              height="100"
            />
          </pattern>
        </defs>
        <polygon
          id={s.hex}
          points="50 1 95 25 95 75 50 99 5 75 5 25"
          fill={`url(#img)`}
        />
        {showEdit && <Camera color="white" x="35" y="40" />}
      </svg>
    </Flex>
  )
}
