import {
  AuthEndpoints,
  fetcher,
  GameEndpoints,
  QuestOwnerEndpoints,
  QuestEndpoints,
  MarketPlaceEndpoints
} from '@olagg/api-hooks'
import type {
  DiscordAccount,
  GameCategory,
  GoogleAccount,
  TwitterAccount,
  User,
  QuestOwner,
  Quest,
  MarketPlaceItem,
  MarketPlaceStockItem
} from '@olagg/db-types'
import create from 'zustand'
import { persist } from 'zustand/middleware'
import Cookies from 'js-cookie';
import * as ctz from 'countries-and-timezones'

enum KYC_STATUS {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NOT_FOUND = 'NOT_FOUND'
}

export interface AuthState {
  loggedIn: boolean
  me:
  | (User & {
    discordAccount?: DiscordAccount | null
    googleAccount?: GoogleAccount | null
    authProviderData?: Record<string, TwitterAccount>
    metamapVerification?: {
      lastStatusReceived: KYC_STATUS | null
    } | null
  })
  | undefined
  getMe: () => Promise<any>
  check: () => void
  login: (provider: 'google' | 'discord') => void
  logout: () => void
  setMe: (data: User) => void
}

export interface OnboardingState {
  isOnboardingOpen: boolean
  openOnboarding: () => void
  closeOnboarding: () => void
  isSignedIn: boolean
  isRedirected: boolean
  providerSignedIn: string
  setIsRedirected: (status: boolean) => void
  setIsSignedIn: (status: boolean, provider: string) => void
}

export interface OwnersState {
  ownersData: QuestOwner[]
  getOwners: () => Promise<any>
  getOwnersData: () => QuestOwner[]
}

export interface QuestsState {
  questsData: Quest[]
  questsByOwnerIdData: any
  getQuestsByOwnerId: (ownerId: string) => Promise<any>
  getQuests: () => Promise<any>
  getQuestsData: () => Quest[]
}

export interface CategoriesState {
  categories: GameCategory[]
  getCategories: () => GameCategory[]
}

export interface MarketPlaceState {
  marketPlaceData: MarketPlaceItem[]
  marketPlaceUserStockData: MarketPlaceStockItem[]
  marketPlaceItemsAvailable: () => MarketPlaceItem[]
  marketPlaceItemsUnavailable: () => MarketPlaceItem[]
  marketPlaceItemsAll: () => MarketPlaceItem[]
  getMarketPlaceItems: () => Promise<MarketPlaceItem[]>
  purchaseMarketPlaceItem: (id: string) => Promise<any>
  purchaseMarketPlaceItemWithCoupon: (id: string, coupon: string) => Promise<any>
  userStockData: () => MarketPlaceStockItem[]
}

export const useCategoriesStore = create<CategoriesState>()(
  persist(
    (set, get) => ({
      categories: [],
      getCategories: () => {
        const detachedFetchCategories = () => {
          fetcher(GameEndpoints.categories)
            .then(data => {
              set({ categories: data.categories })
            })
            .catch(_e => {
              set({
                categories: []
              })
            })
        }

        detachedFetchCategories() // fetch from db
        if (get().categories) return get().categories // return from localstorage
        return []
      }
    }),
    {
      name: 'ola-categories',
      getStorage: () => window.localStorage
    }
  )
)

const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
const timezoneData = ctz.getTimezone(tz)
export const tzCountry = timezoneData.countries[0]


export const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      me: undefined,
      loggedIn: false,
      check: () => {
        fetcher<{ loggedIn: boolean }>(AuthEndpoints.check)
          .then(data => {
            set(state => ({
              ...state,
              loggedIn: data.loggedIn,
              me: data.loggedIn ? state.me : undefined
            }))
          })
          .catch(e => {
            set(state => ({
              ...state,
              loggedIn: false,
              me: undefined
            }))
          })
      },
      login: (provider: 'google' | 'discord') => {
        window.location.href = `${import.meta.env.VITE_API_URL || 'http://localhost:4000'
          }${AuthEndpoints.login[provider].path
          }?redirect=${encodeURIComponent(window.location.origin)}`
      },
      logout: () => {
        set(state => ({
          ...state,
          loggedIn: false,
          me: undefined
        }))

        Cookies.remove('auth._token.local', {
          domain: import.meta.env.DEV ? 'localhost' : '.olagg.io'
        })
      },
      getMe: async () => {
        await fetcher<{ user: User }>(AuthEndpoints.me)
          .then(({ user }) => {
            set(state => ({
              ...state,
              me: user,
              loggedIn: user ? true : false
            }))
          })
          .catch(e => {
            set(state => ({
              ...state,
              loggedIn: false,
              me: undefined
            }))
            console.log("ENTRO AL REMOVE", e)
            Cookies.remove('auth._token.local', {
              domain: import.meta.env.DEV ? 'localhost' : '.olagg.io'
            })
          })
        return get().me ? get().me : undefined
      },
      setMe(data) {
        const updated = { ...get().me, ...data }

        set(state => ({
          ...state,
          me: updated
        }))
      }
    }),
    {
      name: 'ola-user',
      getStorage: () => window.localStorage
    }
  )
)

export const useOnboardingStore = create<OnboardingState>()(
  (set, get) => ({
    isOnboardingOpen: false,
    openOnboarding() {
      set(state => {
        return {
          ...state,
          isOnboardingOpen: true
        }
      })
    },
    closeOnboarding() {
      set(state => ({
        ...state,
        isOnboardingOpen: false
      }))
    },
    isSignedIn: false,
    isRedirected: false,
    providerSignedIn: '',
    setIsSignedIn(status: boolean, provider: string) {
      set(state => {
        return {
          ...state,
          isSignedIn: status,
          providerSignedIn: provider
        }
      })
    },
    setIsRedirected(status: boolean) {
      set(state => {
        return {
          ...state,
          isRedirected: status
        }
      })
    },
  })
)

export interface ProfileSidebarState {
  isSidebarOpen: boolean
  toggleSidebar: () => void
}

export const useProfileSidebarStore = create<ProfileSidebarState>()(
  (set, get) => ({
    isSidebarOpen: false,
    toggleSidebar() {
      set(state => {
        return {
          ...state,
          isSidebarOpen: !state.isSidebarOpen
        }
      })
    }
  })
)

export const useOwnersStore = create<OwnersState>()(
  (set, get) => ({
    ownersData: [],
    getOwners: async () => {
      return await fetcher(QuestOwnerEndpoints.all())
        .then(response => {
          set(state => ({
            ...state,
            ownersData: response
          }))
        })
        .catch(_e => {
          set(state => ({
            ...state,
            ownersData: []
          }))
        })
    },
    getOwnersData: () => {
      return get().ownersData
    }
  })
)


export const useQuestsStore = create<QuestsState>()(
  (set, get) => ({
    questsByOwnerIdData: null,
    questsData: [],
    getQuestsByOwnerId: async (ownerId: string) => {
      return await fetcher(QuestEndpoints.byOwnerId(ownerId))
    },
    getQuests: async () => {
      return await fetcher(QuestEndpoints.all())
        .then(response => {
          set(state => ({
            ...state,
            questsData: response
          }))
        })
        .catch(_e => {
          set(state => ({
            ...state,
            questsData: []
          }))
        })
    },
    getQuestsData: () => {
      return get().questsData
    }
  })
)

export const useMarketPlaceStore = create<MarketPlaceState>()(
  (set, get) => ({
    marketPlaceData: [],
    marketPlaceUserStockData: [],
    marketPlaceItemsAvailable: () => get().marketPlaceData.filter((item) => {
      let couponsStock = item?.couponsAvailable || 0
      if (item.status == 'UNAVAILABLE' && couponsStock > 0) return item
      return item.status != 'UNAVAILABLE' ? item : undefined
    }),
    marketPlaceItemsUnavailable: () => get().marketPlaceData.filter((item) => {
      let couponsStock = item?.couponsAvailable || 0
      return (item.status == 'UNAVAILABLE' && couponsStock == 0) ? item : undefined

    }),
    marketPlaceItemsAll: () => get().marketPlaceData,
    getMarketPlaceItems: async () => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetcher(MarketPlaceEndpoints.all())
          set(state => ({
            ...state,
            marketPlaceData: response,
            marketPlaceUserStockData: response.userStock
          }))
          resolve(response)
        } catch (e) {
          set(state => ({
            ...state,
            marketPlaceData: []
          }))
          reject(e)
        }
      })
    },
    purchaseMarketPlaceItem: async (id: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetcher(MarketPlaceEndpoints.purchase(id))
          resolve(response)
        } catch (e) {
          reject(e)
        }
      })
    },
    purchaseMarketPlaceItemWithCoupon: async (id: string, coupon: string) => {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await fetcher(MarketPlaceEndpoints.purchaseWithCoupon(id, coupon))
          resolve(response)
        } catch (e) {
          reject(e)
        }
      })
    },
    userStockData: () => {
      return get().marketPlaceUserStockData
    }
  })
)
