import { AdminNotificationEndpoints, useQ } from "@olagg/api-hooks";
import { Notification } from "@olagg/db-types";

const useAdminNotifications = ({
    title, page, limit, userId
}: {
    title?: string;
    page: number;
    limit: number;
    userId?: number;
}) => {
    const response = useQ<{ notifications: Notification[], meta: { count: number } }>(
        AdminNotificationEndpoints.all({ title, page, limit, userId }), {
        revalidateOnFocus: false,
    });

    return {
        ...response,
        notifications: response.data?.notifications || [],
        count: response?.data?.meta?.count || 0,
    };
}

export default useAdminNotifications;
