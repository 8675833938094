import OlaggForm from "./OlaggForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formNotificationSchema, NotificationFormData } from "@olagg/validation-schemas/notification.schema";
import OlaggFormInput from "./OlaggFormInput";
import OlaggFormSelect from "./OlaggFormSelect";
import useAdminSegments from "../../pages/Notification/useAdminSegments";
import { Button, Center, useToast, VStack } from "@chakra-ui/react";
import { AdminNotificationEndpoints, fetcher } from "@olagg/api-hooks";
import { useNavigate } from "react-router-dom";
import { Notification, NotificationType } from "@olagg/db-types";

const NotificationForm = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const {segments, setName: setSegmentName} = useAdminSegments({
        page: 1,
        limit: 1000
    })

    const useFormMethods = useForm<NotificationFormData>({
        resolver: yupResolver(formNotificationSchema),
        mode: 'onChange'
    });

    const onSuccess = (message: string) => {
        toast({
            title: message,
            status: 'success',
            duration: 5000,
            isClosable: true
        })
        navigate('/notifications')
    }

    const onError = (e: any) => {
        toast({
            title: 'Error',
            description: e.message,
            status: 'error',
            duration: 5000,
            isClosable: true
        })
    }

    const onSubmit = (data: NotificationFormData) => {
        const body: Partial<Notification>= {
            title: data.title,
            body: data.body,
            image: data.image,
            link: data.link,
            type: data.type.value as NotificationType,
            segmentId: data.segment.value
        }

        fetcher(
            AdminNotificationEndpoints.create(body)
          )
            .then(() => onSuccess('Notificacion creada correctamente'))
            .catch(onError)
    }

    const segmentOptions = segments?.map(segment => ({
        value: segment.id,
        label: `${segment.name} - ${segment.description}`
    })) || [{value: '', label: 'No hay segmentos disponibles'}]

    return(
        <OlaggForm
          useFormMethods={useFormMethods}
          onSubmit={onSubmit}
          style={{
            width: '100%'
          }}
        >
            <Center w={'full'} p={4} >
                <VStack
                w={{ base: '70vw', lg: '50vw' }}
                mt={2}
                mb={10}
                >
                    <OlaggFormInput
                        controlName={'title'}
                        label={'Título'}
                        required={true}
                    />
                    <OlaggFormInput
                        controlName={'body'}
                        label={'Cuerpo de la notificación'}
                        description={'Descripción de la notificación'}
                        required={true}
                    />
                    <OlaggFormInput
                        controlName={'image'}
                        label={'Imagen'}
                        description={'Imagen que aparecerá en la notificación'}
                    />
                    <OlaggFormInput
                        controlName={'link'}
                        label={'Link'}
                        description={'El usuario sera redirigido a este link al hacer click en la notificación'}
                    />
                    <OlaggFormSelect
                        controlName={'type'}
                        label={'Tipo'}
                        staticOptions={[
                            {value: 'PUSH', label: 'Push'},
                            {value: 'EMAIL', label: 'Email'}
                        ]}
                        required={true}
                    />
                    <OlaggFormSelect
                        controlName={'segment'}
                        label={'Segmento'}
                        description={'La notificación será enviada a todos los usuarios que pertenezcan a este segmento'}
                        staticOptions={segmentOptions}
                        required={true}
                        onInputChange={setSegmentName}
                    />

                    <Button
                        variant="filledGradient"
                        type="submit"
                        w={'full'}
                        mt={4}
                    >
                        Crear Notificación
                    </Button>
                </VStack>
            </Center>
        </OlaggForm>
    )
};
export default NotificationForm;