import { Center, VStack, Heading, Text, Flex, Switch, useToast, useDisclosure } from "@chakra-ui/react"
import { AdminCredentialsEndpoints, fetcher, ScholarshipsEndpoints } from "@olagg/api-hooks"
import useAdminScholarships from "@olagg/api-hooks/queries/useAdminScholarships"
import { GameCredentials } from "@olagg/db-types"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import CredentialsForm from "./CredentialsForm"
import ReassignCredential from "./ReassignCredential"

const EditCredential = () => {
  const params = useParams()
  const { id } = params
  const { credential, getCredential } = useAdminScholarships()
  const isActive = credential?.active || false
  const [isEditable, setIsEditable] = useState(false)
  const toast = useToast()
  const { scholarship } = credential || {}
  useEffect(() => {
    getCredential(id!)
  }, [])

  if (!credential) return null

  const onSubmit = (data: GameCredentials) => {
    fetcher(
      AdminCredentialsEndpoints.update(credential.id, data)
    ).then(() =>
      toast({
        title: 'Beca actualizada',
        colorScheme: 'olaggPink',
        status: 'success',
        description: 'La beca fue actualizada con éxito',
        position: 'bottom-right'
      })
    ).catch(e =>
      toast({
        title: 'Error',
        colorScheme: 'olaggPink',
        status: 'error',
        description: e.message,
        position: 'bottom-right'
      })
    )
  }

  return (
    <Center w={'full'}>
      <VStack color={'white'}>
        <Center flexDir="column">
          <Flex w="full" justify="space-between" align="center" my={2}>
            <Heading w={"100%"}>Editar Beca</Heading>
            {isActive && (
              <Flex
                w="full"
                justify="end"
                align="center"
                style={{ marginTop: '30px' }}
              >
                <label
                  htmlFor="enableEdition"
                  style={{ cursor: 'pointer' }}
                >
                  Habilitar edición
                </label>
                <Switch
                  id="enableEdition"
                  colorScheme="purple"
                  size="md"
                  ml={2}
                  onChange={() => setIsEditable(prev => !prev)}
                />
              </Flex>
            )}
          </Flex>
          {scholarship && <ReassignCredential credential={credential} isEditable={isEditable} isActive={isActive} />}

          {!isActive && (
            <>
              <Text fontSize="20px">
                Esta beca fue desactivada y archivada, solo se
                encuentra en modo lectura
              </Text>

              {scholarship &&
                !credential.active && (
                  <Text fontSize="16px" fontWeight={'semibold'}>
                    Esta beca fue desactivada pero no revocada
                    aún, la puedes revocar.
                  </Text>
                )}
            </>
          )}

          <CredentialsForm
            credential={credential}
            onSubmit={onSubmit}
            isEditable={isEditable}
            scholarship={scholarship!}
          />
        </Center>

      </VStack>
    </Center>
  )
}


export default EditCredential
