
import { useState } from "react";
import {AdminSegmentEndpoints, useQ } from "@olagg/api-hooks";
import { Segment } from "@olagg/db-types";

const useAdminSegments = ({page, limit}: {page:number, limit: number}) => {
    const [name, setName] = useState<string>('');
    const response = useQ<{segments: Segment[], meta: {count: number}}>(
        AdminSegmentEndpoints.all({page, limit, name}), {
        revalidateOnFocus: false,
    });

    return {
        ...response,
        setName,
        segments: response.data?.segments ?? [],
        count: response.data?.meta?.count ?? 0
    };
}

export default useAdminSegments;