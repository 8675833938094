import type {
  QuestOwner
} from '@olagg/db-types'
import { AdminOwnersEndpoints } from '../endpoints/adminEndpoints'
import { useQ } from './useQ'

export const useAdminQuestOwner = (id: string) =>{
  const response = useQ<{owner: QuestOwner}>(AdminOwnersEndpoints.find(id), {
    revalidateOnFocus: true
  })
  
  return {
    ...response,
    owner: response.data?.owner ?? null
  }
}
