import { Center, Text } from '@chakra-ui/react'
import { useAdminGame } from '@olagg/api-hooks'
import { useParams } from 'react-router-dom'
import GameForm from '../components/Forms/GameForm'

interface IProps {
  mode: 'create' | 'edit'
}

const ManageGame = ({ mode }: IProps) => {
  const params = useParams()

  if (mode === 'create') return <GameForm mode={'create'} />

  const {
    data: { game } = { game: undefined },
    error,
    isLoading
  } = useAdminGame({
    slug: params.slug!
  })

  if (game) return <GameForm mode={'edit'} game={game} />

  return (
    <Center h="80vh" color="white">
      {isLoading && <Text>Loading...</Text>}
      {!isLoading && (error || !game) && (
        <Text>Game not found</Text>
      )}
    </Center>
  )
}

export default ManageGame
