
import type { InferType } from 'yup';
import * as Yup from 'yup';

export const createQuestSchema = Yup.object({
  action: Yup
    .string()
    .required('El link del botón es requerido')
    .min(5, 'El link del botón debe ser de mínimo 5 caracteres'),
  buttonText: Yup
    .string()
    .required('El texto del botón es requerido')
    .min(5, 'El texto del botón debe ser de mínimo 5 caracteres')
    .max(20, 'El texto del botón debe ser de máximo 20 caracteres'),
  title: Yup
    .string()
    .required('El título es requerido')
    .min(5, 'El título debe ser de mínimo 5 caracteres')
    .max(50, 'El título debe ser de máximo 50 caracteres'),
  banner: Yup
    .string(),
  icon: Yup
    .string()
    .required('El link del icono es requerido')
    .min(5, 'El link del icono debe ser de mínimo 5 caracteres')
    .optional()
    .nullable(),
  type: Yup
    .object({
      value: Yup.string(),
      label: Yup.string()
    })
    .required('El tipo de Quest es requerido'),
  score: Yup
    .number()
    .required('El score es requerido')
    .min(1, 'El score debe ser de mínimo 1')
    .max(200, 'El score debe ser de máximo 200'),
  description: Yup.string().test(
    'empty-or-lengthy',
    'Description must be at least 8 characters and max 200',
    password => !password || password.length >= 8 && password.length <= 200,
  ),
  recursive: Yup.boolean().optional().default(false).nullable(),
  multiplier: Yup
    .number()
    .required('El multiplicador es requerido')
    .min(1, 'El multiplicador debe ser de mínimo 1')
    .max(1000, 'El multiplicador debe ser de máximo 1000'),
  ownerId: Yup
    .object({
      value: Yup.string(),
      label: Yup.string()
    })
    .required('El ownerId es requerido'),
  trigger: Yup
    .object({
      value: Yup.string(),
      label: Yup.string()
    }),
  dependsOn: Yup.object({
    value: Yup.string(),
    label: Yup.string()
  }).optional()
    .nullable(),
  callback: Yup
    .string()
    .optional()
    .nullable(),
  visible: Yup.boolean().optional().default(true),
})

export const updateQuestSchema = Yup.object({
  action: Yup
    .string()
    .required('El link del botón es requerido')
    .min(5, 'El link del botón debe ser de mínimo 5 caracteres'),
  buttonText: Yup
    .string()
    .required('El texto del botón es requerido')
    .min(5, 'El texto del botón debe ser de mínimo 5 caracteres')
    .max(20, 'El texto del botón debe ser de máximo 20 caracteres'),
  title: Yup
    .string()
    .required('El título es requerido')
    .min(5, 'El título debe ser de mínimo 5 caracteres')
    .max(50, 'El título debe ser de máximo 50 caracteres'),
  banner: Yup
    .string(),
  icon: Yup
    .string()
    .required('El link del icono es requerido')
    .min(5, 'El link del icono debe ser de mínimo 5 caracteres')
    .optional()
    .nullable(),
  type: Yup
    .object({
      value: Yup.string(),
      label: Yup.string()
    })
    .required('El tipo de Quest es requerido'),
  score: Yup
    .number()
    .required('El score es requerido')
    .min(1, 'El score debe ser de mínimo 1')
    .max(200, 'El score debe ser de máximo 200'),
  description: Yup.string().test(
    'empty-or-lengthy',
    'Description must be at least 8 characters and max 200',
    password => !password || password.length >= 8 && password.length <= 200,
  ),
  recursive: Yup.boolean().optional().default(true).nullable(),
  multiplier: Yup
    .number()
    .required('El multiplicador es requerido')
    .min(1, 'El multiplicador debe ser de mínimo 1')
    .max(1000, 'El multiplicador debe ser de máximo 1000'),
  ownerId: Yup
    .object({
      value: Yup.string(),
      label: Yup.string()
    })
    .required('El ownerId es requerido'),
  trigger: Yup
    .object({
      value: Yup.string(),
      label: Yup.string()
    }),
  dependsOn: Yup.object({
    value: Yup.string(),
    label: Yup.string()
  }).optional()
    .nullable(),
  callback: Yup
    .string()
    .optional()
    .nullable(),
  visible: Yup.boolean().optional().default(true),
})

export const BatchCompleteSchema = Yup.object({
  file: Yup.mixed()
})

export type CreateQuestInput = InferType<typeof createQuestSchema>

export type UpdateQuestInput = InferType<typeof updateQuestSchema>

export type BatchComplete = InferType<typeof BatchCompleteSchema>
