import { AdminTokensEndpoints } from "../endpoints/adminEndpoints"
import { IUseTokens } from "../interfaces/IUseTokens"
import { useQ } from "./useQ"
import { TokenData } from "@olagg/web3/dist/domain/entities/token.entity"

export const useAdminTokens = ({ page, limit, name }: IUseTokens) => {
  const response = useQ<{ tokens: TokenData[], meta: { count: number } }>(AdminTokensEndpoints.all({ page, limit, name }), {
    revalidateOnFocus: false,
  })

  return {
    ...response,
    tokens: response.data?.tokens || [],
    count: response.data?.meta?.count || 0,
  }
}
