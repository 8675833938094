export const Clock = ({
  height = '16px',
  width = '16px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00004 1.83333C4.32404 1.83333 1.33337 4.824 1.33337 8.5C1.33337 12.176 4.32404 15.1667 8.00004 15.1667C11.676 15.1667 14.6667 12.176 14.6667 8.5C14.6667 4.824 11.676 1.83333 8.00004 1.83333ZM8.00004 13.8333C5.05937 13.8333 2.66671 11.4407 2.66671 8.5C2.66671 5.55933 5.05937 3.16666 8.00004 3.16666C10.9407 3.16666 13.3334 5.55933 13.3334 8.5C13.3334 11.4407 10.9407 13.8333 8.00004 13.8333Z"
        fill={color}
      />
      <path
        d="M8.66671 5.16667H7.33337V8.77601L9.52871 10.9713L10.4714 10.0287L8.66671 8.22401V5.16667Z"
        fill={color}
      />
    </svg>
  )
}

export default Clock
