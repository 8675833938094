import type { InferType } from 'yup';
import * as Yup from 'yup';
import { ContentType, UserContentStatus } from '../db-types'

export const createUserContentSchema = Yup.object({
  title: Yup
    .string()
    .required('El titulo es requerido')
    .min(3, 'El titulo debe ser de mínimo 5 caracteres')
    .max(100, 'El titulo debe ser de máximo 100 caracteres'),
  content: Yup
    .string()
    .required('El contenido es requerido')
    .min(3, 'El texto del botón debe ser de mínimo 3 caracteres')
    .max(1000, 'El texto del botón debe ser de máximo 1000 caracteres'),
  type: Yup
    .object({
      value: Yup
        .string()
        .required('El tipo de contenido es requerid'),
      label: Yup
        .string()
        .required('Opcion inválida (el tipo de contenido no puede estar en blanco)'),
    }),
  description: Yup
    .string()
    .nullable(),
  userId: Yup
    .object({
      value: Yup
        .number()
        .required('El usuario es requerid'),
      label: Yup
        .string()
        .required('Opcion inválida (el usuario no puede estar en blanco)'),
    }),
  gameId: Yup
    .object({
      value: Yup
        .number()
        .required('Introduce el juego al que hace referencia el torneo'),
      label: Yup
        .string()
        .required('Opcion inválida (el juego no puede estar en blanco)'),
    }),
  status: Yup
    .object({
      value: Yup
        .string()
        .required('El estado es requerid'),
      label: Yup
        .string()
        .required('Opcion inválida (el estado no puede estar en blanco)'),
    })
})

export type CreateUserContentInput = InferType<typeof createUserContentSchema>
