import { useAuthStore } from '@olagg/store'
import { Login } from '@olagg/ui-kit'
import { FC, useEffect } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import Layout from './components/Layout'
import RequireAuth from './components/RequireAuth'
import ManageCredentials from './pages/ManageCredentials'
import Credentials from './pages/Credentials'
import Error from './pages/Error'
import Games from './pages/Games'
import ManageGame from './pages/ManageGame'
import GamesContent from './pages/GameContent'
import GamesContentCreate from './pages/GameContent/create'
import GamesContentEdit from './pages/GameContent/edit'
import Marketplace from './pages/Marketplace'
import MarketplaceCreate from './pages/Marketplace/create'
import MarketplaceEdit from './pages/Marketplace/edit'
import Tokens from './pages/Tokens'
import TokenForm from './pages/Tokens/TokenForm'
import TokenAssignments from './pages/Tokens/Assignments'
import TokenAddWhitelistedUser from './pages/Tokens/TokenAddWhitelistedUser'
import { ROLES } from './consts/roles'
import { ROUTES } from './consts/routes'
import Users from './pages/Users'
import ManageUser from './pages/ManageUser'
import Scholarships from './pages/Scholarships'
import Maintenance from '@olagg/ui-kit/Maintenance'
import QuestOwners from './pages/QuestOwners'
import ManageQuestOwner from './pages/ManageQuestOwner'
import Quests from './pages/Quests'
import ManageQuest from './pages/ManageQuest'
import QuestBatchComplete from './pages/QuestBatchComplete'
import UserContent from './pages/UserContent'
import ManageUserContent from './pages/ManageUserContent'
import Notifications from './pages/Notification/Notifications'
import CreateNotification from './pages/Notification/CreateNotification'
import CreateSegment from './pages/Notification/CreateSegment'
import Segments from './pages/Notification/Segments'
import Tournaments from './pages/Tournaments'
import TournamentForm from './pages/Tournaments/Form'
import TournamentInscriptions from './pages/Tournaments/Inscriptions/Inscriptions'
import TournamentAddInscription from './pages/Tournaments/Inscriptions/Create'
import CreateTournament from './pages/Tournaments/Create'
import EditTournament from './pages/Tournaments/Edit'

const App: FC = () => {
  const { me, loggedIn, getMe } = useAuthStore()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const referrerId =
    searchParams.get('referrerId') ||
    localStorage.getItem('referrerId') ||
    ''

  if (searchParams.get('referrerId'))
    localStorage.setItem('referrerId', referrerId)

  const utm_path =
    searchParams.get('utm_path') ||
    localStorage.getItem('utm_path') ||
    ''

  if (searchParams.get('utm_path'))
    localStorage.setItem('utm_path', utm_path)

  useEffect(() => {
    document.body.style.backgroundColor = `#0C0324`
    getMe()
  }, [, pathname])

  useEffect(() => {
    const c = setTimeout(() => {
      if (me) {
        localStorage.removeItem('utm_path')

        if (utm_path) {
          navigate(utm_path)
        }
      }
    }, 5)

    return () => clearTimeout(c)
  }, [me])

  const { ADMIN, REVIEWER } = ROLES
  const {
    LOGIN,
    SCHOLARSHIP_APPLICATIONS,
    GAMES,
    CREDENTIALS,
    USERS,
    OWNERS,
    QUESTS,
    USER_CONTENT,
    NOTIFICATIONS,
    SEGMENTS,
    MARKETPLACE,
    TOURNAMENTS,
    TOKENS,
  } = ROUTES

  return import.meta.env.VITE_MAINTENANCE === 'TRUE' ? (
    <Maintenance />
  ) : (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            loggedIn ? <Navigate to={GAMES.all} /> : <Login
              onClickLogin={provider => {
                localStorage.setItem(
                  'provider',
                  provider
                )
              }}
              referrerId={referrerId}
              redirect={utm_path}
            />
          }
        />
        <Route
          path={LOGIN}
          element={!loggedIn ? <Login
            onClickLogin={provider => {
              localStorage.setItem(
                'provider',
                provider
              )
            }}
            referrerId={referrerId}
            redirect={utm_path}
          /> : <Navigate to={'/'} />}
        />
        <Route
          path={GAMES.all}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <Games />
            </RequireAuth>
          }
        />
        <Route
          path={GAMES.create}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <ManageGame mode="create" />
            </RequireAuth>
          }
        />
        <Route
          path={SCHOLARSHIP_APPLICATIONS}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <Scholarships />
            </RequireAuth>
          }
        />
        <Route
          path={CREDENTIALS.all}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <Credentials />
            </RequireAuth>
          }
        />
        <Route
          path={USERS.all}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <Users />
            </RequireAuth>
          }
        />
        <Route
          path={GAMES.update}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <ManageGame mode="edit" />
            </RequireAuth>
          }
        />
        <Route
          path={GAMES.content}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <GamesContent />
            </RequireAuth>
          }
        />
        <Route
          path={GAMES.content_create}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <GamesContentCreate />
            </RequireAuth>
          }
        />
        <Route
          path={GAMES.content_update}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <GamesContentEdit />
            </RequireAuth>
          }
        />
        <Route
          path={MARKETPLACE.all}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <Marketplace />
            </RequireAuth>
          }
        />
        <Route
          path={MARKETPLACE.create}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <MarketplaceCreate />
            </RequireAuth>
          }
        />
        <Route
          path={MARKETPLACE.update}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <MarketplaceEdit />
            </RequireAuth>
          }
        />
        <Route
          path={TOKENS.all}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <Tokens />
            </RequireAuth>
          }
        />
        <Route
          path={TOKENS.create}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <TokenForm mode='create' />
            </RequireAuth>
          }
        />
        <Route
          path={TOKENS.update}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <TokenForm mode='edit' />
            </RequireAuth>
          }
        />
        <Route
          path={TOKENS.whitelist}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <TokenAssignments />
            </RequireAuth>
          }
        />
        <Route
          path={TOKENS.addWhitelistedUser}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <TokenAddWhitelistedUser />
            </RequireAuth>
          }
        />
        <Route
          path={CREDENTIALS.create}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <ManageCredentials mode="create" />
            </RequireAuth>
          }
        />
        <Route
          path={CREDENTIALS.update}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <ManageCredentials mode="edit" />
            </RequireAuth>
          }
        />
        <Route
          path={USERS.update}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <ManageUser mode="edit" />
            </RequireAuth>
          }
        />
        <Route
          path={OWNERS.all}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <QuestOwners />
            </RequireAuth>
          }
        />
        <Route
          path={OWNERS.update}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <ManageQuestOwner mode="edit" />
            </RequireAuth>
          }
        />
        <Route
          path={OWNERS.create}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <ManageQuestOwner mode="create" />
            </RequireAuth>
          }
        />
        <Route
          path={QUESTS.all}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <Quests />
            </RequireAuth>
          }
        />
        <Route
          path={QUESTS.update}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <ManageQuest mode="edit" />
            </RequireAuth>
          }
        />
        <Route
          path={QUESTS.create}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <ManageQuest mode="create" />
            </RequireAuth>
          }
        />
        <Route
          path={QUESTS.batch_complete}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <QuestBatchComplete />
            </RequireAuth>
          }
        />
        <Route
          path={USER_CONTENT.all}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <UserContent />
            </RequireAuth>
          }
        />
        <Route
          path={USER_CONTENT.update}
          element={
            <RequireAuth
              roles={[ADMIN, REVIEWER]}
              me={me}
              loggedIn={loggedIn}
            >
              <ManageUserContent mode='edit' />
            </RequireAuth>
          }
        />
        <Route
          path={NOTIFICATIONS.all}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <Notifications />
            </RequireAuth>
          }
        />
        <Route
          path={NOTIFICATIONS.create}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <CreateNotification />
            </RequireAuth>
          }
        />
        <Route
          path={SEGMENTS.create}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <CreateSegment />
            </RequireAuth>
          }
        />
        <Route
          path={SEGMENTS.all}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <Segments />
            </RequireAuth>
          }
        />
        <Route path="*" element={<Error />} />
        <Route
          path={TOURNAMENTS.all}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <Tournaments />
            </RequireAuth>
          }
        />
        <Route
          path={TOURNAMENTS.create}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <CreateTournament />
            </RequireAuth>
          }
        />
        <Route
          path={TOURNAMENTS.update}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <EditTournament />
            </RequireAuth>
          }
        />
        <Route
          path={TOURNAMENTS.inscriptions}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <TournamentInscriptions />
            </RequireAuth>
          }
        />
        <Route
          path={TOURNAMENTS.addInscription}
          element={
            <RequireAuth
              roles={[ADMIN]}
              me={me}
              loggedIn={loggedIn}
            >
              <TournamentAddInscription />
            </RequireAuth>
          }
        />
      </Route>
    </Routes>
  )
}

export default App
