/**
 * Model MetamapVerification
 *
 */
export type MetamapVerification = {
  id: string
  resource: string | null
  data: object | null
  lastStatusReceived: string | null
  userId: number | null
  createdAt: Date | null
  updatedAt: Date | null
}

/**
 * Model MetamapStatusUpdate
 *
 */
export type MetamapStatusUpdate = {
  id: string
  status: string | null
  createdAt: Date
  dataReceived: object
  verificationId: string
}

/**
 * Model DiscordAccount
 *
 */
export type DiscordAccount = {
  id: string
  username: string | null
  image: string | null
  email: string | null
  userId: number | null
  LEGACY_METAMAP_VERIFICATION_ID: string | null
}

/**
 * Model TwitterAccount
 *
 */
export type TwitterAccount = {
  id: string
  username: string | null
  image: string | null
  email: string | null
  userId: number | null
}

/**
 * Model GoogleAccount
 *
 */
export type GoogleAccount = {
  id: string
  username: string
  image: string
  email: string
  userId: number
}

/**
 * Model FacebookAccount
 *
 */
export type FacebookAccount = {
  id: string
  username: string
  image: string
  email: string
  userId: number
}

export type Level = {
  value: number,
  nextLevelScore: number,
  prevLevelScore: number,
  score: number,
}

export type TournamentStats = {
  tournamentsPlayed?: number;
  tournamentsWon?: number;
  tournamentsPlayedStreak?: number;
  tournamentsWonStreak?: number;
}

/**
 * Model User
 *
 */
export type User = {
  id: number
  email: string | null
  avatar: string | null
  name: string | null
  createdAt: Date | null
  updatedAt: Date | null
  level: Level | null
  country: string | null
  referrals: User[]
  authProviders: AuthProviderData[] | null
  discordId?: string | null
  gameCategories?: GameCategory[]
}

export type AuthProviderData = {
  id: number
  provider: 'discord' | 'google' | 'ggtech'
  internalId: string
  avatar: string
  email: string
  username: string
  createdAt: Date
  updatedAt: Date
}

/**
 * Model Game
 *
 */
export type Game = {
  id: number
  slug: string
  title: string
  description: string | null
  longDescription: string | null
  image: string | null
  banner: string | null
  screenshots: string[]
  createdAt: Date
  updatedAt: Date
  visible: boolean | null
  status: string | null
  blockchains: string[]
  platforms: string[]
  index: number
  categories?: GameCategory[]
  rating: number
  votes: number
  userRating: number
  link?: string | null
  linkText?: string | null
  content: GameContent[]
  socialLinks: SocialLinks[]
  token?: string | null
  hideTokenMetrics: boolean | null
  tos?: Tos
  newsroomDescription?: boolean | null
}

export type GameContent = {
  link: string
  thumbnail: string
  type: GameContentType
}

export enum GameContentType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

export type SocialLinks = {
  link: string
  type: SocialLinksType
}

export enum SocialLinksType {
  WEB = 'WEB',
  DISCORD = 'DISCORD',
  YOUTUBE = 'YOUTUBE',
  TWITTER = 'TWITTER',
  INSTAGRAM = 'INSTAGRAM',
  TWITCH = 'TWITCH'
}

/**
 * Model GameCategory
 *
 */
export type GameCategory = {
  id: number
  name: string
  icon?: string | null
  longName: string
  categoryType: string
}

/**
 * Model Tos
 *
 */
export type Tos = {
  id: string
  content: string
  createdAt: Date
  updatedAt: Date
  gameId: string
  userAgreement: UserAgreement
}

/**
 * Model UserAgreement
 *
 */
export type UserAgreement = {
  id: string
  createdAt: Date
  updatedAt: Date
  userAgrees: boolean
  userId: number
  tosId: string
}

/**
 * Model ScholarshipApplication
 *
 */
export type ScholarshipApplication = {
  id: number
  gameId: string
  game?: Game
  userId: number | null
  createdAt: Date
  credential: GameCredentials | null
  status: ApplicationStatus
  user: User | null
  LEGACY_DISCORD_USER_ID: string | null
}

/**
 * Model ApplicationStatusUpdate
 *
 */
export type ApplicationStatusUpdate = {
  id: string
  applicationId: number
  message: string | null
  status: ApplicationStatus
  createdAt: Date
  operatedByAdminId: number | null
  operation: string | null
}

/**
 * Model GameCredentials
 *
 */
export type GameCredentials = {
  id: string
  active: boolean
  username: string | null
  password: string | null
  walletAddress: string | null
  seedPhrase: string | null
  userRequired: Array<keyof GameCredentials>
  notShow: Array<keyof GameCredentials>
  blockchain: string | null
  personal: boolean | null
  gameId: string
  game?: Game
  email: string | null
  adminNotes: string | null
  userNotes: string | null
  createdAt: Date | null
  updatedAt: Date | null
  nftIdentifier: string | null
  built: boolean | null
  userInputsCompleted: boolean | null
  user?: User | null
  scholarship?: ScholarshipApplication | null
}

/**
 * Model GameCredentialsUpdate
 *
 */
export type GameCredentialsUpdate = {
  id: string
  data: object | null
  timestamp: Date | null
  operation: string | null
  credentialsId: string
  scholarId: number | null
  operatedByAdminId: number | null
}

/**
 * Enums
 */

// Based on
// https://github.com/microsoft/TypeScript/issues/3192#issuecomment-261720275

export const Role = {
  USER: 'USER',
  ADMIN: 'ADMIN',
  SCHOLARSHIPS_REQUESTS_REVIEWER:
    'SCHOLARSHIPS_REQUESTS_REVIEWER'
}

export type Role = typeof Role[keyof typeof Role]

export const ApplicationStatus = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  SUSPENDED: 'SUSPENDED',
  PENDING_NO_KYC_YET: 'PENDING_NO_KYC_YET'
}

export type ApplicationStatus =
  typeof ApplicationStatus[keyof typeof ApplicationStatus]

export type Quest = {
  id?: string
  title: string
  description: string
  score: any
  createdAt: Date
  updatedAt: Date
  owner?: {
    id: string
    name: string,
    order: number
  }
  type: QUEST_TYPES
  trigger?: string
  dependsOn?: string[]
  recursive: boolean
  multiplier: any
  icon: string | null
  buttonText: string | null
  banner: string | null
  callback?: string
  status: QUEST_STATUS
  action: string | null
  iteration: number
  parents?: Quest[]
  visible: boolean
}

export type AdminQuest = {
  id?: string
  description: string | null
  shortDescription: string | null
  image: string | null
  avatar?: string
  score: number
  type: QUEST_TYPES
  actionText: string | null
  action: string | null
  dependsOn?: string[]
  createdAt: Date
  updatedAt: Date
  isRecursive: boolean
  multiplier: number
  topic: string | null
  ownerId?: string | null
}

export type QuestEvent = string

export enum QUEST_STATUS {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  CLAIMED = 'CLAIMED',
  DISABLED = 'DISABLED'
}

export enum QUEST_TYPES {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
  ONE_CLICK = 'ONE_CLICK',
  ADMIN_COMPLETION = 'ADMIN_COMPLETION'
}

/**
 * Model userQuestss
 *
 */
export type userQuests = {
  id: string
  status: QUEST_STATUS
  iteration: number
  createdAt: Date
  updatedAt: Date
  quest: Quest | null
}

/**
 * Model QuestsOwners
 *
 */
export type QuestOwner = {
  id: string
  name: string
  site: string
  gameId: number
  game?: Game
  discordId: string
  discordUsername: string
  twitterId: string
  twitterUsername: string
  order: number
  quests?: Quest[]
}
export type Owner = QuestOwner

/**
 * Model StoreItem
 *
 */
export type MarketPlaceItem = {
  id?: string
  assigned?: boolean
  name: string
  description: string  // might be used for reward claiming instructions
  type: string
  availableStock: number
  status: string
  level?: number
  image: string
  dueDate: string
  visible: boolean
  callback: string
  link?: string  // might be used for claiming the reward
  couponsAvailable?: number
  couponsTaken?: number
  totalCodes?: number
  userStock?: MarketPlaceStockItem[]
}

export type MarketPlaceCouponsFormData = {
  quantity: number
}

export enum MarketPlaceItemType {
  LIMITED = 'LIMITED',
  UNLIMITED = 'UNLIMITED',
  LOTTERY = 'LOTTERY'
}

export enum MarketPlaceItemStatus {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE'
}

/**
 * Model StoreStockItem
 *
 */
export type MarketPlaceStockItem = {
  item: MarketPlaceItem
  code: string
  assignedToId?: number
  coupon?: string
}

/**
 * Model Metas
 *
 */
export type MetaTags = {
  title: string
  description: string
  url: string
  image: string
  author: string
}

export enum ContentType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  DOCUMENT = 'DOCUMENT',
  LINK = 'LINK',
}

export enum UserContentStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export type Vote = {
  id?: string;
  userId: number;
  userContentId: string;
  value: number;
  createdAt?: Date;
}

export type UserContent = {
  id?: string;
  title: string;
  userId: number;
  user: {
    id: number;
    name?: string;
    avatar?: string;
  };
  gameId: number;
  game: {
    id: number;
    title: string;
    slug: string;
  }
  content: string;
  type: ContentType;
  description: string;
  myVote?: Vote;
  positiveVotes?: number;
  negativeVotes?: number;
  status?: UserContentStatus;
  statusChangedBy?: number;
  active?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum NotificationType {
  EMAIL = 'EMAIL',
  PUSH = 'PUSH',
  SMS = 'SMS',
}

export type Segment = {
  id?: string;
  name: string;
  description: string;
  devices?: Device[];
  createdAt?: Date;
}

export type Device = {
  id?: string;
  token: string;
  userId: number;
  platform: string;
  segments?: Segment[];
  createdAt?: Date;
}

export type Notification = {
  id?: string;
  type: NotificationType;
  image: string;
  title: string;
  body: string;
  link?: string;
  devices?: Device[];
  segmentId?: string;
  segment?: {
    id: string;
    name: string;
  };
  sent?: boolean;
  createdAt?: Date;
  userIds?: number[];
}

export type UserWallet = {
  id: number
  network: string
  address: string
  main: boolean
  userId: string
  createdAt: Date
  updatedAt: Date
}

export type BatchCompleteQuest = {
  file: FileList
}

export type MarketPlaceStockFile = {
  file: FileList
}

// Torneos //
export enum ExternalIdType {
  EMAIL = "email",
  ID = "id",
  DISCORD = "discord",
}

export type FilterTournamentsParams = {
  page: number,
  title?: string,
  externalSlug?: string;
  slug?: string;
  region?: string;
  tournamentType?: string;
  playerId?: number;
  isCanceled?: boolean;
  from?: Date;
  to?: Date;
  sortStartDate?: string;
}

export type CreateTournamentParams = {
  title: string;
  image: string;
  banner: string;
  gameId: number;
  organizers?: {
    organizerId?: string | null;
  };
  externalSlug?: string | null;
  region?: string | null;
  reward: string;
  startDate: Date;
  endDate?: Date | null;
  type?: string | null;
  instructions?: string | null;
  inscriptionBases: {
    min: number;
    max?: number | null;
    openAt: Date;
    closeAt?: Date | null;
    price?: string | null;
    link: string;
  };
  participationScore?: number | null;
  winnerScore?: number | null;
  resultsUrl?: string | null;
  requiredAuth: boolean;
  requiredDiscord: boolean;
  showInBanner: boolean;
  canceled?: boolean | null;
};

export type EventType = {
  id: string,
  hashid: string,
  slug: string,
  title: string,
  keywords: string,
  lead: string,
  keypoints: string,
  cover_image: {
    original: string,
    thumbnail: string,
    tiny: string,
    webp: string
    vs: string,
    s: string,
    n: string,
    m: string,
    h: string,
    vh: string,
    uh: string,
  },
  url?: string,
  lang?: string,
  tags: Array<any>,
  main_category?: string,
  categories: Array<any>,
  tag_list: Array<any>,
  calendar: {
    start: string,
    end: string,
    link?: string,
  },
  author: {
    first_name: string,
    last_name: string,
    email: string,
    profile_image: string,
  },
  relateds?: string,
  views: number,
  publication_date: string,
  expiration_date: string,
  updated_at: string,
  deleted: boolean
  body?: string,
};

export type UpdateTournamentData = CreateTournamentParams;

export type inscriptionBases = {
  min: number;
  max?: number;
  openAt: Date;
  closeAt: Date;
  price?: string;
  link: string;
}

export type CreateInscriptionData = {
  tournamentId: string;
  externalId: string;
  externalIdType: ExternalIdType;
};

export type InscriptionData = {
  id?: string;
  type?: 'Intention' | 'Participation';
  tournamentId: string;
  position?: number;
  winner?: boolean;
  userId: number;
  user: User;
  prize?: PrizeData;
  score?: number;
  createdAt?: Date;
  updatedAt?: Date;
};

export type PrizeData = {
  usdc?: number;
  givenBy?: number;
  other?: string;
};

export type PlayerData = {
  id?: string;
  externalId: string;
  externalIdType: ExternalIdType;
  username?: string;
  image?: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type TournamentData = {
  id: string;
  title: string;
  image: string;
  banner: string;
  gameId: number;
  game?: Game
  slug: string;
  externalSlug?: string;
  region?: string;
  reward: string;
  startDate: Date;
  endDate?: Date;
  inscriptionBases: inscriptionBases;
  participationScore?: number;
  winnerScore?: number;
  instructions?: string;
  organizers?: { organizerId: string };
  type?: string;
  resultsUrl?: string;
  requiredAuth: boolean;
  requiredDiscord: boolean;
  showInBanner: boolean;
  winners?: InscriptionData[];
  isRegistered?: boolean;
  isCanceled: boolean;
  matchCode?: string;
  matchInstructions?: string;
  matchStartDate?: Date;
  discordChannel?: string;
  discordUrl?: string;
  discordNotification?: boolean;
  status: string;
};

export type GameWithTournament = {
  title: string
  slug: string
}

// Torneos - Fin //

export * from './newsroom';

export interface VoteData {
  id?: string;
  userId: number;
  newsId?: string;
  value: number;
  createdAt?: Date;
}

export type ListVotesResponse = {
  votes: Array<VoteData>;
  count: number;
  totalUpVotes: number;
  totalDownVotes: number;
}

/* Token */

export enum TokenUserIdType {
  ID = 'ID',
  DISCORD = 'DISCORD'
}

export enum TokenClassificationType { 
  GAMING = 'GAMING',
  COMMUNITY = 'COMMUNITY',
  EDUCATION = 'EDUCATION',
  GOVERNANCE = 'GOVERNANCE',
}
