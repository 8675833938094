import { Flex, Box } from "@chakra-ui/react"
import { useAdminUsers } from "@olagg/api-hooks"
import { IUseTournaments } from "@olagg/api-hooks/interfaces/IUseTournaments"
import { useState } from "react"
import { useForm } from "react-hook-form"
import OlaggForm from "../../components/Forms/OlaggForm"
import OlaGGFormInput from "../../components/Forms/OlaggFormInput"
import OlaggFormSelect from "../../components/Forms/OlaggFormSelect"

const Filter = ({
  onFilter,
}: {
  onFilter: (filter: any) => void,
}) => {
  const useFormMethods = useForm<IUseTournaments>({
    mode: 'onChange',
  })

  const handleChange = (e: React.ChangeEvent<any>) => {
    onFilter({ name: e.target.name, value: e.target.value })
  }

  return (
    <OlaggForm
      useFormMethods={useFormMethods}
      onSubmit={onFilter}
    >
      {/* <Flex mx={5} justifyContent={"space-between"} w="100%"> */}
        <OlaGGFormInput
          controlName="title"
          label={false}
          placeholder="Título"
          onChange={handleChange}
        />
      {/* </Flex> */}
    </OlaggForm>
  )
}

export default Filter
