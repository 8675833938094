import { FaSortDown, FaSortUp } from 'react-icons/fa'

export const Sort = ({
  value
}: {
  value: 'asc' | 'desc' | ''
}) => {
  if (value === 'asc') return <FaSortUp size={16} />
  if (value === 'desc') return <FaSortDown size={16} />

  return <></>
}
