import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { IUseQuestOwners } from '@olagg/api-hooks/interfaces/IUseQuestOwners'
import { BsSearch } from 'react-icons/bs'

interface IProps {
  onFilter: (value: any) => void
}

const QuestOwnerFilter = ({ onFilter }: IProps) => {
  const handleChange = (e: any) => {
    const { name, value } = e.target
    onFilter({ name, value })
  }
  return (
    <Flex flex="1">
      <InputGroup mx={5} w="50%">
        <Input
          placeholder="Nombre del owner"
          name="name"
          onChange={handleChange}
        />
        <InputRightElement
          children={<BsSearch color="white" />}
        />
      </InputGroup>
    </Flex>
  )
}

export default QuestOwnerFilter
