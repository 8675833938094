import type { InferType } from 'yup';
import * as Yup from 'yup';

enum ExternalIdType {
  EMAIL = "email",
  ID = "id",
  DISCORD = "discord",
}

export const formTournamentSchema = Yup.object({
  title: Yup
    .string()
    .required('Introduce el nombre del torneo'),
  image: Yup
    .string()
    .url('La URL debe ser valida y comenzar con http:// o https://')
    .required('La imagen de la card es requerida'),
  banner: Yup
    .string()
    .url('La URL debe ser valida y comenzar con http:// o https://')
    .required('La imagen del banner es requerida'),
  gameId: Yup
    .object({
      value: Yup
        .number()
        .required('Introduce el juego al que hace referencia el torneo'),
      label: Yup
        .string()
        .required('Opcion inválida (el juego no puede estar en blanco)'),
    })
    .required('Introduce el juego al que hace referencia el torneo'),
  organizers: Yup
    .object({
      organizerId: Yup
        .string()
        .optional()
    })
    .optional(),
  externalSlug: Yup
    .string()
    .matches(/^\S*$/, 'El slug no puede contener espacios en blanco')
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  region: Yup
    .string()
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  reward: Yup
    .string()
    .required('Introduce la descripción de los premios del torneo'),
  startDate: Yup
    .date()
    .transform((_, val) => (val === '' || val === null) ? null : new Date(val))
    .required('Introduce la fecha de inicio del torneo'),
  endDate: Yup
    .date()
    .nullable()
    .transform((_, val) => (val === '' || val === null) ? null : new Date(val))
    .optional(),
  type: Yup
    .string()
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  instructions: Yup
    .string()
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  inscriptionBasesMin: Yup
    .number()
    .min(0, 'El cupo mínimo debe ser mayor o igual a 0')
    .typeError('El cupo debe ser un numero y mayor o igual a 0')
    .required('Ingresa el cupo mínimo del torneo'),
  inscriptionBasesMax: Yup
    .number()
    .min(0, 'El cupo máximo debe ser mayor o igual a 0')
    .transform((_, val) => (val === '' || val === null) ? null : Number(val))
    .typeError('El cupo máximo debe ser un numero y mayor o igual a 0')
    .nullable(),
  inscriptionBasesOpenAt: Yup
    .date()
    .default(null)
    .transform((_, val) => (val === '' || val === null) ? null : new Date(val))
    .required(),
  inscriptionBasesCloseAt: Yup
    .date()
    .default(null)
    .transform((_, val) => (val === '' || val === null) ? null : new Date(val))
    .nullable(),
  inscriptionBasesPrice: Yup
    .string()
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  inscriptionBasesLink: Yup
    .string()
    .url('La URL debe ser valida y comenzar con http:// o https://')
    .required('Introduce el link para registrarse en el torneo'),
  participationScore: Yup
    .number()
    .min(0, 'El XP mínimo debe ser mayor o igual a 0')
    .transform((_, val) => (val === '' || val === null) ? null : Number(val))
    .typeError('El XP debe ser un numero y mayor o igual a 0')
    .nullable(),
  winnerScore: Yup
    .number()
    .min(0, 'El XP mínimo debe ser mayor o igual a 0')
    .transform((_, val) => (val === '' || val === null) ? null : Number(val))
    .typeError('El XP debe ser un numero y mayor o igual a 0')
    .nullable(),
  resultsLink: Yup
    .string()
    .url('La URL debe ser valida y comenzar con http:// o https://')
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  requiredAuth: Yup
    .boolean()
    .default(false),
  requiredDiscord: Yup
    .boolean()
    .default(false),
  showInBanner: Yup
    .boolean()
    .default(false),
  isCanceled: Yup
    .boolean()
    .default(false),
  matchCode: Yup
    .string()
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  matchInstructions: Yup
    .string()
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  matchStartDate: Yup
    .date()
    .default(null)
    .transform((_, val) => (val === '' || val === null) ? null : new Date(val))
    .nullable(),
  discordChannel: Yup
    .string()
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  discordUrl: Yup
    .string()
    .url('La URL debe ser valida y comenzar con http:// o https://')
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  discordNotification: Yup
    .boolean()
    .default(false)
    .optional(),
})
  .required();

export const formInscriptionSchema = Yup.object({
  userId: Yup
    .object({
      value: Yup
        .number()
        .required('Introduce el usuario'),
      label: Yup
        .string()
        .required('Opción inválida (el usuario no puede estar en blanco)'),
    })
    .required('Introduce el usuario'),
  position: Yup
    .number()
    .min(0, 'La posición final debe ser mayor o igual a 0')
    .transform((_, val) => (val === '' || val === undefined) ? undefined : Number(val))
    .typeError('La posición debe ser un numero y mayor o igual a 0')
    .optional(),
  score: Yup
    .number()
    .min(0, 'Los XP a otorgar deben ser mayor o igual a 0')
    .transform((_, val) => (val === '' || val === undefined) ? undefined : Number(val))
    .typeError('La cantidad de XP debe ser un numero y mayor o igual a 0')
    .optional(),
  prizeUsdc: Yup
    .number()
    .min(0, 'Los USDC a otorgar deben ser mayor o igual a 0')
    .transform((_, val) => (val === '' || val === undefined) ? undefined : Number(val))
    .typeError('La cantidad de USDC debe ser un numero y mayor o igual a 0')
    .optional(),
  prizeOther: Yup
    .string()
    .optional(),
  winner: Yup
    .boolean()
    .default(false),
})
  .required();

export const formInscriptionBatchSchema = Yup
  .object().shape({
    file: Yup
      .mixed<FileList>()
      .required()
  })
  .required();

export type FormTournamentData = InferType<typeof formTournamentSchema>;
export type FormInscriptionData = InferType<typeof formInscriptionSchema>;
export type FormInscriptionBatchData = InferType<typeof formInscriptionBatchSchema>;
