import type { Game } from '@olagg/db-types'
import { AdminGameEndpoints } from '../endpoints/adminEndpoints'
import { useQ } from './useQ'

export const useAdminGames = ({
  title,
  page,
  limit
}: {
  title?: string
  page: number
  limit: number
}) =>{
  const response = useQ<{games: Game[], meta: {count: number}}>(
    AdminGameEndpoints.all({title, page, limit}), { revalidateOnFocus: false }
  )

  return {
    ...response,
    games: response.data?.games ?? [],
    count: response.data?.meta.count ?? 0
  }
}
