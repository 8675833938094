import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { IGetAllProps } from '@olagg/api-hooks/interfaces/IUseGames'
import { BsSearch } from 'react-icons/bs'

interface IProps {
  onFilter: (value: any) => void
  filter: IGetAllProps
}

const GamesFilter = ({ onFilter, filter }: IProps) => {
  const { title } = filter
  return (
    <Flex flex="1">
      <InputGroup mx={5} w="50%">
        <Input
          placeholder="Título o slug"
          name="title"
          value={title}
          onChange={(e) => onFilter(e.target)}
        />
        <InputRightElement
          children={<BsSearch color="white" />}
        />
      </InputGroup>
    </Flex>
  )
}

export default GamesFilter
