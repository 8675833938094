import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  Box
} from '@chakra-ui/react'
import { GameCategory } from '@olagg/db-types'
import { useCategoriesStore } from '@olagg/store'
import { useEffect, useState } from 'react'
import Check from '../svg/Check'
import Category from './Category'

enum CAT_TYPES {
  GENRE = 'Género',
  OLA = 'OLA'
}

interface IProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: (categories: GameCategory[]) => void
  categoriesOfInterest: GameCategory[]
  customMessage?: string
  onlyGenre?: boolean
}
export default function CategoryModal({
  isOpen,
  onClose,
  categoriesOfInterest,
  onConfirm,
  customMessage,
  onlyGenre = false
}: IProps) {
  const { categories, getCategories } = useCategoriesStore()

  const [selectedCategories, setSelectedCategories] = useState<GameCategory[]>([])

  const [isLoading, setIsLoading] = useState(false)
  const [categoriesOrder, setCategoriesOrder] = useState<{}>({})
  const [categoriesTypes, setCategoriesTypes] = useState<string[]>([])

  useEffect(() => {
    getCategories()

    setSelectedCategories(categoriesOfInterest)
  }, [categoriesOfInterest])

  useEffect(() => {
    if (!isOpen) setIsLoading(false)
  }, [isOpen])


  useEffect(() => {
    reorderCategoriesType()
  }, [categories])

  useEffect(() => {
    reorderCategories()

  }, [categoriesOrder])

  const reorderCategoriesType = async () => {
    let catTypes: string[] = categoriesTypes
    categories.map((ct) => {
      if (!catTypes.includes(ct.categoryType)) {
        catTypes.push(ct.categoryType)
      }
    })
    setCategoriesTypes(catTypes)
  }

  const reorderCategories = async () => {
    let fillCatOrder: {} = categoriesOrder
    categoriesTypes.map((ct) => {
      fillCatOrder[ct] = categories.filter(c => c.categoryType == ct)
    })
    setCategoriesOrder(fillCatOrder)
  }


  const checkIfSelected = (id: string) =>
    selectedCategories?.some(category => category.id === id)

  const selectCategory = (category: GameCategory) => {
    const categoryExists: boolean = selectedCategories?.some(
      ({ id }) => id === category.id
    )

    if (categoryExists) {
      return setSelectedCategories(prev =>
        prev.filter(({ id }) => id !== category.id)
      )
    }
    if (selectedCategories?.length) {
      setSelectedCategories(prev => [...prev, category])
    } else {
      setSelectedCategories([category])
    }
  }

  const onClickConfirm = () => {
    setIsLoading(true)
    onConfirm(selectedCategories)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={5}>
        <ModalCloseButton />
        <ModalHeader color="olaggPurple.100" mr={3}>
          {customMessage ||
            'Selecciona tus géneros de juego favoritos:'}
        </ModalHeader>
        <ModalBody maxHeight='450px' overflow='auto'>

          {
            categoriesOrder && !onlyGenre &&
            Object.keys(categoriesOrder).map((t) => (
              <Box key={t}>
                <Box
                  w='100%'
                  pb={2}
                  borderBottom='1px solid #ccc'
                >
                  <Text
                    fontSize="18px"
                    fontWeight="600"
                    color='#3F2091'
                  >
                    {CAT_TYPES[t]}
                  </Text>
                </Box>
                <Box mb={8}>
                  {
                    categoriesOrder[t].map((c: GameCategory) => (
                      <Category
                        key={c.id}
                        category={c}
                        name={c.longName}
                        selected={checkIfSelected(c.id)}
                        selectCategory={selectCategory}
                      />
                    ))
                  }
                </Box>
              </Box>
            ))
          }

          {
            categoriesOrder && onlyGenre &&
            <Box mb={8}>
              {
                categoriesOrder['genre'] &&
                categoriesOrder['genre'].map((c: GameCategory) => (
                  <Category
                    key={c.id}
                    category={c}
                    name={c.longName}
                    selected={checkIfSelected(c.id)}
                    selectCategory={selectCategory}
                  />
                ))
              }
            </Box>
          }

        </ModalBody>
        <ModalFooter>
          <Button
            w="full"
            gap="2"
            variant="filledGradient"
            onClick={onClickConfirm}
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                <Check />
                <Text mt={1} fontSize="20px" fontWeight="500">
                  Confirmar
                </Text>
              </>
            )}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
