import { AssignmentData } from "@olagg/web3/dist/domain/entities/assignment.entity";
import { AdminAssignmentEndpoints } from "../endpoints/adminEndpoints";
import { IUseAssignments } from "../interfaces/IUseAssignments";
import { useQ } from "./useQ";

export const useAdminAssignments = ({ page, limit, userId, tokenId }: IUseAssignments) => {

  const response = useQ<{ assignments: AssignmentData[], meta: { count: number } }>(
    AdminAssignmentEndpoints.all({ page, limit, userId, tokenId }), {
    revalidateOnFocus: false,
  }
  )

  return {
    ...response,
    assignments: response.data?.assignments || [],
    count: response.data?.meta?.count || 0,
  }
}
