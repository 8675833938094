import {
  TableContainer,
  Table as ChakraTable,
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react"
import { AdminTournamentsEndpoints, fetcher } from "@olagg/api-hooks";
import { InscriptionData } from "@olagg/db-types"
import { FaAward } from "react-icons/fa"

type TableProps = {
  inscriptions: InscriptionData[];
  onUpdate?: () => void;
}

const Table = ({ inscriptions, onUpdate = () => {} }: TableProps) => {
  const toast = useToast();

  const removeInscription = (inscription: InscriptionData) => {
    fetcher(AdminTournamentsEndpoints.deleteInscription(Number(inscription.tournamentId!), inscription.id!))
      .then(() => {
        toast({
          title: 'Inscripciones',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Usuario removido del torneo',
          position: 'bottom-right'
        });
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
      .finally(() => {
        onUpdate();
      });
  };

  return (
    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="80vw" h="60vh">
      <ChakraTable
        variant="striped"
        colorScheme="olaggPurple"
        fontSize="18px"
        position='relative'
      >
        <Thead>
          <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
            <Th color="white">Jugador</Th>
            <Th color="white" textAlign='center'>Ganador</Th>
            <Th color="white" textAlign='center'>Posición</Th>
            <Th color="white" textAlign='center'>XP</Th>
            <Th color="white" textAlign='center'>Premios</Th>
            <Th textAlign='center' color="white">Acción</Th>
          </Tr>
        </Thead>
        <Tbody>
          {inscriptions.map((inscription, index) => (
            <Tr key={index}>
              <Td maxWidth='150px'>
                {`${inscription.user.name || 'Sin nombre'} (${inscription.user.id})`}
              </Td>
              <Td textAlign='center'>
                {inscription.type === 'Intention' ? 
                  'N/A (intención)' :
                  inscription.winner && <FaAward style={{ margin: '0 auto' }} color="#eeee00" /> 
                }
              </Td>
              <Td textAlign='center'>{inscription.position}</Td>
              <Td textAlign='center'>{inscription.score}</Td>
              <Td textAlign='center'>
                <Box>
                  {(inscription.prize?.usdc || 0 > 0) && `${inscription.prize?.usdc} USDC `}
                  {inscription.prize?.other}
                </Box>
              </Td>
              <Td textAlign='center'>
                <Flex gap='3' justifyContent={'center'}>
                  <Popover>
                    <PopoverTrigger>
                      <Button size={'sm'} padding='0px 8px' ml={0} colorScheme="red">Eliminar</Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Button width={'100%'} colorScheme='red' onClick={() => removeInscription(inscription)}
                          >
                            Confirmar eliminar
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}

export default Table;
