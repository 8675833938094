import {
  Box,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  VStack
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import type {
  DiscordAccount,
  MetamapVerification,
  ScholarshipApplication,
  User
} from '@olagg/db-types'
import {
  UpdateUserInput,
  updateUserSchema
} from '@olagg/validation-schemas'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import ScholarshipApplications from '../../features/ScholarshipApplications'

interface IProps {
  user: User
  mode: 'edit' | 'view'
}

const UserForm: FC<IProps> = ({ user, mode }) => {
  const {
    register,
    formState: { errors, isSubmitting }
  } = useForm<UpdateUserInput>({
    resolver: zodResolver(updateUserSchema.shape['body']),
    mode: 'all'
  })

  const titleize = (str: string) =>
    str.charAt(0).toUpperCase() + str.slice(1)

  return (
    <Center w="full">
      <VStack color="white" gap={4}>
        <Heading>{user.email}</Heading>
        <fieldset disabled>
          <VStack w={{ base: '70vw', lg: '50vw' }}>
            <>
              <Box w="full">
                <FormControl
                  mt={5}
                  isInvalid={errors.name ? true : false}
                >
                  <FormLabel htmlFor="name">Nombre</FormLabel>
                  <Input
                    id="name"
                    placeholder="Nombre de usuario"
                    {...register('name', {
                      value: user?.name || ''
                    })}
                  />
                  <FormErrorMessage>
                    {errors.name &&
                      errors.name.message?.toString()}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box w="full">
                <FormControl
                  mt={5}
                  isInvalid={errors.email ? true : false}
                >
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    id="email"
                    placeholder="Nombre de usuario"
                    {...register('email', {
                      value: user.email || ''
                    })}
                  />
                  <FormErrorMessage>
                    {errors.email &&
                      errors.email.message?.toString()}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              {user.authProviders?.map(auth => (
                <Box w="full">
                  <FormControl mt={5}>
                    <FormLabel htmlFor="discordId">
                      {`${titleize(auth.provider)} ID`}
                    </FormLabel>
                    <Input
                      id="discordId"
                      value={auth.internal_id || ''}
                      disabled={true}
                    />
                    <FormErrorMessage></FormErrorMessage>
                  </FormControl>
                </Box>
              ))}
            </>
          </VStack>
        </fieldset>
      </VStack>
    </Center>
  )
}

export default UserForm
