import { Center, Text, VStack, Heading, FormControl, Input, FormLabel, FormErrorMessage, Flex, Button, useToast, Box } from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'

import {
  BatchCompleteSchema,
  BatchComplete
} from '@olagg/validation-schemas'

import {
  fetcher,
  AdminQuestEndpoints
} from '@olagg/api-hooks'
import { yupResolver } from '@hookform/resolvers/yup'

const QuestBatchComplete = () => {
  const params = useParams()
  const id = params?.id

  const toast = useToast()

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm<BatchComplete>({
    resolver: yupResolver(BatchCompleteSchema),
    mode: 'onChange'
  })

  const onSubmit = (data: any) => {
    console.log(data);
    console.log("ID", id);

    const formData = new FormData();
    formData.append("file", data.file[0]);
    fetcher(
      AdminQuestEndpoints.batchComplete(id, formData)
    )
      .then(() => onSuccess('Subido!'))
      .catch(e => onError(e))
  }

  const onSuccess = async (title: string, body?: string) => {
    reset()
    toast({
      title,
      colorScheme: 'olaggPink',
      status: 'success',
      description: 'Archivo de Lote subido correctamente!',
      position: 'bottom-right'
    })
    setTimeout(() => {
      location.reload()
    }, 750)
  }

  const onError = (e: { message: string } | string) => {
    const message = typeof e === 'string' ? e : e.message
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: message
          }}
        ></div>
      ),
      position: 'bottom-right'
    })
  }

  return (
    <Center w={'full'}>
      <VStack color={'white'}>
        <Heading my={2}>Subir archivo de lote para completar Misiones</Heading>

        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <VStack
            w={{ base: '70vw', lg: '50vw' }}
            mt={2}
            mb={10}
          >
            <FormControl
              isInvalid={errors.file ? true : false}
            >
              <FormLabel htmlFor="file">
                Seleccionar archivo de lote* (.xlsx)
              </FormLabel>
              <Input
                sx={{
                  lineHeight: '32px',
                  paddingLeft: 0
                }}

                type='file'
                id="file"
                {...register('file')}
              />
              <FormErrorMessage>
                {errors.file &&
                  errors.file.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <Box textAlign='left' w='100%'>
              <a href='https://olagg.io/downloads/campos.xlsx'>
                <Button
                  variant="filledGradient"
                  flex="1"
                  size={'sm'} py='5px' mt='5px'
                >
                  Descargar archivo de ejemplo
                </Button>
              </a>
            </Box>

            <Flex w="full" style={{ marginTop: '40px' }}>
              <Button
                variant="filledGradient"
                type="submit"
                flex="1"
              >
                Subir archivo
              </Button>
            </Flex>
          </VStack>
        </form>
      </VStack>
    </Center>
  )
}

export default QuestBatchComplete
