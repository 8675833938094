import { FC, useState, useEffect, useCallback } from 'react'
import {
  Center,
  Heading,
  VStack,
  Image,
  Button,
  Box,
  Spinner,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Select,
  useToast,
  Checkbox
} from '@chakra-ui/react'
import Option from '../../components/Option'
import {
  fetcher,
  AdminMarketPlaceEndpoints
} from '@olagg/api-hooks'
import type { MarketPlaceItem, MarketPlaceStockFile } from '@olagg/db-types'
import { MarketPlaceItemType, MarketPlaceItemStatus } from '@olagg/db-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { ROUTES } from '../../consts/routes'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios'
import moment from 'moment/moment.js'
import 'moment/min/locales'

type FormData = {
  name: string,
  description: string,
  type: MarketPlaceItemType,
  level?: number,
  image: string,
  dueDate?: string,
  visible: boolean,
  callback?: string,
  link?: string
}

const schema: Yup.Schema<FormData> = Yup
  .object({
    name: Yup.string().required('El título es obligatorio'),
    description: Yup.string().required('Descripción es obligatoria'),
    type: Yup.mixed<MarketPlaceItemType>().oneOf(Object.values(MarketPlaceItemType)).required('Tipo de Item es obligatorio'),
    level: Yup.number(),
    image: Yup.string().required('Link de la imagen es obligatoria'),
    dueDate: Yup.string(),
    visible: Yup.boolean().required(),
    callback: Yup.string().optional(),
    link: Yup.string(),
  })

const MarketplaceCreate: FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation();
  const { data } = state;
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [toggleStockFile, setToggleStockFile] = useState(false)
  const [visibleItem, setVisibleItem] = useState(false)
  const [selectedType, setSelectedType] = useState<string | null>(null)

  const initRequest = useCallback(async () => {
    setValue('name', data.name)
    setValue('type', data.type)
    setValue('description', data.description)
    setValue('link', data.link)

    if (data.type == MarketPlaceItemType.LIMITED) {
      setToggleStockFile(true)
    } else {
      setToggleStockFile(false)
    }

    if (data.type == MarketPlaceItemType.UNLIMITED) {
      setSelectedType(data.type)
    }

    setValue('level', data.level)
    setValue('image', data.image)
    if (data?.dueDate) {
      let dueDate = moment.utc(data.dueDate).format('YYYY-MM-DD')
      setValue('dueDate', dueDate)
    }
    setValue('visible', data.visible)
    setVisibleItem(data.visible)

    setValue('callback', data.callback)


  }, [])

  useEffect(() => {
    initRequest();
  }, [])

  const { handleSubmit, formState: { errors }, register, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const typeText = (s: string) => {
    if (s == MarketPlaceItemType.LOTTERY) {
      return 'Sorteo'
    }
    if (s == MarketPlaceItemType.LIMITED) {
      return 'Cupos limitados'
    }
    if (s == MarketPlaceItemType.UNLIMITED) {
      return 'Cupos ilimitados'
    }
  }

  const onSubmit = (formData: any) => {
    const form = new FormData();
    form.append('item[name]', formData.name);
    form.append('item[description]', formData.description);
    form.append('item[type]', formData.type);
    form.append('item[level]', formData.level);
    form.append('item[image]', formData.image);
    form.append('item[dueDate]', formData.dueDate);
    form.append('item[visible]', formData.visible);
    form.append('item[callback]', formData.callback);
    form.append('item[link]', formData.link);
    if (formData.code) form.append('code', formData.code);
    if (formData.file?.[0]) form.append('file', formData.file[0]);

    setIsLoading(true)
    fetcher(AdminMarketPlaceEndpoints.update(data.id, form))
      .then(() => {
        toast({
          title: 'Actualizado!',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Item actualizado con éxito',
          position: 'bottom-right'
        })
        setIsLoading(false)
        navigate(ROUTES.MARKETPLACE.all)
      })
      .catch(e => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: e.message,
          position: 'bottom-right'
        })
        setIsLoading(false)
      })
  }

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Editar Item de la Tienda
        </Heading>

        {isLoading ? (
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit as any)} style={{ width: '100%' }} encType="multipart/form-data">
              <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
                <FormControl
                  color={'white'}
                  isInvalid={errors.name ? true : false}
                >
                  <FormLabel marginTop={'20px'} fontWeight={600}>Título</FormLabel>
                  <Input
                    id="name"
                    {...register('name')}
                  />
                  <FormErrorMessage>
                    {errors.name && errors.name.message?.toString()}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  color={'white'}
                  isInvalid={errors.type ? true : false}
                  isDisabled={true}
                >
                  <FormLabel marginTop={'20px'} fontWeight={600} htmlFor="type">Tipo</FormLabel>
                  <Select
                    fontWeight={600}
                    id="type"
                    {...register('type')}
                    onChange={(e) => {
                      if (e.target.value == MarketPlaceItemType.LIMITED) {
                        setToggleStockFile(true)
                      } else {
                        setToggleStockFile(false)
                      }
                      setSelectedType(e.target.value)
                    }}
                  >
                    {Object.values(MarketPlaceItemType).map(s => (
                      <Option key={s} value={s}>
                        {typeText(s)}
                      </Option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {errors.type && errors.type.message?.toString()}
                  </FormErrorMessage>
                </FormControl>

                {/* {toggleClickRedirect && ( */}
                <FormControl
                  color={'white'}
                  isInvalid={errors.callback ? true : false}
                >
                  <FormLabel marginTop={'20px'} fontWeight={600}>Link "Más info" (opcional)</FormLabel>
                  <Input
                    id="callback"
                    {...register('callback')}
                    required={false}
                  />
                  <FormErrorMessage>
                    {errors.callback && errors.callback.message?.toString()}
                  </FormErrorMessage>
                </FormControl>
                {/* )} */}

                <FormControl
                  color={'white'}
                  isInvalid={errors.level ? true : false}
                >
                  <FormLabel marginTop={'20px'} fontWeight={600}>Level</FormLabel>
                  <Input
                    id="level"
                    {...register('level')}
                    type='number'
                  />
                  <FormErrorMessage>
                    {errors.level && errors.level.message?.toString()}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  color={'white'}
                  isInvalid={errors.image ? true : false}
                >
                  <FormLabel marginTop={'20px'} fontWeight={600}>Link de la Imagen (712x400px)</FormLabel>
                  <Input
                    id="image"
                    {...register('image')}
                  />
                  <FormErrorMessage>
                    {errors.image && errors.image.message?.toString()}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  color={'white'}
                  isInvalid={errors.dueDate ? true : false}
                >
                  <FormLabel marginTop={'20px'} fontWeight={600}>Fecha de vencimiento (Opcional)</FormLabel>
                  <Input
                    id="dueDate"
                    {...register('dueDate')}
                    type='date'
                  />
                  <FormErrorMessage>
                    {errors.dueDate && errors.dueDate.message?.toString()}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  color={'white'}
                  isInvalid={errors.description ? true : false}
                >
                  <FormLabel marginTop={'20px'} fontWeight={600}>Descripción</FormLabel>
                  <Input
                    id="description"
                    {...register('description')}
                  />
                  <FormErrorMessage>
                    {errors.description && errors.description.message?.toString()}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  color={'white'}
                  isInvalid={errors.link ? true : false}
                >
                  <FormLabel marginTop={'20px'} fontWeight={600}>Link de canje (opcional - Ej.: https://www.google.com)</FormLabel>
                  <Input
                    id="link"
                    {...register('link')}
                  />
                  <FormErrorMessage>
                    {errors.link && errors.link.message?.toString()}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  pb={2}
                  color={'white'}
                  isInvalid={errors.visible ? true : false}
                >
                  <Checkbox
                    marginTop={'20px'}
                    id="visible"
                    {...register('visible', {
                      value: data.visible || false
                    })}
                    onChange={value => (
                      setVisibleItem(value.target.checked)
                    )}
                  >
                    Visible
                  </Checkbox>
                  <FormErrorMessage>
                    {errors.status &&
                      errors.status.message?.toString()}
                  </FormErrorMessage>
                </FormControl>

                <Button
                  variant={'filledGradient'}
                  isLoading={isLoading}
                  disabled={isLoading}
                  type="submit"
                  w={'full'}
                  style={{
                    marginTop: '30px'
                  }}
                >
                  Enviar
                </Button>
              </VStack>
              {toggleStockFile && (
                <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
                  <FormControl
                    color={'white'}
                  >
                    <FormLabel marginTop={'20px'} fontWeight={600}>Seleccione archivo XLSX con códigos del stock</FormLabel>
                    <Input
                      sx={{
                        lineHeight: '32px',
                        paddingLeft: 0
                      }}
                      id="file"
                      {...register('file')}
                      type='file'
                    />

                  </FormControl>

                  <Box textAlign='left' w='100%'>
                    <a href='https://olagg.io/downloads/codigos.xlsx'>
                      <Button
                        variant="filledGradient"
                        flex="1"
                        size={'sm'} py='5px' mt='5px'
                      >
                        Descargar archivo de ejemplo
                      </Button>
                    </a>
                  </Box>

                  <Button
                    variant={'filledGradient'}
                    isLoading={isLoading}
                    disabled={isLoading}
                    type="submit"
                    w={'full'}
                    style={{
                      marginTop: '30px'
                    }}
                  >
                    Subir códigos
                  </Button>
                </VStack>
              )}
            </form>
          </>


        )}
      </VStack>
    </Center>
  )
}

export default MarketplaceCreate
