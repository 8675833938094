export const Sniper = ({
  height = '21px',
  width = '20px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99994 17.2833C8.65031 17.2833 7.33107 16.8826 6.20936 16.1321C5.08765 15.3816 4.21398 14.315 3.69903 13.0675C3.18409 11.82 3.05106 10.4477 3.31679 9.12446C3.58253 7.80125 4.23507 6.58671 5.19174 5.63472C6.1484 4.68274 7.36613 4.03615 8.69062 3.77691C10.0151 3.51766 11.3868 3.65742 12.6317 4.17847C13.8767 4.69952 14.939 5.57842 15.684 6.70379C16.429 7.82916 16.8232 9.15036 16.8166 10.5C16.8056 12.3014 16.0828 14.0254 14.8059 15.2961C13.5289 16.5668 11.8014 17.2811 9.99994 17.2833ZM9.99994 4.9083C8.90002 4.90831 7.82476 5.23417 6.90988 5.84474C5.99499 6.45532 5.2815 7.32324 4.85944 8.33896C4.43739 9.35468 4.3257 10.4727 4.53847 11.5518C4.75125 12.6309 5.27894 13.6229 6.05496 14.4024C6.83097 15.1819 7.82052 15.714 8.89869 15.9316C9.97687 16.1492 11.0953 16.0425 12.1129 15.625C13.1305 15.2075 14.0017 14.4979 14.6163 13.5858C15.231 12.6737 15.5617 11.5999 15.5666 10.5C15.5699 9.76684 15.4283 9.04029 15.1501 8.36202C14.8718 7.68375 14.4623 7.06713 13.945 6.54757C13.4278 6.028 12.813 5.61573 12.136 5.33442C11.459 5.0531 10.7331 4.90829 9.99994 4.9083Z"
        fill={color}
      />
      <path
        d="M10 6.41666C9.83424 6.41666 9.67527 6.35081 9.55806 6.2336C9.44085 6.11639 9.375 5.95742 9.375 5.79166V2.76666C9.375 2.6009 9.44085 2.44193 9.55806 2.32472C9.67527 2.20751 9.83424 2.14166 10 2.14166C10.1658 2.14166 10.3247 2.20751 10.4419 2.32472C10.5592 2.44193 10.625 2.6009 10.625 2.76666V5.79166C10.625 5.95742 10.5592 6.11639 10.4419 6.2336C10.3247 6.35081 10.1658 6.41666 10 6.41666Z"
        fill={color}
      />
      <path
        d="M10 18.8333C9.83424 18.8333 9.67527 18.7675 9.55806 18.6503C9.44085 18.5331 9.375 18.3741 9.375 18.2083V15.1417C9.375 14.9759 9.44085 14.8169 9.55806 14.6997C9.67527 14.5825 9.83424 14.5167 10 14.5167C10.1658 14.5167 10.3247 14.5825 10.4419 14.6997C10.5592 14.8169 10.625 14.9759 10.625 15.1417V18.1667C10.6307 18.2522 10.6188 18.3379 10.5899 18.4187C10.5611 18.4994 10.516 18.5733 10.4574 18.6358C10.3987 18.6983 10.3279 18.7481 10.2492 18.7821C10.1705 18.8161 10.0857 18.8335 10 18.8333Z"
        fill={color}
      />
      <path
        d="M17.7 11.125H14.675C14.5093 11.125 14.3503 11.0592 14.2331 10.9419C14.1159 10.8247 14.05 10.6658 14.05 10.5C14.05 10.3342 14.1159 10.1753 14.2331 10.0581C14.3503 9.94085 14.5093 9.875 14.675 9.875H17.7C17.8658 9.875 18.0248 9.94085 18.142 10.0581C18.2592 10.1753 18.325 10.3342 18.325 10.5C18.325 10.6658 18.2592 10.8247 18.142 10.9419C18.0248 11.0592 17.8658 11.125 17.7 11.125Z"
        fill={color}
      />
      <path
        d="M5.32505 11.0917H2.30005C2.13429 11.0917 1.97532 11.0258 1.85811 10.9086C1.7409 10.7914 1.67505 10.6324 1.67505 10.4667C1.67505 10.3009 1.7409 10.1419 1.85811 10.0247C1.97532 9.90752 2.13429 9.84167 2.30005 9.84167H5.32505C5.40713 9.84167 5.4884 9.85784 5.56423 9.88925C5.64005 9.92066 5.70895 9.9667 5.76699 10.0247C5.82503 10.0828 5.87106 10.1517 5.90247 10.2275C5.93388 10.3033 5.95005 10.3846 5.95005 10.4667C5.95005 10.5488 5.93388 10.63 5.90247 10.7059C5.87106 10.7817 5.82503 10.8506 5.76699 10.9086C5.70895 10.9667 5.64005 11.0127 5.56423 11.0441C5.4884 11.0755 5.40713 11.0917 5.32505 11.0917Z"
        fill={color}
      />
      <path
        d="M11.1917 11.1166H8.80835C8.64259 11.1166 8.48362 11.0508 8.36641 10.9336C8.2492 10.8164 8.18335 10.6574 8.18335 10.4916C8.18335 10.3259 8.2492 10.1669 8.36641 10.0497C8.48362 9.93249 8.64259 9.86664 8.80835 9.86664H11.1917C11.3574 9.86664 11.5164 9.93249 11.6336 10.0497C11.7508 10.1669 11.8167 10.3259 11.8167 10.4916C11.8167 10.6574 11.7508 10.8164 11.6336 10.9336C11.5164 11.0508 11.3574 11.1166 11.1917 11.1166Z"
        fill={color}
      />
      <path
        d="M10 12.2834C9.83424 12.2834 9.67527 12.2175 9.55806 12.1003C9.44085 11.9831 9.375 11.8241 9.375 11.6584V9.27502C9.375 9.10926 9.44085 8.95029 9.55806 8.83308C9.67527 8.71587 9.83424 8.65002 10 8.65002C10.1658 8.65002 10.3247 8.71587 10.4419 8.83308C10.5592 8.95029 10.625 9.10926 10.625 9.27502V11.6584C10.625 11.8241 10.5592 11.9831 10.4419 12.1003C10.3247 12.2175 10.1658 12.2834 10 12.2834Z"
        fill={color}
      />
    </svg>
  )
}

export default Sniper
