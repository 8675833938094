import { useState } from "react";
import {
  Center,
  Heading,
  VStack,
  Table as ChakraTable,
  Thead,
  Tr,
  Td,
  Tbody,
  Th,
  Image,
  Box,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import Pagination from "@olagg/ui-kit/Pagination";
import useAdminNotifications from "./useAdminNotifications";
import NotificationsFilter from "../../components/Filters/NotificationsFilter";
import moment from "moment";

const Notifications = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [filter, setFilter] = useState({
    title: searchParams.get('title') || undefined,
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 10),
    userId: searchParams.get('userId') ? Number(searchParams.get('userId')) : undefined,
  })

  const { title, page, limit, userId } = filter

  const { notifications, count } = useAdminNotifications({ page, title, limit, userId });

  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  return (
    <Center w={'full'} p={4} >
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Notificaciones
        </Heading>

        <NotificationsFilter onFilter={onFilter} />
        <Box overflow="auto" mt={4} w="75vw" h="60vh">
          <ChakraTable variant="striped" colorScheme="olaggPurple">
            <Thead position='sticky' top='0' bg='gray.800'>
              <Tr>
                <Th color="white">Tipo</Th>
                <Th color="white">Imagen</Th>
                <Th color="white">Título</Th>
                <Th color="white">Cuerpo</Th>
                <Th color="white">Segmento</Th>
                <Th color="white">Fecha</Th>
              </Tr>
            </Thead>
            <Tbody>
              {notifications.map(notification => (
                <Tr key={notification.id}>
                  <Td style={{ fontWeight: 'bold' }}>{notification.type.toUpperCase()}</Td>
                  <Td>
                    <Image maxW={'30px'} src={notification.image} />
                  </Td>
                  <Td>{notification.title}</Td>
                  <Td>{notification.body}</Td>
                  <Td>{notification.segment?.name}</Td>
                  <Td>{moment(notification.createdAt).format('DD/MM/YYYY')}</Td>
                </Tr>
              ))}
            </Tbody>
          </ChakraTable>
        </Box>
        <Pagination
            params={{ limit, page, count }}
            onClick={page =>
              onFilter({
                name: 'page',
                value: page + 1
              })
            }
            onLimitChange={val =>
              onFilter({
                name: 'limit',
                value: val
              })
            }
            hideLimitInput
          />
      </VStack>
    </Center >
  );
};

export default Notifications
