import Ball from '../svg/Ball'
import Card from '../svg/Card'
import Dice from '../svg/Dice'
import Knife from '../svg/Knife'
import Sniper from '../svg/Sniper'
import Sword from '../svg/Sword'
import TicTacToe from '../svg/TicTacToe'

const CategoryICon = ({
  name,
  selected
}: {
  name: string
  selected: boolean
}) => {
  const color = selected ? 'white' : '#3F2091'

  if (name === 'Battle Royale') return <Sword color={color} />
  if (name === 'Juegos Casuales') return <Card color={color} />
  if (name === 'Role-Playing') return <Dice color={color} />
  if (name === 'Deportes') return <Ball color={color} />
  if (name === 'MOBA/Estrategia')
    return <TicTacToe color={color} />
  if (name === 'Fighting') return <Knife color={color} />
  if (name === 'First Person Shooters')
    return <Sniper color={color} />
  return <></>
}

export default CategoryICon
