import { FC, useState, useEffect, useCallback } from 'react'
import {
  Center,
  Heading,
  VStack,
  Image,
  Button,
  Box,
  Spinner,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Select,
  useToast
} from '@chakra-ui/react'
import Option from '../../components/Option'
import {
  fetcher,
  GameEndpoints,
  GameContentEndpoints
} from '@olagg/api-hooks'
import type { Game as GameType } from '@olagg/db-types'
import { useNavigate, useSearchParams, Link } from 'react-router-dom'
import { ROUTES } from '../../consts/routes'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios'


export enum GameContentType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

type FormData = {
  slug: string | null
  link: string
  thumbnail: string
  type: GameContentType
}

const schema: Yup.Schema<FormData> = Yup
  .object({
    slug: Yup.string().required('Slug es obligatorio'),
    link: Yup.string().required('Link es obligatorio'),
    thumbnail: Yup.string().required('Link de la imagen miniatura es obligatorio'),
    type: Yup.string().required('El tipo de contenido es obligatorio'),
  })
  .required()


const GamesContentCreate: FC = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [searchParams, setSearchParams] = useSearchParams()
  const [linkFromInput, setLinkFromInput] = useState<string>()
  const slug = searchParams.get('slug')
  const [disableThumbnail, setDisableThumbnail] = useState<boolean>(false)
  const baseEmbedUrl = 'https://www.youtube.com/embed/'
  const [videoIdExtracted, setVideoIdExtracted] = useState<string>()
  const [thumbnailUrlExtracted, setThumbnailUrlExtracted] = useState<string>()
  const [embedUrl, setEmbedUrl] = useState<string>()

  const [isLoading, setIsLoading] = useState(true)
  const [currentGame, setCurrentGame] = useState<GameType>()

  const initRequest = useCallback(async () => {
    setIsLoading(true)
    await fetcher(
      GameEndpoints.bySlug(slug!)
    ).then((response: GameType) => {
      setCurrentGame(response)
    })

    setIsLoading(false)
  }, [])

  useEffect(() => {
    initRequest();
  }, [])

  const { control, handleSubmit, formState: { errors, isValid }, register, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const onSubmit = (data: FormData) => {
    if (data.type == GameContentType.VIDEO) {
      data.link = embedUrl!
    }

    let payload = {
      data: {
        slug: data.slug,
        link: data.link,
        type: data.type,
        thumbnail: data.thumbnail
      }
    }

    console.log(payload);

    fetcher(GameContentEndpoints.new(payload))
      .then(() => {
        toast({
          title: 'Contenido creado',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Imagen/Video creado con éxito',
          position: 'bottom-right'
        })
        navigate(`${ROUTES.GAMES.content}?slug=${slug}`)
      })
      .catch(e => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: e.message,
          position: 'bottom-right'
        })
      })
  }

  const getYoutubeMetaDataFromUrl = async (url: string)
  : Promise<{thumbnail_url?: string}> => {
    let data: {} = {}
    if (url) {
      let full_url = `https://youtube.com/oembed?url=${url}&format=json`
      data = await axios.get(full_url).then(resp => {
        return resp.data
      }).catch(e => {
        console.log("error", e.response.data);
      });
    }
    return data
  }

  const getMetaDataFromLinkVideo = async (tipo: GameContentType) => {
    console.log(linkFromInput);

    if(tipo == GameContentType.VIDEO && linkFromInput) {
      const metaData = await getYoutubeMetaDataFromUrl(linkFromInput)
      const video_id = await youtubeParser(linkFromInput)
      console.log(metaData);
      console.log(video_id);
      setThumbnailUrlExtracted(metaData.thumbnail_url)
      setValue('thumbnail', metaData.thumbnail_url)
      setVideoIdExtracted(video_id)
      setDisableThumbnail(true)
      setEmbedUrl(`${baseEmbedUrl}${video_id}`)
    } else {
      console.log('es una imagen, nada que obtener');
      setVideoIdExtracted('')
      setEmbedUrl('')
      setDisableThumbnail(false)
    }
  }

  const youtubeParser = (url: string) : string => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    var match = url.match(regExp)
    return (match && match[7].length == 11) ? match[7] : ''
  }

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Agregar Imagen/video para {currentGame?.title}
        </Heading>

        {isLoading ? (
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
              <FormControl
                color={'white'}
                isInvalid={errors.slug ? true : false}
              >
                <FormLabel fontWeight={600} htmlFor="slug">Slug</FormLabel>
                <Input
                  id="name"
                  {...register('slug')}
                  value={slug}
                  readOnly
                />
                <FormErrorMessage>
                  {errors.slug && errors.slug.message?.toString()}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                color={'white'}
                isInvalid={errors.link ? true : false}
              >
                <FormLabel marginTop={'20px'} fontWeight={600} htmlFor="link">Link (Youtube o imagen)</FormLabel>
                <Input
                  fontWeight={600}
                  id="link"
                  {...register('link')}
                  onBlur={(e) => {
                    setLinkFromInput(e.target.value)
                  }}
                />
                <FormErrorMessage>
                  {errors.link && errors.link.message?.toString()}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                color={'white'}
                isInvalid={errors.type ? true : false}
              >
                <FormLabel marginTop={'20px'} fontWeight={600} htmlFor="type">Tipo</FormLabel>
                <Select
                  fontWeight={600}
                  id="type"
                  {...register('type')}
                  onChange={(e) => {
                    getMetaDataFromLinkVideo(e.target.value)
                  }}
                >
                  {[GameContentType.IMAGE, GameContentType.VIDEO].map(s => (
                    <Option key={s} value={s}>
                      {s}
                    </Option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {errors.type && errors.type.message?.toString()}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                color={'white'}
                isInvalid={errors.thumbnail ? true : false}
              >
                <FormLabel marginTop={'20px'} fontWeight={600} htmlFor="thumbnail">Link Imagen Miniatura (mínimo 200x130)</FormLabel>
                <Input
                  fontWeight={600}
                  id="thumbnail"
                  {...register('thumbnail')}
                  disabled={disableThumbnail}
                  onChange={(e) => {
                    setThumbnailUrlExtracted(e.target.value)
                  }}
                />
                <FormErrorMessage>
                  {errors.thumbnail && errors.thumbnail.message?.toString()}
                </FormErrorMessage>
              </FormControl>
              
              {thumbnailUrlExtracted && (
                <Box border='1px solid #4b4b4b' fontWeight={600} padding={5} borderRadius={10} w={'full'} textAlign='center'>
                  Imagen miniatura
                  <Image margin={'0 auto'} src={thumbnailUrlExtracted}/>
                </Box>
              )}

              {videoIdExtracted && (
                <Box border='1px solid #4b4b4b' fontWeight={600} padding={5} borderRadius={10} w={'full'} textAlign='center'>
                  <iframe
                    width="560" height="315"
                    src={embedUrl}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen>
                  </iframe>
                </Box>
              )}

              <Button
                variant={'filledGradient'}
                isLoading={isLoading}
                disabled={isLoading}
                type="submit"
                w={'full'}
                style={{
                  marginTop: '30px'
                }}
              >
                Enviar
              </Button>
            </VStack>
          </form>
        )}
      </VStack>
    </Center>
  )
}

export default GamesContentCreate
