import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Switch,
} from '@chakra-ui/react'
import { fetcher, Method } from '@olagg/api-hooks'
import {
  GameCredentials,
  ScholarshipApplication
} from '@olagg/db-types'
import { useState } from 'react'

interface IProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: (s: string, b?: string) => void
  onError: (s: string) => void
  currentApplication?: ScholarshipApplication | undefined
  currentCredentials?: GameCredentials | undefined
}

const ReassignModal = ({
  isOpen,
  onClose,
  currentApplication,
  onSuccess,
  onError
}: IProps) => {
  const [_discordId, setDiscordId] = useState<string>()

  const [enableCreateForPrev, setEnableCreateForPrev] =
    useState(false)

  const toggleDisable = () =>
    setEnableCreateForPrev(!enableCreateForPrev)

  const reassign = async () => {
    if (!_discordId)
      return onError('Debes rellenar el ID de Discord')
    if (!currentApplication) return

    fetcher({
      method: Method.PUT,
      path: `/admin/scholarships/${currentApplication.id}/reassign`,
      body: {
        discordId: _discordId,
        createForPrev: enableCreateForPrev,
        gameId: currentApplication?.gameId
      }
    })
      .then(d => {
        onSuccess('Reasignación realizada con éxito')

        if (enableCreateForPrev) {
          window.open(
            // @ts-ignore
            `${location.origin}/becas/${d.vacant}`,
            '_blank'
          )
        } else {
          location.reload()
        }
      })
      .catch(e => {
        onError(e.message)
      })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent mx={5}>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mt={4} textAlign={'center'}>
            <Heading fontSize={'24px'} color={'olaggPurple.100'}>
              Reasignar beca
            </Heading>
            <Box>
              <Text fontFamily={'text'} mt={4}>
                Escribe el Discord ID del usuario al que quieres
                darle esta beca en su lugar
              </Text>
            </Box>
            <Box mt={5}>
              <Input
                autoFocus
                focusBorderColor="olaggPurple.100"
                value={_discordId}
                onChange={e => setDiscordId(e.target.value)}
              ></Input>
            </Box>
          </Box>
          <Flex w="full" align="center" mt={2}>
            <FormLabel
              htmlFor="disableCredentials"
              pt={4}
              textAlign={'center'}
            >
              ¿Quieres generar una vacante para el usuario que
              tenía asignada ésta beca previamente? Se abrirá una
              nueva ventana para completar los datos necesarios
              de dicha vacante, de lo contrario el usuario se
              quedará sin beca y su solicitud será marcada como
              suspendida.
            </FormLabel>
          </Flex>
          <Flex justify={'center'} mt={2}>
            <Switch onClick={toggleDisable} />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            variant={'filledGradient'}
            w={'full'}
            onClick={reassign}
          >
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ReassignModal
