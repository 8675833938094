import { FC } from 'react'
import { Heading } from '@chakra-ui/react'
import ScholarshipApplications from '../features/ScholarshipApplications'

const Scholarships: FC = ({ }) => {
  return (
    <>
      <Heading textAlign="center" color="white" my={10}>
        Solicitudes de Beca
      </Heading>
      <ScholarshipApplications showFilter />
    </>
  )
}

export default Scholarships
